import {
  Component,
  OnInit,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  Input,
  OnDestroy,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PdfRenderService } from '@services/pdf-render.service';
import { UserContext } from '@classes/user-context';
import { Timeline } from '@classes/timeline';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormControl, FormControl } from '@angular/forms';
import { stringToKeyValue } from '@angular/flex-layout/extended/style/style-transforms';
import { of } from 'rxjs';
import { saveAs } from 'file-saver';
import { AbschlagsAusgleichService } from '@services/abschlags-ausgleich.service';
import { Slot } from '@classes/slot';
@Component({
  selector: 'app-pdf-controls',
  templateUrl: './pdf-controls.component.html',
  styleUrls: ['./pdf-controls.component.scss'],
})
export class PdfControlsComponent implements OnInit, OnChanges, OnDestroy {
  @Input('user') user: UserContext | undefined = undefined;
  @Input('markerAge') markerAge: number | undefined | null = undefined;
  timelines: Array<Timeline> = new Array<Timeline>();
  explanations: any = {};
  bundesland = new FormControl<string>('Nordrhein-Westfalen');
  kurzform_arbeit = new FormControl<string>('Arbeit');
  kurzform_kuendigungsfrist = new FormControl<string>('Kü-Fri');
  kurzform_freistellung = new FormControl<string>('Freist.');
  kurzform_krankengeld = new FormControl<string>('Krank');
  kaufbare_ep = new FormControl<number | null>(null);
  kaufbare_ep_text = new FormControl<string | null>(null);
  kurzform_transfergesellschaft = new FormControl<string>('TG KuG');
  kurzform_sperre = new FormControl<string>('Sperre');
  kurzform_arbeitslosengeld_1 = new FormControl<string>('ALG1');

  kurzform_arbeitslosengeld_mit_minijob_1 = new FormControl<string>('ALG1');
  kurzform_arbeitslosengeld_durch_geschaeftsaufgabe_1 = new FormControl<string>(
    'ALG1',
  );
  kurzform_arbeitslosengeld_durch_geschaeftsaufgabe_mit_minijob_1 =
    new FormControl<string>('ALG1');
  kurzform_luecke = new FormControl<string>('ohne Eink.');
  steuerpflichtiges_jahresbrutto: boolean = true;
  kurzform_bruecke = new FormControl<string>('ohne Eink.');
  kurzform_atz = new FormControl<string>('ATZ');
  kurzform_joker = new FormControl<string>('ohne Eink.');
  kurzform_phase_x = new FormControl<string>('Ph.X');
  kurzform_phase_y = new FormControl<string>('Ph.Y');
  slot_translations_phase_x = new FormControl<string>('NichtPhaseX');
  slot_translations_phase_y = new FormControl<string>('NichtPhaseY');
  slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob =
    new FormControl<string>('ALG1');
  slot_translations_alg1_durch_geschaeftsaufgabe = new FormControl<string>(
    'ALG1',
  );
  slot_translations_alg1_mit_minijob = new FormControl<string>('ALG1');
  slot_translations_alg1 = new FormControl<string>('ALG1');
  slot_translations_sperre = new FormControl<string>('Sperre_vor_ALG1');
  slot_translations_joker = new FormControl<string>('Joker');
  slot_translations_luecke = new FormControl<string>('Brücke');
  slot_translations_bruecke = new FormControl<string>('Lücke');
  custom_colors_on_pensions: boolean = true;
  always_show_exit_marker: boolean = true;
  show_pension_income_in_overview_graph: boolean = true;
  include_zero_income_bars: boolean = true;
  brochure: boolean = true;
  pad_anyway: boolean = false;
  name = new FormControl<string>('Benutzer');
  alternate_markers: boolean = true;
  limit_earliest_now: boolean = true;
  slotNames: Map<string, string> = new Map<string, string>();

  legacyPensionCalculatorUrl = new FormControl<string>(
    'https://www.clever-in-rente.de/?rest_route=/rentenrechner/v1/rentenbericht',
  );
  legacyPensionCalculatorUserName = new FormControl<string>('');
  legacyPensionCalculatorUserPassword = new FormControl<string>('');
  legacyPensioncalculatorArgument = new FormControl<string>('');
  savePW = new FormControl<boolean>(true);
  downloading: boolean = false;
  downloadError: boolean = false;
  sections: any = {
    seiten: false,
    timelines: false,
    namen: false,
    abk: false,
    platzhalter: false,
    texte: false,
    wege: false,
    legacy: false,
    data: false,
  };
  selectedTabIndex: number = 1;
  sectionVisible(id: string): boolean {
    return this.sections[id] === true;
  }
  setRef(tl: Timeline, ev: any) {
    if (!(ev?.isUserInput === true)) {
      return;
    }
    this.selectTimeline(tl);
    this.refTl = tl;
    console.log(tl.getCondensedName(true));
    console.log(ev);
  }
  toggleSection(id: string) {
    this.sections[id] = !this.sections[id];
    for (let key of Object.keys(this.sections)) {
      if (key != id) {
        this.sections[key] = false;
      }
    }
  }
  castLegacyPensionCalculatorArgs() {
    let str = '&GDB=' + (this.user?.gdb! > 50 ? 1 : 0);
    str += '&Geburtsdatum_day=' + this.user?.date_of_birth.getDate();
    str += '&Geburtsdatum_month=' + (this.user?.date_of_birth!.getMonth()! + 1);
    str += '&Geburtsdatum_year=' + this.user?.date_of_birth.getFullYear();
    switch (this.user?.wartezeit_35_fehlend) {
      case 0: {
        str += '&Wartezeit35Erfuellt=1';
        break;
      }
      case 999: {
        str += '&Wartezeit35Erfuellt=2';
        break;
      }
      default: {
        str += '&Wartezeit35Erfuellt=0';
        break;
      }
    }
    switch (this.user?.wartezeit_45_fehlend) {
      case 0: {
        str += '&Wartezeit45Erfuellt=1';
        break;
      }
      case 999: {
        str += '&Wartezeit45Erfuellt=2';
        break;
      }
      default: {
        str += '&Wartezeit45Erfuellt=0';
        break;
      }
    }

    if (this.user?.wartezeit_35_fehlend! > 0) {
      if (this.user?.wartezeit_35_fehlend! < 12 * 35) {
        str +=
          '&Wartezeit35Bestaetigte=' +
          (12 * 35 - this.user?.wartezeit_35_fehlend!);
        str += '&wartezeit35Fehlend=' + this.user?.wartezeit_35_fehlend;
      }
      str +=
        '&WartezeitBeruecksichtigtBis=' +
        this.user?.wz_bis.toLocaleDateString('de-DE', {
          day: 'numeric',
          year: 'numeric',
          month: 'numeric',
        });
      str +=
        '&WartezeitBeruecksichtigtBis_year=' + this.user?.wz_bis.getFullYear();
      str +=
        '&WartezeitBeruecksichtigtBis_months=' +
        (this.user?.wz_bis.getMonth()! + 1);
    }

    if (this.user?.wartezeit_45_fehlend! > 0) {
      if (this.user?.wartezeit_45_fehlend! < 12 * 45) {
        str += '&wartezeit45Fehlend=' + this.user?.wartezeit_45_fehlend;
        str +=
          '&Wartezeit45Bestaetigte=' +
          (12 * 45 - this.user?.wartezeit_45_fehlend!);
      }
      if (this.user?.wartezeit_35_fehlend! == 0) {
        str +=
          '&WartezeitBeruecksichtigtBis=' +
          this.user?.wz_bis.toLocaleDateString('de-DE', {
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
          });
        str +=
          '&WartezeitBeruecksichtigtBis_year=' +
          this.user?.wz_bis.getFullYear();
        str +=
          '&WartezeitBeruecksichtigtBis_months=' +
          (this.user?.wz_bis.getMonth()! + 1);
      }
    }

    str += '&EPBestaetigte_UI=' + this.user?.ep;
    str += '&EPBestaetigte=' + this.user?.ep?.toFixed(4);
    str += '&EPBeruecksichtigtBis_day=' + this.user?.ep_bis!.getDate();
    str +=
      '&EPBeruecksichtigtBis_month=' + (this.user?.ep_bis!.getMonth()! + 1);
    str +=
      '&EPBeruecksichtigtBis_year=' + (this.user?.ep_bis!.getFullYear()! + 1);
    str +=
      '&WahlGeltenderTarifvertrag=0&WahlArbeitslosigkeit=0&TransferGesellschaftBegin_day=&TransferGesellschaftBegin_month=&TransferGesellschaftBegin_year=';
    str +=
      '&krankenkasse=' +
      (this.user?.sozialversicherung.krankenkasse! == 'gesetzlich' ? '1' : '0');
    str +=
      '&Zusatzbeitragssatz=' +
      this.user?.sozialversicherung.zusatzbeitrag_kv?.toFixed(2);
    str +=
      '&Zusatzbeitragssatz_UI=' +
      this.user?.sozialversicherung.zusatzbeitrag_kv?.toFixed(2);
    str +=
      '&pflegeversicherungszuschlag=' +
      (this.user?.sozialversicherung.kinder_unter_23! > 0 ? '1' : '0');
    str += '&preset=AlleWegeInDieRenteFormular';
    str += '&JahresentgeltRV=' + this.user?.jahresentgelt?.toFixed(2);
    str += '&JahresentgeltRV_UI=' + this.user?.jahresentgelt?.toFixed(2);
    this.legacyPensioncalculatorArgument.setValue(str);
  }
  legacyDownloadLink() {
    let url: string = '';
    let failed: boolean = false;
    if (
      this.legacyPensionCalculatorUrl?.value === undefined ||
      this.legacyPensionCalculatorUrl?.value === null
    ) {
      failed = true;
    } else {
      url += this.legacyPensionCalculatorUrl.value;
    }
    if (
      this.legacyPensioncalculatorArgument?.value === undefined ||
      this.legacyPensioncalculatorArgument?.value === null
    ) {
      failed = true;
    } else {
      url += this.legacyPensioncalculatorArgument.value;
    }
    if (
      this.legacyPensionCalculatorUserName?.value === undefined ||
      this.legacyPensionCalculatorUserName?.value === null
    ) {
      failed = true;
    } else {
      url += '&user=' + this.legacyPensionCalculatorUserName.value;
    }
    if (
      this.legacyPensionCalculatorUserPassword?.value === undefined ||
      this.legacyPensionCalculatorUserPassword?.value === null
    ) {
      failed = true;
    } else {
      url += '&password=' + this.legacyPensionCalculatorUserPassword.value;
    }

    if (failed) {
      alert('es fehlen daten');
      return;
    }
    return url;
  }

  legacyDownload() {
    let url = this.legacyDownloadLink();
    this.downloading = true;
    this.downloadError = false;
    if (url == undefined) {
      return;
    }
    let headers = new HttpHeaders()
      .set('Accept', 'application/pdf')
      .set('Access-Control-Allow-Origin', '*');
    this.http.get(url, { responseType: 'blob', headers: headers }).subscribe(
      (blob) => {
        saveAs(blob, 'Legacy-' + this.getFilename());
        this.downloading = false;
      },
      (error) => {
        this.downloading = false;
        this.downloadError = true;
      },
    );
  }
  pages: any = {
    title: true,
    personal_data: true,
    toc: true,
    overview: true,
    comparison: true,
    hinweise_und_erlaeuterungen: true,
    appendix_fls: true,
    appendix_kostal: true,
    appendix_vallourec: false,
    appendix_mypegasus: false,
    appendix_voestalpine: false,
    legend: false,
    backpage: true,
    abfindung_vallourec: false,
    abfindung_fls: false,
    abfindung_igm: true,
    abschlagsausgleich: true,
    abschlagsausgleich_cost: true,
    einzahlungen: false,
    hinweise_und_erlaeuterungen_igm: true
  };

  placeholders: Array<{
    name: string;
    type: string;
    content: any;
    condition?: any;
  }> = [
      {
        name: '%earliest_of_ep_bis_wz_bis%',
        type: 'function',
        content: () => {
          return this.user?.earliest_ep_wz().toLocaleDateString('de-DE', {
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
          });
        },
      },
      {
        name: '%abfindung_1%',
        type: 'function',
        content: () => {
          return parseFloat(
            this.user?.metadata.get('abfindung_1'),
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        name: '%abfindung_1_evaluated%',
        type: 'function',
        content: () => {
          return parseFloat(this.user?.metadata.get('abfindung_1')) * 0.6;
        },
      },
      {
        name: '%abfindung_2%',
        type: 'function',
        content: () => {
          return parseFloat(
            this.user?.metadata.get('abfindung_2'),
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        name: '%abfindung_2_evaluated%',
        type: 'function',
        content: () => {
          return (
            parseFloat(this.user?.metadata.get('abfindung_2')) * 0.6
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        name: '%abfindung_3%',
        type: 'function',
        content: () => {
          return parseFloat(
            this.user?.metadata.get('abfindung_3'),
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        name: '%abfindung_3_evaluated%',
        content: () => {
          return (
            parseFloat(this.user?.metadata.get('abfindung_3')) * 0.6
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
        type: 'function',
      },
      {
        name: '%abfindung_4%',
        type: 'function',
        content: () => {
          return parseFloat(
            this.user?.metadata.get('abfindung_4'),
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        name: '%abfindung_4_evaluated%',
        type: 'function',
        content: () => {
          return (
            parseFloat(this.user?.metadata.get('abfindung_4')) * 0.6
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        name: '%abfindung_5%',
        type: 'function',
        content: () => {
          return parseFloat(
            this.user?.metadata.get('abfindung_5'),
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        name: '%abfindung_5_evaluated%',
        type: 'function',
        content: () => {
          return (
            parseFloat(this.user?.metadata.get('abfindung_5')) * 0.6
          ).toLocaleString('de', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      {
        name: '%hinweis_luecke_35_jahre%',
        type: 'conditional_string',
        content:
          'Auf diesem Weg liegt eine Lücke vor erreichen der 35-jährigen Wartezeit vor.',
      },
      {
        name: '%hinweis_alg_45_jahre%',
        type: 'conditional_string',
        content:
          'In den letzten 24 Monaten vor der Rente tauchen Lücke und/oder ALG1 auf',
      },
      {
        name: '%phase_x_lt_24_months%',
        type: 'conditional_string',
        content: 'Phase X is kürzer als 24 Monate',
      },
      {
        name: '%kaufbare_ep%',
        type: 'function',
        content: () => this.ausgleich.buyableEP(this.user).toLocaleString('de-DE', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
      },
      {
        name: '%kaufbare_ep_kosten%',
        type: 'function',
        content: () => this.ausgleich.maxCost(this.user).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      },
      {
        name: '%kosten_pro_ep%',
        type: 'function',
        content: () => this.ausgleich.costPerEp().toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
      },
      {
        name: '%bav_brutto%',
        type: 'function',
        content: () => (parseFloat(this.user?.metadata.get('bav_brutto'))).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      },
      {
        name: '%bav_netto%',
        type: 'function',
        content: () => (parseFloat(this.user?.metadata.get('bav_netto'))).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      },
      {
        name: '%regelaltersgrenze_datum%',
        type: 'function',
        content: () => this.regelaltersgrenzeDatum()
      },
      {
        name: '%weg_rente_netto%',
        type: 'function',
        content: (timeline: Timeline) => {

          return timeline.getPension()?.nettoeinkuenfte?.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        }
      },
      {
        name: '%weg_rente_netto_plus_bav_netto%',
        type: 'function',
        content: (timeline: Timeline) => {
          return (parseFloat(this.user?.metadata.get('bav_netto') ?? 0) + (timeline.getPension()?.nettoeinkuenfte ?? 0))?.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        }
      },
      {
        name: '%mitte_atz%',
        type: 'function',
        content: (timeline: Timeline) => {
          let atz = timeline.slots.find(x => x.name === 'ATZ');
          if (atz) {
            let start = atz.start;
            let end = atz.end;
            let mitte = new Date((atz.end!.getTime() + atz.start!.getTime()) * 0.5)
            if (mitte.getDate() > 1) {
              mitte.setDate(mitte.getDate() + 10);
            }
            mitte.setDate(1);
            return mitte.toLocaleDateString('de-DE', {
              year: "numeric",
              month: "long"
            });
          }
          return 'unbekannt';
        }
      },
      {
        name: '%mitte_atz_datum%',
        type: 'function',
        content: (timeline: Timeline) => {
          let atz = timeline.slots.find(x => x.name === 'ATZ');
          if (atz) {
            let start = atz.start;
            let end = atz.end;
            let mitte = new Date((atz.end!.getTime() + atz.start!.getTime()) * 0.5)
            if (mitte.getDate() > 1) {
              mitte.setDate(mitte.getDate() + 10);
            }
            mitte.setDate(1);
            return mitte.toLocaleDateString('de-DE');
          }
          return 'unbekannt';
        }
      },
      {
        name: '%start_phase_x%',
        type: 'function',
        content: (timeline: Timeline) => {
          let px = timeline.slots.find(x => ['PhaseX', 'Phase X'].includes(x.name));
          if (px) {
            return px.start!.toLocaleDateString('de-DE');
          }
          return 'unbekannt';
        }
      },
      {
        name: '%start_phase_y%',
        type: 'function',
        content: (timeline: Timeline) => {
          let px = timeline.slots.find(x => ['PhaseY', 'Phase Y'].includes(x.name));
          if (px) {
            return px.start!.toLocaleDateString('de-DE');
          }
          return 'unbekannt';
        }
      },
      {
        name: '%start_atz%',
        type: 'function',
        content: (timeline: Timeline) => {
          let px = timeline.slots.find(x => ['ATZ'].includes(x.name));
          if (px) {
            return px.start!.toLocaleDateString('de-DE');
          }
          return 'unbekannt';
        }
      }

    ];

  regelaltersrente(): Slot {
    let referenzWegRenten: Array<Slot> = this.user!.getSlotsByFilterFunction(
      (slot: Slot) => {
        return (
          slot?.timeline?.name.toLowerCase().includes('referenzweg') &&
          slot.name.toLowerCase().includes('rente')
        );
      },
    );
    for (let rente of referenzWegRenten) {
      if (rente?.timeline?.is('Regelaltersrente')) {
        return rente;
      }
    }
    throw Error("cannot find regelaltersrente");
  }
  regelaltersgrenzeDatum(): string {
    let rar = this.regelaltersrente();
    return rar!.start!.toLocaleDateString('de-DE');
  }

  regelaltersrenteNetto(): number {
    let rar = this.regelaltersrente();
    return rar!.nettoeinkuenfte!;
  }
  placeholdersChanged() {
    console.log(this.placeholders);
    this.savePlaceholders();
  }
  savePlaceholders() {
    for (let placeholder of this.placeholders) {
      if (placeholder.type != 'function') {
        localStorage.setItem(
          'placeholder_' + placeholder.name,
          placeholder.content,
        );
      }
    }
  }
  loadPlaceholders() {
    for (let placeholder of this.placeholders) {
      if (placeholder.type != 'function') {
        placeholder.content = localStorage.getItem(
          'placeholder_' + placeholder.name,
        );
      }
    }
  }
  bundeslaender: { ost: Array<string>; west: Array<string> } = {
    west: [
      'Baden-Württemberg',
      'Bayern',
      'Berlin',
      'Bremen',
      'Hamburg',
      'Hessen',
      'Niedersachsen',
      'Nordrhein-Westfalen',
      'Rheinland-Pfalz',
      'Saarland',
      'Schleswig-Holstein',
    ],
    ost: [
      'Brandenburg',
      'Mecklenburg-Vorpommern',
      'Sachsen',
      'Sachsen-Anhalt',
      'Thüringen',
    ],
  };
  saveAbbrevations() {
    if (this.kurzform_arbeit && this.kurzform_arbeit.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_arbeit',
        this.kurzform_arbeit.value,
      );
    }
    if (
      this.kurzform_kuendigungsfrist &&
      this.kurzform_kuendigungsfrist.value
    ) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_kuendigungsfrist',
        this.kurzform_kuendigungsfrist.value,
      );
    }
    if (this.kurzform_freistellung && this.kurzform_freistellung.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_freistellung',
        this.kurzform_freistellung.value,
      );
    }
    if (this.kurzform_krankengeld && this.kurzform_krankengeld.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_krankengeld',
        this.kurzform_krankengeld.value,
      );
    }
    if (
      this.kurzform_transfergesellschaft &&
      this.kurzform_transfergesellschaft.value
    ) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_transfergesellschaft',
        this.kurzform_transfergesellschaft.value,
      );
    }
    if (this.kurzform_sperre && this.kurzform_sperre.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_sperre',
        this.kurzform_sperre.value,
      );
    }
    if (
      this.kurzform_arbeitslosengeld_1 &&
      this.kurzform_arbeitslosengeld_1.value
    ) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_arbeitslosengeld_1',
        this.kurzform_arbeitslosengeld_1.value,
      );
    }
    if (this.kurzform_luecke && this.kurzform_luecke.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_luecke',
        this.kurzform_luecke.value,
      );
    }
    if (this.kurzform_bruecke && this.kurzform_bruecke.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_bruecke',
        this.kurzform_bruecke.value,
      );
    }
    if (this.kurzform_atz && this.kurzform_atz.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_atz',
        this.kurzform_atz.value,
      );
    }
    if (this.kurzform_joker && this.kurzform_joker.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_joker',
        this.kurzform_joker.value,
      );
    }
    if (this.kurzform_phase_x && this.kurzform_phase_x.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_phase_x',
        this.kurzform_phase_x.value,
      );
    }
    if (this.kurzform_phase_y && this.kurzform_phase_y.value) {
      localStorage.setItem(
        'pdf.abbreviation.kurzform_phase_y',
        this.kurzform_phase_y.value,
      );
    }
  }
  loadAbbrevations() {
    if (this.kurzform_arbeit && this.kurzform_arbeit.value) {
      this.kurzform_arbeit.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_arbeit'),
      );
    }
    if (
      this.kurzform_kuendigungsfrist &&
      this.kurzform_kuendigungsfrist.value
    ) {
      this.kurzform_kuendigungsfrist.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_kuendigungsfrist'),
      );
    }
    if (this.kurzform_freistellung && this.kurzform_freistellung.value) {
      this.kurzform_freistellung.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_freistellung'),
      );
    }
    if (this.kurzform_krankengeld && this.kurzform_krankengeld.value) {
      this.kurzform_krankengeld.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_krankengeld'),
      );
    }
    if (
      this.kurzform_transfergesellschaft &&
      this.kurzform_transfergesellschaft.value
    ) {
      this.kurzform_transfergesellschaft.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_transfergesellschaft'),
      );
    }
    if (this.kurzform_sperre && this.kurzform_sperre.value) {
      this.kurzform_sperre.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_sperre'),
      );
    }
    if (
      this.kurzform_arbeitslosengeld_1 &&
      this.kurzform_arbeitslosengeld_1.value
    ) {
      this.kurzform_arbeitslosengeld_1.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_arbeitslosengeld_1'),
      );
    }
    if (this.kurzform_luecke && this.kurzform_luecke.value) {
      this.kurzform_luecke.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_luecke'),
      );
    }
    if (this.kurzform_bruecke && this.kurzform_bruecke.value) {
      this.kurzform_bruecke.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_bruecke'),
      );
    }
    if (this.kurzform_atz && this.kurzform_atz.value) {
      this.kurzform_atz.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_atz'),
      );
    }
    if (this.kurzform_joker && this.kurzform_joker.value) {
      this.kurzform_joker.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_joker'),
      );
    }
    if (this.kurzform_phase_x && this.kurzform_phase_x.value) {
      this.kurzform_phase_x.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_phase_x'),
      );
    }
    if (this.kurzform_phase_y && this.kurzform_phase_y.value) {
      this.kurzform_phase_y.setValue(
        localStorage.getItem('pdf.abbreviation.kurzform_phase_y'),
      );
    }
  }
  saveTranslations() {
    if (this.slot_translations_phase_x.value) {
      console.log('saving phasex-transl.');
      localStorage.setItem(
        'pdf.translation.phaseX',
        this.slot_translations_phase_x.value,
      );
    }
    if (this.slot_translations_phase_y.value) {
      console.log('saving phasey-transl.');
      localStorage.setItem(
        'pdf.translation.phaseY',
        this.slot_translations_phase_y.value,
      );
    }

    if (this.slot_translations_alg1_durch_geschaeftsaufgabe.value) {
      console.log(
        'saving slot_translations_alg1_durch_geschaeftsaufgabe-transl.',
      );
      localStorage.setItem(
        'pdf.translation.slot_translations_alg1_durch_geschaeftsaufgabe',
        this.slot_translations_alg1_durch_geschaeftsaufgabe.value,
      );
    }
    if (this.slot_translations_alg1.value) {
      console.log('saving phasex-transl.');
      localStorage.setItem(
        'pdf.translation.slot_translations_alg1',
        this.slot_translations_alg1.value,
      );
    }

    if (this.slot_translations_sperre.value) {
      localStorage.setItem(
        'pdf.translation.slot_translations_sperre',
        this.slot_translations_sperre.value,
      );
    }
    if (this.slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob.value) {
      console.log('saving phasex-transl.');
      localStorage.setItem(
        'pdf.translation.slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob',
        this.slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob.value,
      );
    }
    if (this.slot_translations_alg1_mit_minijob.value) {
      console.log('saving slot_translations_alg1_mit_minijob-transl.');
      localStorage.setItem(
        'pdf.translation.slot_translations_alg1_mit_minijob',
        this.slot_translations_alg1_mit_minijob.value,
      );
    }

    if (this.slot_translations_joker.value) {
      console.log('saving slot_translations_joker-transl.');
      localStorage.setItem(
        'pdf.translation.slot_translations_joker',
        this.slot_translations_joker.value,
      );
    }
    if (this.slot_translations_luecke.value) {
      console.log('saving slot_translations_luecke-transl.');
      localStorage.setItem(
        'pdf.translation.slot_translations_luecke',
        this.slot_translations_luecke.value,
      );
    }
    if (this.slot_translations_bruecke.value) {
      console.log('saving slot_translations_bruecke-transl.');
      localStorage.setItem(
        'pdf.translation.slot_translations_bruecke',
        this.slot_translations_bruecke.value,
      );
    }
  }
  loadTranslations() {
    console.log('loading transl.');
    this.slot_translations_joker.setValue(
      localStorage.getItem('pdf.translation.slot_translations_joker') ??
      'Joker',
    );
    this.slot_translations_bruecke.setValue(
      localStorage.getItem('pdf.translation.slot_translations_bruecke') ??
      'Brücke',
    );
    this.slot_translations_luecke.setValue(
      localStorage.getItem('pdf.translation.slot_translations_luecke') ??
      'Lücke',
    );
    this.slot_translations_phase_x.setValue(
      localStorage.getItem('pdf.translation.phaseX') ?? 'Phase X',
    );
    this.slot_translations_phase_y.setValue(
      localStorage.getItem('pdf.translation.phaseY') ?? 'Phase Y',
    );
    this.slot_translations_alg1.setValue(
      localStorage.getItem('pdf.translation.slot_translations_alg1') ?? 'ALG1',
    );
    this.slot_translations_sperre.setValue(
      localStorage.getItem('pdf.translation.slot_translations_sperre') ??
      'Sperre_vor_ALG1',
    );
    this.slot_translations_alg1_mit_minijob.setValue(
      localStorage.getItem(
        'pdf.translation.slot_translations_alg1_mit_minijob',
      ) ?? 'ALG1',
    );
    this.slot_translations_alg1_durch_geschaeftsaufgabe.setValue(
      localStorage.getItem(
        'pdf.translation.slot_translations_alg1_durch_geschaeftsaufgabe',
      ) ?? 'ALG1',
    );
    this.slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob.setValue(
      localStorage.getItem(
        'pdf.translation.slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob',
      ) ?? 'ALG1',
    );
  }
  getExplanation(str: string): string {
    return this.explanations[str] ?? 'N/A';
  }
  saveExplanations() {
    for (let tl of this.availableTimelines()) {
      let key = tl.getCondensedName();
      let expl = tl.explanation_pdf;
      localStorage.setItem('pdf.explanation.' + key, expl);
    }
  }
  constructor(
    private pdf: PdfRenderService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private ausgleich: AbschlagsAusgleichService
  ) {
    this.spinner.show('legacyDownloadSpinner');
  }

  refTl: Timeline | undefined = undefined;
  ngOnChanges(changes: SimpleChanges) { }
  ngOnDestroy(): void {
    localStorage.setItem('pdf.pages', JSON.stringify(this.pages));
  }
  saveSettings() {
    let obj = {
      steuerpflichtiges_jahresbrutto: this.steuerpflichtiges_jahresbrutto,
      alternate_markers: this.alternate_markers,
      limit_earliest_now: this.limit_earliest_now,
      always_show_exit_marker: this.always_show_exit_marker,
      custom_colors_on_pensions: this.custom_colors_on_pensions,
      show_pension_income_in_overview_graph:
        this.show_pension_income_in_overview_graph,
      brochure: this.brochure,
      pad_anyway: this.pad_anyway,
    };
    localStorage.setItem('pdf.settings', JSON.stringify(obj));
  }
  loadSettings() {
    console.log('load');
    let obj: any = {};
    if (localStorage.getItem('pdf.settings') === undefined) {
      this.saveSettings();
    }
    let str = localStorage.getItem('pdf.settings');
    if (str) {
      obj = JSON.parse(str);
    }
    this.alternate_markers = obj.alternate_markers ?? this.alternate_markers;
    this.limit_earliest_now = obj.limit_earliest_now ?? this.limit_earliest_now;
    this.always_show_exit_marker =
      obj.always_show_exit_marker ?? this.always_show_exit_marker;
    this.custom_colors_on_pensions =
      obj.custom_colors_on_pensions ?? this.custom_colors_on_pensions;
    this.show_pension_income_in_overview_graph =
      obj.show_pension_income_in_overview_graph ??
      this.show_pension_income_in_overview_graph;
    this.brochure = obj.brochure ?? this.brochure;
    this.pad_anyway = obj.pad_anyway ?? this.pad_anyway;
  }
  ngOnInit(): void {
    this.loadSettings();
    this.loadPlaceholders();
    let pagesString = localStorage.getItem('pdf.pages');
    if (![undefined, null, ''].includes(pagesString)) {
      console.log('pages:' + pagesString);
      this.pages = {
        ...this.pages,
        ...JSON.parse(pagesString!),
      };
    }
    if (localStorage.getItem('legacy_pension_calc_pw')) {
      this.legacyPensionCalculatorUserPassword.setValue(
        localStorage.getItem('legacy_pension_calc_pw'),
      );
      this.legacyPensionCalculatorUserName.setValue(
        localStorage.getItem('legacy_pension_calc_user'),
      );
    }
    this.savePW.valueChanges.subscribe((val) => {
      if (val === true) {
        localStorage.setItem(
          'legacy_pension_calc_pw',
          this.legacyPensionCalculatorUserPassword.value!,
        );
        localStorage.setItem(
          'legacy_pension_calc_user',
          this.legacyPensionCalculatorUserName.value!,
        );
      } else {
        localStorage.removeItem('legacy_pension_calc_pw');
      }
    });
    this.legacyPensionCalculatorUserPassword.valueChanges.subscribe(
      (val: string | null) => {
        if (this.savePW.value && val) {
          localStorage.setItem('legacy_pension_calc_pw', val);
        } else {
          localStorage.removeItem('legacy_pension_calc_pw');
        }
      },
    );
    this.legacyPensionCalculatorUserName.valueChanges.subscribe(
      (val: string | null) => {
        if (this.savePW.value && val) {
          localStorage.setItem('legacy_pension_calc_user', val);
        } else {
          localStorage.removeItem('legacy_pension_calc_user');
        }
      },
    );
    if (this.user) {
      if (this.user.vorname && this.user.nachname) {
        this.name.setValue(this.user.vorname + ' ' + this.user.nachname);
      } else {
        this.name.setValue(this.user.identifier);
      }
      let ref = this.user.getTimelineByCondensedName(
        'Referenzweg: Arbeit -> Regelaltersrente',
      );
      if (ref !== undefined) {
        this.refTl = ref;
        this.timelines.push(ref);
      }
      this.castLegacyPensionCalculatorArgs();
    }
    this.loadTranslations();
    this.loadAbbrevations();
    for (let s of [
      this.slot_translations_phase_x,
      this.slot_translations_phase_y,
      this.slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob,
      this.slot_translations_alg1_durch_geschaeftsaufgabe,
      this.slot_translations_alg1_mit_minijob,
      this.slot_translations_alg1,
      this.slot_translations_sperre,
      this.slot_translations_joker,
      this.slot_translations_bruecke,
      this.slot_translations_luecke,
    ]) {
      s.valueChanges.subscribe((x) => {
        this.saveTranslations();
      });
    }
    let abbreviations = [
      this.kurzform_arbeit,
      this.kurzform_kuendigungsfrist,
      this.kurzform_freistellung,
      this.kurzform_krankengeld,
      this.kurzform_transfergesellschaft,
      this.kurzform_sperre,
      this.kurzform_arbeitslosengeld_1,
      this.kurzform_sperre,
      this.kurzform_luecke,
      this.kurzform_bruecke,
      this.kurzform_atz,
      this.kurzform_joker,
      this.kurzform_phase_x,
      this.kurzform_phase_y,
      this.slot_translations_phase_x,
      this.slot_translations_phase_y,
      this.slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob,
      this.slot_translations_alg1_durch_geschaeftsaufgabe,
      this.slot_translations_alg1_mit_minijob,
      this.slot_translations_alg1,
      this.slot_translations_sperre,
      this.slot_translations_joker,
      this.slot_translations_bruecke,
      this.slot_translations_luecke,
    ];
    for (let abb of abbreviations) {
      abb.valueChanges.subscribe(() => {
        this.saveAbbrevations();
      });
    }
    for (let tl of this.availableTimelines()) {
      let explanation = 'Erklärung für ' + tl.getCondensedName();

      let saved = localStorage.getItem(
        'pdf.explanation.' + tl.getCondensedName(),
      );
      tl.explanation_pdf = saved ?? explanation;
    }

    for (let tl of this.availableTimelines()) {
      this.selectTimeline(tl);
    }
    let removeTls: Array<Timeline> = new Array<Timeline>();
    for (let tl of this.timelines) {
      if (
        (tl.getCondensedName().startsWith('Referenzweg') &&
          tl.getCondensedName() != 'Referenzweg: Arbeit -> Regelaltersrente') ||
        tl.getCondensedName().includes('-> Rente mit') ||
        (!tl.getCondensedName().startsWith('Referenzweg') &&
          tl.getCondensedName().includes('Regelaltersrente'))
      ) {
        removeTls.push(tl);
      }
    }
    for (let tl of removeTls) {
      this.unselectTimeline(tl);
    }
    this.bundesland.setValue('Nordrhein-Westfalen');




    this.kaufbare_ep.valueChanges.subscribe({
      next: (ep: number | undefined | null) => {
        this.user?.metadata.set('buyable_ep', ep);
        console.log({ msg: "Setting buyable ep", ep: ep });
      }
    });
  }
  selectTimeline(tl: Timeline) {
    if (!this.timelineSelected(tl)) {
      this.timelines.push(tl);
    }
  }
  unselectTimeline(tl: Timeline) {
    if (tl === this.refTl) {
      return;
    }
    let idx = this.timelines.indexOf(tl);
    if (idx > 0) {
      this.timelines.splice(idx, 1);
    }
  }
  availableTimelines(): Array<Timeline> {
    if (this.user !== undefined) {
      return this.user.timelines;
    } else {
      return new Array<Timeline>();
    }
  }
  explanationChanged(id: string, expl: string): void {
    console.log('explanation changed for: ' + id);
    console.log('explanation changed to: ');
    console.log(expl);
  }
  timelineSelected(tl: Timeline): boolean {
    let idx = this.timelines.indexOf(tl);
    return idx >= 0;
  }

  toggleTimeline(tl: Timeline) {
    if (
      tl == this.refTl ||
      tl.getCondensedName() == this.refTl?.getCondensedName()
    ) {
      console.log('not disabling.');
      return;
    }
    console.log('ref: ' + this.refTl?.getCondensedName());
    console.log('toggling ' + tl.getCondensedName());
    if (this.timelineSelected(tl)) {
      this.unselectTimeline(tl);
    } else {
      this.selectTimeline(tl);
    }
  }
  getFilename(): string {
    let parts = Array<string>();
    parts.push(this.user?.nachname ?? '');
    parts.push(this.user?.vorname ?? '');
    //parts.push(this.name?.value!);
    if (this.user?.standort) {
      parts.push(this.user.standort);
    }
    let now = new Date();
    if (this.user && this.user.identifier) {
      parts.push(this.user?.identifier);
    }
    parts.push(
      now.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    );
    return parts.join('_') + '.pdf';
  }
  renderTeaser() {
    this.render({
      filename: 'teaser',
      pages: {
        title: false,
        personal_data: false,
        toc: false,
        overview: true,
        comparison: false,
        hinweise_und_erlaeuterungen: true,
        appendix_fls: true,
        appendix_kostal: false,
        appendix_vallourec: false,
        appendix_mypegasus: false,
        appendix_voestalpine: false,
        legend: true,
        backpage: false,
        abfindung_vallourec: false,
        abfindung_fls: false,
        abfindung_igm: true,
        abschlagsausgleich: false,
        abschlagsausgleich_cost: true,
        einzahlungen: false,
        hinweise_und_erlaeuterungen_igm: true
      },
      brochure: false,
    });
  }
  getOrderedTimelines(): Array<Timeline> {
    if (this.user) {
      return this.user.timelines.filter((tl: Timeline) =>
        this.timelineSelected(tl),
      );
    }
    return new Array<Timeline>();
  }
  render(options?: any) {
    if (this.user !== undefined) {
      this.pdf.render(
        {
          pages:
            options !== undefined ? options.pages ?? this.pages : this.pages,
          bundesland: this.bundesland.value,
          steuerpflichtiges_jahresbrutto: (this.steuerpflichtiges_jahresbrutto === true),
          referenzweg: this.refTl,
          name: this.name.value,
          include_zero_income_bars: this.include_zero_income_bars,
          timelines: this.getOrderedTimelines(),
          markerAge: this.markerAge ?? 85,
          limit_earliest_now: this.limit_earliest_now,
          alternate_markers: this.alternate_markers,
          customPensionColorInDetailChart: this.custom_colors_on_pensions,
          alwaysShowExitMarker: this.always_show_exit_marker,
          show_pension_income_in_overview_graph:
            this.show_pension_income_in_overview_graph,
          brochure:
            options !== undefined
              ? options.brochure ?? this.brochure
              : this.brochure,
          pad_anyway: this.pad_anyway,
          abbreviations: {
            'Phase X': this.kurzform_phase_x.value,
            'Phase Y': this.kurzform_phase_y.value,
            PhaseX: this.kurzform_phase_x.value,
            PhaseY: this.kurzform_phase_y.value,
            Arbeit: this.kurzform_arbeit.value,
            ALG1: this.kurzform_arbeitslosengeld_1.value,
            'ALG1 durch Geschäftsaufgabe oder Insolvenz mit Minijob':
              this
                .kurzform_arbeitslosengeld_durch_geschaeftsaufgabe_mit_minijob_1
                .value,
            'ALG1 mit Minijob':
              this.kurzform_arbeitslosengeld_mit_minijob_1.value,
            'ALG1 durch Geschäftsaufgabe oder Insolvenz':
              this.kurzform_arbeitslosengeld_durch_geschaeftsaufgabe_1.value,
            Transfergesellschaft: this.kurzform_transfergesellschaft.value,
            Kündigungsfrist: this.kurzform_kuendigungsfrist.value,
            Brücke: this.kurzform_bruecke.value,
            Joker: this.kurzform_joker.value,
            Lücke: this.kurzform_bruecke.value,
            ATZ: this.kurzform_atz.value,
            Sperre_vor_ALG1: this.kurzform_sperre.value,
          },
          slot_translations: {
            'Phase X': this.slot_translations_phase_x.value,
            'Phase Y': this.slot_translations_phase_y.value,
            PhaseX: this.slot_translations_phase_x.value,
            PhaseY: this.slot_translations_phase_y.value,
            Joker: this.slot_translations_joker.value,
            Brücke: this.slot_translations_bruecke.value,
            Lücke: this.slot_translations_luecke.value,
            ALG1: this.slot_translations_alg1.value,
            'ALG1 durch Geschäftsaufgabe oder Insolvenz mit Minijob':
              this.slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob
                .value,
            'ALG1 mit Minijob':
              this.kurzform_arbeitslosengeld_mit_minijob_1.value,
            'ALG1 durch Geschäftsaufgabe oder Insolvenz':
              this.slot_translations_alg1_durch_geschaeftsaufgabe.value,
            Sperre_vor_ALG1: this.slot_translations_sperre.value,
          },
          ep: {
            kaufbare: this.kaufbare_ep.value,
            text: this.kaufbare_ep_text.value
          },
          placeholders: this.placeholders,
          explanations: this.explanations,
          filename:
            options !== undefined
              ? options.filename ?? this.getFilename()
              : this.getFilename(),
        },
        this.user,
      );
    }
  }
}
