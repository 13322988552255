import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormularComponent } from '@components/formular/formular.component';
import { ComcolWernerComponent } from '@components/comcol-werner/comcol-werner.component';
const routes: Routes = [
  {
    path: 'formular',
    component: FormularComponent,
  },
  {
    path: 'comcol-werner',
    component: ComcolWernerComponent,
  },
  {
    path: '**',
    redirectTo: '/comcol-werner',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
