<ng-container>
  <mat-card *ngIf="prepared()">
    <mat-card-content>
      <plotly-plot
        [data]="GroupGraph.data"
        [layout]="GroupGraph.layout"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '98vw'
        }"
        [useResizeHandler]="true"
        [divId]="'gesamtuebersichtGroupGraph'"
        [revision]="revision"
      >
      </plotly-plot>

      <plotly-plot
        *ngIf="specialRente"
        [data]="SpecialPensionGraph.data"
        [layout]="SpecialPensionGraph.layout"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '98vw'
        }"
        [useResizeHandler]="true"
        [divId]="'SpecialPensionGraph'"
        [revision]="revision"
      >
      </plotly-plot>
      <plotly-plot
        *ngFor="let year of years"
        [data]="pieCharts.data['years.' + year]"
        [layout]="pieCharts.layout"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '30vw'
        }"
        [useResizeHandler]="true"
        [revision]="revision"
      >
      </plotly-plot>
    </mat-card-content>
  </mat-card>
</ng-container>
