import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Functoid } from '@classes/functoid';
@Component({
  selector: 'app-functoid',
  templateUrl: './functoid.component.html',
  styleUrls: ['./functoid.component.scss'],
})
export class FunctoidComponent implements OnInit {
  @Input('functoid') functoid: Functoid | undefined;
  @Output('onChange') onChange = new EventEmitter<boolean>();
  enabled = new UntypedFormControl(false);
  bFactor = new UntypedFormControl(0);
  nFactor = new UntypedFormControl(1);
  monatsDeckel = new UntypedFormControl(999999);
  monatsBetrag = new UntypedFormControl(0);
  displayName = new UntypedFormControl('unnamed');
  field = new UntypedFormControl('durationMonths');
  baseAmount = new UntypedFormControl(0);
  gdbThreshold = new UntypedFormControl(50);
  timesGDB = new UntypedFormControl(true);
  constructor() {}

  details: boolean = false;
  detailsVisible(): boolean {
    return this.details;
  }
  toggleDetails(): void {
    this.details = !this.details;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fromFunctoid();
  }

  ngOnInit(): void {
    this.nFactor.valueChanges.subscribe((nFactor: number) => {
      if (this.functoid) {
        //console.log("nFactor changed to "+nFactor);
        this.functoid.nFactor = nFactor;
        this.onChange.next(true);
      }
    });
    this.bFactor.valueChanges.subscribe((bFactor: number) => {
      if (this.functoid) {
        this.functoid.bFactor = bFactor;
        this.onChange.next(true);
      }
    });

    this.monatsDeckel.valueChanges.subscribe((monatsDeckel: number) => {
      if (this.functoid) {
        this.functoid.monatsDeckel = monatsDeckel;
        this.onChange.next(true);
      }
    });
    this.monatsBetrag.valueChanges.subscribe((monatsBetrag: number) => {
      if (this.functoid) {
        this.functoid.monatsBetrag = monatsBetrag;
        this.onChange.next(true);
      }
    });
    this.baseAmount.valueChanges.subscribe((baseAmount: number) => {
      if (this.functoid) {
        this.functoid.baseAmount = baseAmount;
        this.onChange.next(true);
      }
    });
    this.enabled.valueChanges.subscribe((enabled: boolean) => {
      if (this.functoid) {
        this.functoid.enabled = enabled;
        this.onChange.next(true);
      }
    });
    this.timesGDB.valueChanges.subscribe((timesGDB: boolean) => {
      if (this.functoid) {
        this.functoid.timesGDB = timesGDB;
        this.onChange.next(true);
      }
    });

    this.gdbThreshold.valueChanges.subscribe((gdbThreshold: number) => {
      if (this.functoid) {
        this.functoid.threshold = gdbThreshold;
        this.onChange.next(true);
      }
    });
    this.fromFunctoid();
  }
  fromFunctoid() {
    if (this.functoid) {
      this.enabled.setValue(this.functoid.enabled);
      this.nFactor.setValue(this.functoid.nFactor);
      this.bFactor.setValue(this.functoid.bFactor);
      this.monatsDeckel.setValue(this.functoid.monatsDeckel);
      this.monatsBetrag.setValue(this.functoid.monatsBetrag);
      this.displayName.setValue(this.functoid.displayName);
      this.field.setValue(this.functoid.field);
      this.baseAmount.setValue(this.functoid.baseAmount);
      this.gdbThreshold.setValue(this.functoid.threshold);
      this.timesGDB.setValue(this.functoid.timesGDB);
    }
  }
  toFunctoid() {
    if (this.functoid) {
      this.functoid.enabled = this.enabled.value;
      this.functoid.nFactor = this.nFactor.value;
      this.functoid.bFactor = this.bFactor.value;
      this.functoid.monatsDeckel = this.monatsDeckel.value;
      this.functoid.monatsBetrag = this.monatsBetrag.value;
      this.functoid.displayName = this.displayName.value;
      this.functoid.field = this.field.value;
      this.functoid.baseAmount = this.baseAmount.value;
      this.functoid.threshold = this.gdbThreshold.value;
      this.functoid.timesGDB = this.timesGDB.value;
    }
  }
}
