import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TimelineBatch } from '@classes/timeline-batch';
import { Timeline } from '@classes/timeline';
import { Slot } from '@classes/slot';
import { UserContext } from '@classes/user-context';
@Component({
  selector: 'app-jahrgangs-uebersicht',
  templateUrl: './jahrgangs-uebersicht.component.html',
  styleUrls: ['./jahrgangs-uebersicht.component.scss'],
})
export class JahrgangsUebersichtComponent implements OnInit {
  @Input('timelineBatch') timelineBatch: TimelineBatch;
  @Input('betriebsaufloesung') betriebsaufloesung: Date;
  @Input('vergleichsweg') vergleichsweg: string;
  @Input('consider_regular_pension_unreduced')
  consider_regular_pension_unreduced: boolean = false;
  revision: number = 0;
  renten: Array<string> = [
    'Regelaltersrente',
    'Geminderte Rente',
    'Ungeminderte Rente',
  ];
  years: Array<number> = new Array<number>();
  timelineIdentifier: string = '';
  constructor() {}
  prepare() {
    this.timelineIdentifier = this.getTimelineIdentifier();
    this.years = this.getYears();
  }
  prepared(): boolean {
    if ([null, undefined, '', 'Keine'].includes(this.timelineIdentifier)) {
      return false;
    }
    if (this.years.length == 0) {
      return false;
    }
    return true;
  }

  pieCharts: any = {
    data: {},
    layout: {},
  };

  redraw() {
    this.prepare();

    if (this.prepared()) {
      this.getGroupedBarChart();
      this.getPieChartData();
      this.getSpecialRenteChart();
      this.revision++;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['timelineBatch']) {
      this.redraw();
    }
    if (
      changes['vergleichsweg'] ||
      changes['consider_regular_pension_unreduced']
    ) {
      this.redraw();
    }
  }
  public GroupGraph: any = {
    data: [],
    layout: {
      autosize: true,
      title: 'Verfügbarkeit der Renten ohne Lücken nach Jahrgang',
      barmode: 'stack',
      automargin: true,
      shapes: [],
      annotations: [],
      xaxis: {
        title: {
          text: '<br>Jahrgang',
          xanchor: 'center',
          yanchor: 'bottom',
        },
        type: 'category',
        showgrid: true,
        zeroline: false,
        tickangle: -30,
      },
      yaxis: {
        showgrid: false,
        showline: true,
        rangemode: 'nonnegative',
        title: 'Personen (%)',
      },
    },
  };

  public SpecialPensionGraph: any = {
    data: [],
    layout: {
      autosize: true,
      title: 'Verfügbarkeit der Spezialrente nach Jahrgang',
      barmode: 'stack',
      automargin: true,
      shapes: [],
      annotations: [],
      xaxis: {
        title: {
          text: '<br>Jahrgang',
          xanchor: 'center',
          yanchor: 'bottom',
        },
        type: 'category',
        showgrid: true,
        zeroline: false,
        tickangle: -30,
      },
      yaxis: {
        showgrid: false,
        showline: true,
        rangemode: 'nonnegative',
        title: 'Personen (%)',
      },
    },
  };
  getPieChartData(): any {
    this.pieCharts.layout = {
      height: 400,
      width: 500,
    };

    let annotations = new Array<any>();
    let row = 0;
    let col = 0;

    for (let year of this.years) {
      let labels = [...this.renten, 'Keine'];
      let values: Array<number> = new Array<number>();
      let allUsers = this.getUsersBornIn(year);
      let allUserCount = allUsers.length;

      let userCount: any = {
        all: allUserCount,
        rar: 0,
        gemindert: 0,
        ungemindert: 0,
        keine: 0,
      };

      for (let user of allUsers) {
        let best = user.getBestPensionFromTimeline(this.vergleichsweg);
        if (best === undefined) {
          userCount.keine++;
        } else if (best.regular_pension) {
          userCount.rar++;
        } else if (best.reduced === true) {
          userCount.gemindert++;
        } else if (best.reduced === false) {
          userCount.ungemindert++;
        }
      }

      let colors = [
        this.timelineBatch.colors.regelaltersrente,
        this.timelineBatch.colors.ungeminderte,
        this.timelineBatch.colors.geminderte,
        this.timelineBatch.colors.keine,
      ];

      this.pieCharts.data['years.' + year] = [
        {
          values: [
            userCount.rar,
            userCount.ungemindert,
            userCount.gemindert,
            userCount.keine,
          ],
          labels: [
            'Regelaltersrente',
            'Rente ohne Abschlag',
            'Rente mit Abschlag',
            'Keine Rente',
          ],
          title: {
            text: 'Jahrgang ' + year,
            position: 'bottom center',
          },
          type: 'pie',
          sort: false, //legende nicht nach eintragsgröße sortieren...
          textinfo: 'value+percent+name',
          text: 'Jahrgang ' + year + '\ntest',
          marker: {
            colors: colors,
          },
        },
      ];
      /*
      col = (col+1)%this.pieCharts.layout.grid.columns;
      if(col == 0){
        row++;
      }
      */
    }
  }
  specialRente?: string;
  getSpecialRenteChart() {
    let specialRenten = this.timelineBatch
      .getSpecialPensions()
      .filter((x: string) => x.toLowerCase().match(/rente mit [0-9]{2}/));

    if (specialRenten && specialRenten.length == 1) {
      this.specialRente = specialRenten[0];
    } else {
      console.log(
        'not found: Rente mit 65 in: ' +
          this.timelineBatch.getSpecialPensions().join(', '),
      );
      this.specialRente = undefined;
      return;
    }

    let specialRente: string = this.specialRente!;
    console.log('SpecialRente: ' + this.specialRente);
    let x: Array<number> = this.years;
    let y_all: Array<number> = new Array<number>();
    let y_rar: Array<number> = new Array<number>();
    let y_ungemindert: Array<number> = new Array<number>();
    let y_gemindert: Array<number> = new Array<number>();
    let y_keine: Array<number> = new Array<number>();
    let y_rar_abs: Array<number> = new Array<number>();
    let y_ungemindert_abs: Array<number> = new Array<number>();
    let y_gemindert_abs: Array<number> = new Array<number>();
    let y_keine_abs: Array<number> = new Array<number>();
    this.SpecialPensionGraph.layout.xaxis.nticks = this.years.length;
    this.SpecialPensionGraph.layout.xaxis.dtick = 1;
    let colors = [
      this.timelineBatch.colors.regelaltersrente,
      this.timelineBatch.colors.ungeminderte,
      this.timelineBatch.colors.geminderte,
      this.timelineBatch.colors.keine,
    ];
    this.SpecialPensionGraph.layout.title =
      'Verfügbarkeit der ' + specialRente + ' nach Jahrgang';
    this.SpecialPensionGraph.data.length = 0;
    let absolutes: any = {};
    for (let year of this.years) {
      let allUsers = this.getUsersBornIn(year);
      let userCount: any = {
        all: allUsers.length,
        rar: 0,
        gemindert: 0,
        ungemindert: 0,
        keine: 0,
      };

      if (allUsers.length >= 1) {
        let u = allUsers[0];
        let tl = u.getTimelineByCondensedName(this.vergleichsweg);
        if (tl) {
          specialRente = tl.identifier + '->' + specialRente;
        }
      }

      for (let user of allUsers) {
        let best = user.getTimelineByCondensedName(specialRente);
        if (best === undefined) {
          userCount.keine++;
        } else if (best!.regular_pension) {
          userCount.rar++;
        } else if (best!.reduced === true) {
          userCount.gemindert++;
        } else if (best!.reduced === false) {
          userCount.ungemindert++;
        }
      }

      if (userCount.all != 0) {
        y_all.push(userCount.all);
        y_rar.push((userCount.rar / userCount.all) * 100);
        y_ungemindert.push((userCount.ungemindert / userCount.all) * 100);
        y_gemindert.push((userCount.gemindert / userCount.all) * 100);
        y_keine.push((userCount.keine / userCount.all) * 100);
        y_rar_abs.push(userCount.rar);
        y_ungemindert_abs.push(userCount.ungemindert);
        y_gemindert_abs.push(userCount.gemindert);
        y_keine_abs.push(userCount.keine);
      } else {
        y_all.push(0);
        y_rar.push(0);
        y_ungemindert.push(0);
        y_gemindert.push(0);
        y_keine.push(0);
        y_rar_abs.push(0);
        y_ungemindert_abs.push(0);
        y_gemindert_abs.push(0);
        y_keine_abs.push(0);
      }
    }
    let x_augmented = x.map(
      (x, idx, arr) => x + '<br>( ' + y_all[idx] + ' Pers. )',
    );

    this.SpecialPensionGraph.data.push({
      x: x_augmented,
      y: y_rar,
      type: 'bar',
      text: y_rar.map(
        (x, idx, arr) =>
          x.toFixed(1).replace('.', ',') + '%<br>(' + y_rar_abs[idx] + ' P.)',
      ),
      textposition: 'auto',
      marker: { color: this.timelineBatch.colors.regelaltersrente },
      name: 'Regelaltersrente',
    });

    this.SpecialPensionGraph.data.push({
      x: x_augmented,
      y: y_ungemindert,
      type: 'bar',
      text: y_ungemindert.map(
        (x, idx, arr) =>
          x.toFixed(1).replace('.', ',') +
          '%<br>(' +
          y_ungemindert_abs[idx] +
          ' P.)',
      ),
      textposition: 'auto',
      marker: { color: this.timelineBatch.colors.ungeminderte },
      name: 'Rente ohne Abschlag',
    });
    this.SpecialPensionGraph.data.push({
      x: x_augmented,
      y: y_gemindert,
      text: y_gemindert.map(
        (x, idx, arr) =>
          x.toFixed(1).replace('.', ',') +
          '%<br>(' +
          y_gemindert_abs[idx] +
          ' P.)',
      ),
      textposition: 'auto',
      type: 'bar',

      marker: { color: this.timelineBatch.colors.geminderte },
      name: 'Rente mit Abschlag',
    });
    this.SpecialPensionGraph.data.push({
      x: x_augmented,
      y: y_keine,
      text: y_keine.map(
        (x, idx, arr) =>
          x.toFixed(1).replace('.', ',') + '%<br>(' + y_keine_abs[idx] + ' P.)',
      ),
      textposition: 'auto',
      type: 'bar',
      marker: { color: this.timelineBatch.colors.keine },
      name: 'Keine Rente',
    });

    //console.log(this.SpecialPensionGraph);
  }
  getGroupedBarChart() {
    let x: Array<number> = this.years;
    let y_all: Array<number> = new Array<number>();
    let y_rar: Array<number> = new Array<number>();
    let y_ungemindert: Array<number> = new Array<number>();
    let y_gemindert: Array<number> = new Array<number>();
    let y_keine: Array<number> = new Array<number>();
    let y_rar_abs: Array<number> = new Array<number>();
    let y_ungemindert_abs: Array<number> = new Array<number>();
    let y_gemindert_abs: Array<number> = new Array<number>();
    let y_keine_abs: Array<number> = new Array<number>();
    this.GroupGraph.layout.xaxis.nticks = this.years.length;
    this.GroupGraph.layout.xaxis.dtick = 1;
    let colors = [
      this.timelineBatch.colors.regelaltersrente,
      this.timelineBatch.colors.ungeminderte,
      this.timelineBatch.colors.geminderte,
      this.timelineBatch.colors.keine,
    ];

    this.GroupGraph.data.length = 0;
    let absolutes: any = {};
    for (let year of this.years) {
      let allUsers = this.getUsersBornIn(year);
      let userCount: any = {
        all: allUsers.length,
        rar: 0,
        gemindert: 0,
        ungemindert: 0,
        keine: 0,
      };

      for (let user of allUsers) {
        let best = user.getBestPensionFromTimeline(this.vergleichsweg);
        if (best === undefined) {
          userCount.keine++;
        } else if (best.regular_pension) {
          userCount.rar++;
        } else if (best.reduced === true) {
          userCount.gemindert++;
        } else if (best.reduced === false) {
          userCount.ungemindert++;
        }
      }

      if (userCount.all != 0) {
        y_all.push(userCount.all);
        y_rar.push((userCount.rar / userCount.all) * 100);
        y_ungemindert.push((userCount.ungemindert / userCount.all) * 100);
        y_gemindert.push((userCount.gemindert / userCount.all) * 100);
        y_keine.push((userCount.keine / userCount.all) * 100);
        y_rar_abs.push(userCount.rar);
        y_ungemindert_abs.push(userCount.ungemindert);
        y_gemindert_abs.push(userCount.gemindert);
        y_keine_abs.push(userCount.keine);
      } else {
        y_all.push(0);
        y_rar.push(0);
        y_ungemindert.push(0);
        y_gemindert.push(0);
        y_keine.push(0);
        y_rar_abs.push(0);
        y_ungemindert_abs.push(0);
        y_gemindert_abs.push(0);
        y_keine_abs.push(0);
      }
    }
    let x_augmented = x.map(
      (x, idx, arr) => x + '<br>( ' + y_all[idx] + ' Pers. )',
    );

    this.GroupGraph.data.push({
      x: x_augmented,
      y: y_rar,
      type: 'bar',
      text: y_rar.map(
        (x, idx, arr) =>
          x.toFixed(1).replace('.', ',') + '%<br>(' + y_rar_abs[idx] + ' P.)',
      ),
      textposition: 'auto',
      marker: { color: this.timelineBatch.colors.regelaltersrente },
      name: 'Regelaltersrente',
    });

    this.GroupGraph.data.push({
      x: x_augmented,
      y: y_ungemindert,
      type: 'bar',
      text: y_ungemindert.map(
        (x, idx, arr) =>
          x.toFixed(1).replace('.', ',') +
          '%<br>(' +
          y_ungemindert_abs[idx] +
          ' P.)',
      ),
      textposition: 'auto',
      marker: { color: this.timelineBatch.colors.ungeminderte },
      name: 'Rente ohne Abschlag',
    });
    this.GroupGraph.data.push({
      x: x_augmented,
      y: y_gemindert,
      text: y_gemindert.map(
        (x, idx, arr) =>
          x.toFixed(1).replace('.', ',') +
          '%<br>(' +
          y_gemindert_abs[idx] +
          ' P.)',
      ),
      textposition: 'auto',
      type: 'bar',

      marker: { color: this.timelineBatch.colors.geminderte },
      name: 'Rente mit Abschlag',
    });
    this.GroupGraph.data.push({
      x: x_augmented,
      y: y_keine,
      text: y_keine.map(
        (x, idx, arr) =>
          x.toFixed(1).replace('.', ',') + '%<br>(' + y_keine_abs[idx] + ' P.)',
      ),
      textposition: 'auto',
      type: 'bar',
      marker: { color: this.timelineBatch.colors.keine },
      name: 'Keine Rente',
    });
  }

  /**
   *
   * Gets the Timelines Identifier as provided in the request (i.E. without '->Regelaltersrente' etc))
   *
   **/
  getTimelineIdentifier(): string {
    return this.timelineBatch.getTimelineIdentifier(this.vergleichsweg);
  }
  getUsersBornIn(birthYear: number): Array<UserContext> {
    return this.timelineBatch.getUsersBornIn(birthYear);
  }
  getPeopleThatCan(rente: string, birthYear: number): Array<UserContext> {
    let condensedName = this.timelineIdentifier + '->' + rente;

    let users = this.getUsersBornIn(birthYear);
    let tlFilter = (timeline: Timeline) => {
      if (timeline.hasSlots(['Lücke', 'Joker'])) {
        return false;
      }
      return timeline.isTimeline(condensedName);
    };
    /*
    if(condensedName.includes('Ungeminderte Rente') && this.consider_regular_pension_unreduced){
      tlFilter = (timeline:Timeline)=>{
        if(timeline.hasSlots(['Lücke','Joker'])){
          return false;
        }
        return (timeline.isTimeline(condensedName) || timeline.isTimeline(this.timelineIdentifier+"->Regelaltersrente"));
      };
    }*/

    users = users.filter((user: UserContext) => {
      return user.timelines.filter(tlFilter).length == 1;
    });
    return users;
  }

  getYears(): Array<number> {
    return this.timelineBatch.getUserBirthYears().sort();
    //let years : Array<number> = this.timelineBatch.UserContexts().map( (user:UserContext)=>user.date_of_birth.getFullYear() );
    //return years.filter( (item,pos) => (years.indexOf(item)===pos));
  }
  ngOnInit(): void {}
}
