import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TimelineApiService } from '@services/timeline-api.service';
import { TimelineCreatorService } from '@services/timeline-creator.service';
import { UntypedFormControl, FormControl } from '@angular/forms';
import { Moment } from 'moment/moment';
import moment from 'moment';
import { environment } from '@environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  UserContext,
  IUserContext,
  IUserContextJSON,
  ISozialversicherung,
} from '@classes/user-context';
import { TimelineBatch } from '@classes/timeline-batch';
import { ITimelineRequest, TimelineRequest } from '@classes/timeline-request';
import {
  HttpErrorResponse
} from '@angular/common/http';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ISlotRequest } from '@classes/slot-request';
import { AbschlagsAusgleichService } from '@services/abschlags-ausgleich.service';

function getWindow() {
  return window;
}

@Component({
  selector: 'app-comcol-werner',
  templateUrl: './comcol-werner.component.html',
  styleUrls: ['./comcol-werner.component.scss'],
})
export class ComcolWernerComponent implements OnInit, AfterViewInit {
  @ViewChild('mainTabGroup', { read: ElementRef }) mainTabGroup: ElementRef;

  selectedTabIndex: number = 0;
  timelines: Map<string, TimelineRequest> = new Map<string, TimelineRequest>();
  datensatz: File | undefined = undefined;
  pauschaleAngaben: File | undefined = undefined;
  markerAge = new FormControl<number>(85);
  result: any = undefined;
  bridge_brutto = new FormControl<number>(1);
  bridge_netto = new FormControl<number>(1);
  pv_zusatzbeitrag = new FormControl<number>(3.4);
  kv_zusatzbeitrag = new FormControl<number>(1.7);
  rente_mit = new FormControl<number>(65);
  algDauer = new FormControl<number>(24);
  alg_brutto = new FormControl<number>(0.8);
  alg_netto = new FormControl<number>(0.6);
  phaseXDauer = new FormControl<number>(24);
  phaseX_brutto = new FormControl<number>(0.8);
  phaseX_netto = new FormControl<number>(0.6);
  phaseYDauer = new FormControl<number>(24);
  phaseY_brutto = new FormControl<number>(0.8);
  phaseY_netto = new FormControl<number>(0.6);
  atzDauer = new FormControl<number>(60);
  atz_netto = new FormControl<number>(0.85);
  atz_brutto = new FormControl<number>(0.8);
  atz_max_alter = new FormControl<number>(67);
  atz_latest_end_pension = new FormControl<string>('earliest,unreduced');
  tgDauer = new FormControl<number>(12);
  tg_brutto = new FormControl<number>(0.8);
  tg_netto = new FormControl<number>(0.85);
  kuendigungsFrist = new FormControl<number>(7);

  use_dynamic_timelines = new FormControl<boolean>(true);
  autoload = new FormControl<boolean>(true);
  erfuellte_wz_5 = new FormControl<boolean>(true);
  consider_regular_pension_unreduced = new FormControl<boolean>(true);
  erfuellte_wz_45 = new FormControl<boolean>(false);
  erfuellte_wz_35 = new FormControl<boolean>(false);
  assume_gdb_zero = new FormControl<boolean>(false);
  rente_mit_enabled = new FormControl<boolean>(false);
  kv_privat_brutto_gleich_netto = new FormControl<boolean>(true);
  maximierterVorruhestandEnabled = new FormControl<boolean>(true);
  phaseXEnabled = new FormControl<boolean>(false);
  phaseYEnabled = new FormControl<boolean>(false);
  betriebsaufloesung = new FormControl<Date>(new Date());
  betriebszugehoerigkeit_datum = new FormControl<Date>(new Date());
  empty_spaces = new FormControl<string>('joker');
  empty_spaces_vallourec = new FormControl<string>('joker');
  use_austrittstermin = new FormControl<boolean>(false);
  use_kuendigungsfrist = new FormControl<boolean>(true);
  use_betriebsaufloesung_in_alg1 = new FormControl<boolean>(true);
  use_minijob_in_alg1 = new FormControl<boolean>(false);
  getEmptySpacesHString(): string {
    switch (this.empty_spaces.value) {
      case 'bridge':
        return 'Brücke';
      case 'joker':
        return 'Joker';
      case 'luecke':
        return 'Lücke';
      default:
        return '';
    }
  }
  additional: number = 0;

  TimelineRequests: Map<string, TimelineRequest> = new Map<
    string,
    TimelineRequest
  >();

  toggleTimeline(id: string): void {
    if (this.timelineBatch.timelineRequest(id)) {
      let tl = this.timelineBatch.timelineRequest(id);
      if (tl !== undefined) {
        tl.enabled = !tl.enabled;
      }
    } else {
      console.warn('Cannot toggle non-existent timeline: ' + id);
    }
    this.save();
  }

  selectedTimeline: string = 'Keine';
  nextUser() {
    console.log('next user.');
    let tabIdx = this.selectedTabIndex;
    let users = this.timelineBatch.UserContexts();
    if (this.detailedView != undefined) {
      let userIdx = users.indexOf(this.detailedView);

      let targetUserIdx = (userIdx + 1) % users.length;
      if (targetUserIdx == 0) {
        alert('Achtung: Sprung vom letzten auf den ersten Listeneintrag');
      }
      if (targetUserIdx != userIdx) {
        this.toggleDetails(users[targetUserIdx]);
      }
      this.selectedTabIndex = 0;
      setTimeout(() => {
        this.selectedTabIndex = tabIdx;
      }, 150);
    }
  }
  getTimelineNames() {
    let wege = this.timelineBatch
      .getCondensedNames()
      .filter((x: string) => !x.toLowerCase().includes('referenzweg'));
    let order = [
      'Regelaltersrente',
      'Ungeminderte Rente',
      'Geminderte Rente',
      ...this.timelineBatch.getSpecialPensions(),
    ];
    wege = wege.sort((x: string, y: string) => {
      let _x = x;
      let _y = y;

      for (let rente of order) {
        _x = _x.replace('->' + rente, '');
        _y = _y.replace('->' + rente, '');
      }

      if (_x != _y) {
        return _x < _y ? -1 : 1;
      }
      let xR = x.split('->').pop();
      let yR = y.split('->').pop();
      if (xR && yR) {
        let _xIdx = order.indexOf(xR);
        let _yIdx = order.indexOf(yR);
        return _xIdx < _yIdx ? -1 : _yIdx < _xIdx ? 1 : 0;
      }
      return 0;
    });
    return ['Keine', ...wege];
  }

  applySorting() {
    if (this._dataSource === undefined) {
      return;
    }
    this._dataSource.sort = this.sort;
    this._dataSource.sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        case 'date_of_birth':
        case 'wz_bis':
        case 'ep_bis': {
          return item[property].getTime();
        }
        default: {
          return item[property];
        }
      }
    };
  }

  announceSortChange(sortState: any) {
    this.applySorting();
    this.timelineBatch.setSortState(sortState);
    console.log(sortState);
  }

  setTimelineFilters(): void {
    let filter = 'digest_pensions:keep_all,consider_regular_pension_unreduced';
    if (this.rente_mit_enabled.value) {
      filter += ',pension_at:' + this.rente_mit.value;
    }
    if (this.maximierterVorruhestandEnabled.value) {
      filter += ',maximized_pre_pension_phase';
    }
    this.timelineBatch.setTimelineFilters([filter]);
  }

  constructor(
    private timelineApi: TimelineApiService,
    private timelineCreationService: TimelineCreatorService,
    private spinner: NgxSpinnerService,
    private scroller: ViewportScroller,
    private ausgleich: AbschlagsAusgleichService
  ) {
    this.setup();
  }

  getDynamicTimelines(): TimelineRequest[] {
    return this.timelineCreationService.getTimelines().sort((a: ITimelineRequest, b: ITimelineRequest) => {
      return (a.identifier ?? '')?.localeCompare((b.identifier ?? ''), 'de-DE');
    });
  }

  lastSecondOfThisYear(): Date {
    let endOfYear = new Date();
    endOfYear.setMonth(11);
    endOfYear.setDate(31);
    endOfYear.setHours(23);
    endOfYear.setMinutes(59);
    endOfYear.setSeconds(59);
    return endOfYear;
  }

  setup() {

    this.timelineBatch = new TimelineBatch();
    this.betriebsaufloesung.setValue(this.lastSecondOfThisYear());
    let endOfLastYear = this.lastSecondOfThisYear();
    endOfLastYear.setFullYear(endOfLastYear.getFullYear() - 1);
    this.betriebszugehoerigkeit_datum.setValue(endOfLastYear);
    this.calculateAdditionalBetriebszugehoerigkeitsMonths();
    this.setTimelineFilters();
    this.setTimelineRequests();
    this.timelineBatch.timelineRequest('arbeit_bis_rente')?.enable();
    this.timelineBatch.timelineRequest('vallourec_1')?.enable();
    this.timelineBatch.timelineRequest('vallourec_2')?.enable();
    this.timelineBatch.timelineRequest('vallourec_3')?.enable();
    this.timelineBatch.timelineRequest('vallourec_4')?.enable();
    this.timelineBatch.timelineRequest('vallourec_5')?.enable();
    this.timelineBatch.timelineRequest('vallourec_6')?.enable();
    let controls = [
      this.markerAge,
      this.kuendigungsFrist,
      this.rente_mit,
      this.rente_mit_enabled,
      this.maximierterVorruhestandEnabled,
      this.bridge_netto,
      this.bridge_brutto,
      this.tgDauer,
      this.tg_netto,
      this.tg_brutto,
      this.algDauer,
      this.alg_netto,
      this.alg_brutto,
      this.atzDauer,
      this.atz_latest_end_pension,
      this.atz_netto,
      this.atz_brutto,
      this.atz_max_alter,
      this.betriebsaufloesung,
      this.use_dynamic_timelines,
      this.betriebszugehoerigkeit_datum,
      this.phaseXEnabled,
      this.phaseXDauer,
      this.phaseX_netto,
      this.phaseX_brutto,
      this.phaseYEnabled,
      this.phaseYDauer,
      this.phaseY_netto,
      this.phaseY_brutto,
      this.kv_privat_brutto_gleich_netto,
      this.use_austrittstermin,
      this.use_kuendigungsfrist,
      this.use_betriebsaufloesung_in_alg1,
      this.use_minijob_in_alg1,
    ];
    for (let control of controls) {
      (control as UntypedFormControl).valueChanges.subscribe(() => {
        this.setTimelineRequests();
      });
    }
    this.timelineCreationService.changed.subscribe((x) =>
      this.setTimelineRequests(),
    );
    for (let control of [
      this.betriebsaufloesung,
      this.betriebszugehoerigkeit_datum,
    ]) {
      control.valueChanges.subscribe(() => {
        this.calculateAdditionalBetriebszugehoerigkeitsMonths();
      });
    }
    this.betriebszugehoerigkeit_datum.valueChanges.subscribe((value: any) => {
      console.log('Bz changed: ');
      console.log(value);
    });
    this.betriebsaufloesung.valueChanges.subscribe((value: any) => {
      console.log('Ba changed: ');
      console.log(value);
    });

    this.empty_spaces.valueChanges.subscribe(() => {
      this.setTimelineRequests();
    });
    this.empty_spaces_vallourec.valueChanges.subscribe(() => {
      this.setTimelineRequests();
    });
    let overwrites = [
      this.erfuellte_wz_5,
      this.erfuellte_wz_35,
      this.erfuellte_wz_45,
      this.assume_gdb_zero,
      this.kv_zusatzbeitrag,
      this.pv_zusatzbeitrag,
    ];
    for (let overwrite of overwrites) {
      console.log('Updating overwrites.');
      (overwrite as UntypedFormControl).valueChanges.subscribe((x: any) => {
        this.refreshUserContextOverwrites();
      });
    }
  }
  calculateAdditionalBetriebszugehoerigkeitsMonths() {
    let baDate = this.betriebsaufloesung.value;
    let bzDate = this.betriebszugehoerigkeit_datum.value;
    if (baDate === null || bzDate === null) {
      console.warn('baDate or bzDate is null');
      return;
    }
    baDate = this.getDate(baDate);
    bzDate = this.getDate(bzDate);
    baDate.setHours(0);
    baDate.setMinutes(0);
    baDate.setSeconds(0);
    bzDate.setHours(0);
    bzDate.setMinutes(0);
    bzDate.setSeconds(0);
    let baMoment = moment(this.getDate(baDate));

    baMoment.set({ hour: 0, minute: 0, second: 0 });
    baMoment.add(1, 'day');
    let bzMoment = moment(this.getDate(bzDate));
    bzMoment.set({ hour: 0, minute: 0, second: 0 });
    if (baMoment.isAfter(bzMoment)) {
      baMoment.add(1, 'day');
    } else {
      bzMoment.add(1, 'day');
    }
    let diff = baMoment.diff(bzMoment, 'year');
    console.log({ ba: baMoment, bz: bzMoment, diff: diff });
    this.additional = diff;
    if (this.timelineBatch) {
      this.timelineBatch.setAdditionalBzMonths(diff);
    }
  }
  @ViewChild(MatSort) sort: MatSort;
  _dataSource?: MatTableDataSource<UserContext> = undefined;
  getDataSource(): MatTableDataSource<UserContext> {
    if (this._dataSource === undefined) {
      return this.updateTableSource();
    }
    return this._dataSource;
  }
  users(): Array<UserContext> {
    return this.timelineBatch.UserContexts();
  }
  refreshUserContextOverwrites() {
    let overwrite: Partial<IUserContextJSON> = {};
    let overlayItems: number = 0;
    if (this.erfuellte_wz_5.value === true) {
      overwrite['wartezeit_5_fehlend'] = 0;
      overlayItems++;
    }
    if (this.erfuellte_wz_35.value === true) {
      overwrite['wartezeit_35_fehlend'] = 0;
      overlayItems++;
    }
    if (this.assume_gdb_zero.value === true) {
      overwrite['gdb'] = 0;
      overlayItems++;
    }
    if (this.erfuellte_wz_45.value === true) {
      overwrite['wartezeit_45_fehlend'] = 0;
      overlayItems++;
    }
    if (overlayItems > 0) {
      this.timelineBatch.setUserContextOverlay(overwrite);
    } else {
      this.timelineBatch.setUserContextOverlay(undefined);
    }
    let sv: Partial<ISozialversicherung> = {};
    sv['zusatzbeitrag_kv'] = this.kv_zusatzbeitrag.value!;
    sv['zusatzbeitrag_pv'] = this.pv_zusatzbeitrag.value!;
    this.timelineBatch.setUserContextSozialversicherungOverlay(sv);
  }
  getMarkers() {
    let markers = [
      {
        title: 'AgeMarkerM10',
        at: 'age:' + (parseInt('' + this.markerAge.value!) - 10),
      },
      { title: 'AgeMarker', at: 'age:' + parseInt('' + this.markerAge.value!) },
      {
        title: 'AgeMarkerP10',
        at: 'age:' + (parseInt('' + this.markerAge.value!) + 10),
      },
    ];

    return markers;
  }
  getArbeit(kf?: boolean): Array<ISlotRequest> {
    let arr: Array<ISlotRequest> = new Array<ISlotRequest>();
    if (this.use_austrittstermin.value === false) {
      arr.push({
        type: 'Arbeit',
        start: 'rightafter:earliest_of(wz_bis,ep_bis)',
        end: this.betriebsaufloesung.value!,
      });
    } else {
      arr.push({
        type: 'Arbeit',
        start: 'rightafter:earliest_of(wz_bis,ep_bis)',
        end: 'user.meta.austrittstermin_1',
      });
    }
    if (kf === true) {
      arr.push({
        type: 'Kuendigungsfrist',
        dauer: this.kuendigungsFrist.value!,
      });
    }
    return arr;
  }

  setTimelineRequests(): void {
    this.timelineBatch.clearTimelineFilters();
    this.timelineBatch.clearMeta();
    this.timelineBatch.setMeta(
      'kv_privat_brutto_gleich_netto',
      this.kv_privat_brutto_gleich_netto.value,
    );
    this.setTimelineFilters();
    this.timelineBatch.clearSpecialPensions();
    if (this.rente_mit_enabled.value) {
      this.timelineBatch.addSpecialPension(
        'Rente mit ' + this.rente_mit.value!,
      );
    }
    if (this.maximierterVorruhestandEnabled.value) {
      this.timelineBatch.addSpecialPension(
        'Rente nach maximiertem Vorruhestand',
      );
    }

    let arbeit_bis_rente = new TimelineRequest({
      name: 'Referenzweg: Arbeit',
      enabled: this.timelineSelected('arbeit_bis_rente'),
      identifier: 'Referenzweg: Arbeit',
      padder: this.empty_spaces.value!,
      gapdata: this.getGapData(),
      markers: [
        ...this.getMarkers(),
        { title: 'counter:wz35:reached', at: 'counter:wz35:reached' },
        { title: 'counter:wz45:reached', at: 'counter:wz45:reached' },
      ],
      slots: [
        {
          type: 'Arbeit',
          start: 'rightafter:earliest_of(wz_bis,ep_bis)',
          paddingPriority: 1,
        },
        {
          type:
            this.vergleichsEinschraenkungen != 'rar'
              ? 'Rente'
              : 'Regelaltersrente',
          keep_conditions:
            this.vergleichsEinschraenkungen != 'rar'
              ? this.vergleichsEinschraenkungen
              : '',
        },
      ],
    });

    let arbeit_bis_betriebsaufloesung = new TimelineRequest({
      name: 'Nur Arbeit bis zur Betriebsauflösung',
      enabled: this.timelineSelected('arbeit_bis_betriebsaufloesung'),
      identifier: 'Arbeit bis Betriebsauflösung',
      padder: this.empty_spaces.value!,
      gapdata: this.getGapData(),
      markers: this.getMarkers(),
      slots: [...this.getArbeit(), { type: 'Rente' }],
    });
    let arbeit_bis_betriebsaufloesung_kuendigungsfrist = new TimelineRequest({
      name:
        'Arbeit -> Kündigungsfrist -> ALG1 (' +
        this.algDauer.value! +
        ' Monate)',
      enabled: this.timelineSelected(
        'arbeit_bis_betriebsaufloesung_kuendigungsfrist',
      ),
      markers: this.getMarkers(),
      padder: this.empty_spaces.value!,
      gapdata: this.getGapData(),
      identifier: 'Arbeit bis Betriebsauflösung -> Kündigungsfrist -> ALG1',
      slots: [...this.getArbeit(true), this.getALG(), { type: 'Rente' }],
    });

    let arbeit_tg_alg_rente_slots: any = [
      ...this.getArbeit(),
      this.getTG(),
      this.getALG(),
    ];

    if (this.phaseXEnabled.value) {
      arbeit_tg_alg_rente_slots.push(this.getPhaseX());
    }

    if (this.phaseYEnabled.value) {
      arbeit_tg_alg_rente_slots.push(this.getPhaseY());
    }

    arbeit_tg_alg_rente_slots.push({ type: 'Rente' });
    let arbeit_tg_alg_rente = new TimelineRequest({
      name:
        'TG (' +
        this.tgDauer.value +
        ' Monate) -> ALG1 (' +
        this.algDauer.value +
        ' Monate)',
      identifier: 'Arbeit bis Betriebsauflösung -> TG -> ALG1',
      enabled: this.timelineSelected('arbeit_tg_alg_rente'),
      padder: this.empty_spaces.value!,
      gapdata: this.getGapData(),
      markers: this.getMarkers(),
      slots: arbeit_tg_alg_rente_slots,
    });

    let arbeit_atz_alg_rente_slots: any = [
      ...this.getArbeit(),
      this.getATZ(),
      this.getALG(),
    ];
    if (this.phaseXEnabled.value) {
      arbeit_atz_alg_rente_slots.push(this.getPhaseX());
    }
    if (this.phaseYEnabled.value) {
      arbeit_atz_alg_rente_slots.push(this.getPhaseY());
    }
    arbeit_atz_alg_rente_slots.push({ type: 'Rente' });
    let arbeit_atz_alg_rente = new TimelineRequest({
      name:
        'ATZ (' +
        this.atzDauer.value +
        ' Monate) -> ALG1 (' +
        this.algDauer.value +
        ' Monate)',
      identifier: 'Arbeit bis Betriebsauflösung -> ATZ -> ALG1',
      enabled: this.timelineSelected('arbeit_atz_alg_rente'),
      padder: this.empty_spaces.value!,
      gapdata: this.getGapData(),
      markers: this.getMarkers(),
      slots: arbeit_atz_alg_rente_slots,
    });

    let arbeit_tg_rente = new TimelineRequest({
      name: 'TG (' + this.tgDauer.value + ' Monate)',
      identifier: 'Arbeit bis Betriebsauflösung -> TG',
      enabled: this.timelineSelected('arbeit_tg_rente'),
      padder: this.empty_spaces.value!,
      gapdata: this.getGapData(),
      markers: this.getMarkers(),
      slots: [...this.getArbeit(), this.getTG(), { type: 'Rente' }],
    });

    this.timelineBatch.clearTimelineRequests();
    this.timelineBatch.addTimelineRequest(arbeit_bis_rente, 'arbeit_bis_rente');
    this.timelineBatch.addTimelineRequest(
      arbeit_bis_betriebsaufloesung,
      'arbeit_bis_betriebsaufloesung',
    );
    this.timelineBatch.addTimelineRequest(
      arbeit_bis_betriebsaufloesung_kuendigungsfrist,
      'arbeit_bis_betriebsaufloesung_kuendigungsfrist',
    );
    this.timelineBatch.addTimelineRequest(
      arbeit_tg_alg_rente,
      'arbeit_tg_alg_rente',
    );
    this.timelineBatch.addTimelineRequest(
      arbeit_atz_alg_rente,
      'arbeit_atz_alg_rente',
    );
    this.timelineBatch.addTimelineRequest(arbeit_tg_rente, 'arbeit_tg_rente');

    let vallourec_1 = new TimelineRequest({
      identifier: 'Vallourec #1: Arbeit -> PhaseX -> Rente',
      name: 'Vallourec #1: Arbeit -> PhaseX -> Rente',
      markers: this.getMarkers(),
      enabled: this.timelineSelected('vallourec_1'),
      padder: this.empty_spaces_vallourec.value!,
      slots: [
        {
          bruttoFaktor: 1,
          end: 'user.meta.austrittstermin_1',
          nettoFaktor: 1,
          paddingPriority: 1,
          start: 'earliest_of(wz_bis,ep_bis)',
          type: 'Arbeit',
        },
        {
          bruttoFaktor: 1,
          maximal_dauer: 12,
          nettoFaktor: 1,
          paddingPriority: 2,
          type: 'PhaseX',
        },
        {
          type: this.empty_spaces_vallourec.value!,
          paddingPriority: 3,
        },
        {
          type: 'Rente',
        },
      ],
    });
    this.timelineBatch.addTimelineRequest(vallourec_1, 'vallourec_1');

    let vallourec_2 = new TimelineRequest({
      identifier: 'Vallourec #2: Arbeit -> PhaseX -> ALG1 -> Rente',
      name: 'Vallourec #2: Arbeit -> PhaseX -> ALG1 -> Rente',
      markers: this.getMarkers(),
      enabled: this.timelineSelected('vallourec_2'),
      padder: this.empty_spaces_vallourec.value!,
      slots: [
        {
          bruttoFaktor: 1,
          durch_geschaeftsaufgabe: true,
          end: 'user.meta.austrittstermin_1',
          nettoFaktor: 1,
          paddingPriority: 1,
          start: 'earliest_of(wz_bis,ep_bis)',
          type: 'Arbeit',
        },
        {
          bruttoFaktor: 1,
          durch_geschaeftsaufgabe: true,
          maximal_dauer: 12,
          nettoFaktor: 1,
          paddingPriority: 2,
          type: 'PhaseX',
        },
        {
          bruttoFaktor: 0.8,
          durch_geschaeftsaufgabe: true,
          maximal_dauer: 24,
          nettoFaktor: 0.6,
          paddingPriority: 3,
          type: 'ALG1',
        },
        {
          type: this.empty_spaces_vallourec.value!,
          paddingPriority: 3,
        },
        {
          type: 'Rente',
        },
      ],
    });
    this.timelineBatch.addTimelineRequest(vallourec_2, 'vallourec_2');

    let vallourec_3 = new TimelineRequest({
      identifier: 'Vallourec #3: Arbeit -> PhaseX -> Rente',
      name: 'Vallourec #3: Arbeit -> PhaseX -> Rente',
      enabled: this.timelineSelected('vallourec_3'),
      markers: this.getMarkers(),
      padder: this.empty_spaces_vallourec.value!,
      slots: [
        {
          bruttoFaktor: 1,
          end: 'user.meta.austrittstermin_1',
          nettoFaktor: 1,
          paddingPriority: 1,
          start: 'earliest_of(wz_bis,ep_bis)',
          type: 'Arbeit',
        },
        {
          bruttoFaktor: 1,
          max_alter_ende: 63,
          nettoFaktor: 1,
          paddingPriority: 2,
          type: 'PhaseX',
        },
        {
          type: this.empty_spaces_vallourec.value!,
          paddingPriority: 3,
        },
        {
          type: 'Rente',
        },
      ],
    });
    this.timelineBatch.addTimelineRequest(vallourec_3, 'vallourec_3');

    let vallourec_4 = new TimelineRequest({
      identifier: 'Vallourec #4: Arbeit -> PhaseX -> ALG1 -> Rente',
      name: 'Vallourec #4: Arbeit -> PhaseX -> ALG1 -> Rente',
      enabled: this.timelineSelected('vallourec_4'),
      markers: this.getMarkers(),
      padder: this.empty_spaces_vallourec.value!,
      slots: [
        {
          bruttoFaktor: 1,
          durch_geschaeftsaufgabe: true,
          end: 'user.meta.austrittstermin_1',
          nettoFaktor: 1,
          paddingPriority: 1,
          start: 'earliest_of(wz_bis,ep_bis)',
          type: 'Arbeit',
        },
        {
          bruttoFaktor: 1,
          durch_geschaeftsaufgabe: true,
          max_alter_ende: 63,
          nettoFaktor: 1,
          paddingPriority: 2,
          type: 'PhaseX',
        },
        {
          bruttoFaktor: 0.8,
          durch_geschaeftsaufgabe: true,
          maximal_dauer: 24,
          nettoFaktor: 0.6,
          paddingPriority: 3,
          type: 'ALG1',
        },
        {
          type: this.empty_spaces_vallourec.value!,
          paddingPriority: 3,
        },
        {
          type: 'Rente',
        },
      ],
    });
    this.timelineBatch.addTimelineRequest(vallourec_4, 'vallourec_4');

    let vallourec_5 = new TimelineRequest({
      identifier: 'Vallourec #5: Arbeit -> Transfergesellschaft -> Rente',
      name: 'Vallourec #5: Arbeit -> Transfergesellschaft -> Rente',
      enabled: this.timelineSelected('vallourec_5'),
      markers: this.getMarkers(),
      padder: this.empty_spaces_vallourec.value!,
      slots: [
        {
          bruttoFaktor: 1,
          end: 'user.meta.austrittstermin_1',
          nettoFaktor: 1,
          paddingPriority: 1,
          start: 'earliest_of(wz_bis,ep_bis)',
          type: 'Arbeit',
        },
        {
          bruttoFaktor: 0.8,
          maximal_dauer: 12,
          nettoFaktor: 0.85,
          paddingPriority: 2,
          type: 'Transfergesellschaft',
        },
        {
          type: this.empty_spaces_vallourec.value!,
          paddingPriority: 3,
        },
        {
          type: 'Rente',
        },
      ],
    });
    this.timelineBatch.addTimelineRequest(vallourec_5, 'vallourec_5');

    let vallourec_6 = new TimelineRequest({
      identifier:
        'Vallourec #6: Arbeit -> Transfergesellschaft -> ALG1 -> Rente',
      name: 'Vallourec #6: Arbeit -> Transfergesellschaft -> ALG1 -> Rente',
      markers: this.getMarkers(),
      enabled: this.timelineSelected('vallourec_6'),
      padder: this.empty_spaces_vallourec.value!,
      slots: [
        {
          bruttoFaktor: 1,
          durch_geschaeftsaufgabe: true,
          end: 'user.meta.austrittstermin_1',
          nettoFaktor: 1,
          paddingPriority: 1,
          start: 'earliest_of(wz_bis,ep_bis)',
          type: 'Arbeit',
        },
        {
          bruttoFaktor: 0.8,
          durch_geschaeftsaufgabe: true,
          maximal_dauer: 12,
          nettoFaktor: 0.85,
          paddingPriority: 2,
          type: 'Transfergesellschaft',
        },
        {
          bruttoFaktor: 0.8,
          durch_geschaeftsaufgabe: true,
          maximal_dauer: 24,
          nettoFaktor: 0.6,
          paddingPriority: 3,
          type: 'ALG1',
        },
        {
          type: this.empty_spaces_vallourec.value!,
          paddingPriority: 3,
        },
        {
          type: 'Rente',
        },
      ],
    });
    this.timelineBatch.addTimelineRequest(vallourec_6, 'vallourec_6');

    let arbeit_alg1_rente = new TimelineRequest({
      identifier: 'AAR: Arbeit -> ALG1 -> Rente',
      name: 'AAR: Arbeit -> ALG1 -> Rente',
      markers: this.getMarkers(),
      enabled: this.timelineSelected('arbeit_alg1_rente'),
      slots: [
        {
          bruttoFaktor: 1,
          durch_geschaeftsaufgabe: true,
          nettoFaktor: 1,
          paddingPriority: 2,
          start: 'earliest_of(wz_bis,ep_bis)',
          type: 'Arbeit',
        },
        {
          bruttoFaktor: 0.8,
          durch_geschaeftsaufgabe: true,
          dauer: 24,
          nettoFaktor: 0.6,
          align: 'right',
          type: 'ALG1',
        },
        {
          type: 'Rente',
        },
      ],
    });
    this.timelineBatch.addTimelineRequest(
      arbeit_alg1_rente,
      'arbeit_alg1_rente',
    );

    let arbeit_alg1_sperre_rente = new TimelineRequest({
      identifier: 'ASAR: Arbeit -> Sperre -> ALG1 -> Rente',
      name: 'ASAR: Arbeit -> Sperre -> ALG1 -> Rente',
      markers: this.getMarkers(),
      enabled: this.timelineSelected('arbeit_alg1_sperre_rente'),
      slots: [
        {
          bruttoFaktor: 1,
          durch_geschaeftsaufgabe: true,
          nettoFaktor: 1,
          paddingPriority: 2,
          start: 'earliest_of(wz_bis,ep_bis)',
          type: 'Arbeit',
        },
        {
          bruttoFaktor: 0.8,
          durch_geschaeftsaufgabe: true,
          dauer: 3,
          nettoFaktor: 0.85,
          type: 'ALG1Sperre',
          align: 'right',
        },
        {
          bruttoFaktor: 0.8,
          durch_geschaeftsaufgabe: true,
          dauer: 18,
          nettoFaktor: 0.6,
          type: 'ALG1',
          align: 'right',
        },
        {
          type: 'Rente',
        },
      ],
    });
    this.timelineBatch.addTimelineRequest(
      arbeit_alg1_sperre_rente,
      'arbeit_alg1_sperre_rente',
    );

    if (this.use_dynamic_timelines.value) {
      console.log("adding dynamic timelines.");
      for (let timeline of this.getDynamicTimelines()) {
        console.log("adding timeline");
        console.log(timeline)
        this.timelineBatch.addTimelineRequest(
          new TimelineRequest(<ITimelineRequest>{ ...(timeline.toAPIJSON()), enabled: true }),
          timeline.identifier,
        );
      }
    } else {
      console.log("not using dynamic timelines.");
    }
  }
  getPhaseX() {
    return {
      type: 'PhaseX',
      maximal_dauer: this.phaseXDauer.value!,
      paddingPriority: 1000,
      nettoFaktor: this.phaseX_netto.value! * 100,
      bruttoFaktor: this.phaseX_brutto.value! * 100,
    };
  }

  getPhaseY() {
    return {
      type: 'PhaseY',
      maximal_dauer: this.phaseYDauer.value!,
      paddingPriority: 1001,
      nettoFaktor: this.phaseY_netto.value! * 100,
      bruttoFaktor: this.phaseY_brutto.value! * 100,
    };
  }

  getALG() {
    return {
      type: 'ALG1',
      maximal_dauer: this.algDauer.value!,
      paddingPriority: 999,
      nettoFaktor: this.alg_netto.value! * 100,
      bruttoFaktor: this.alg_brutto.value! * 100,
      durch_geschaeftsaufgabe:
        this.use_betriebsaufloesung_in_alg1.value ?? false,
      minijob: this.use_minijob_in_alg1.value ?? false,
    };
  }

  getTG() {
    return {
      type: 'Transfergesellschaft',
      maximal_dauer: this.tgDauer.value!,
      paddingPriority: 998,
      nettoFaktor: this.tg_netto.value! * 100,
      bruttoFaktor: this.tg_brutto.value! * 100,
    };
  }

  getATZ() {
    let atz: any = {
      type: 'ATZ',
      maximal_dauer: this.atzDauer.value!,
      max_alter_ende: this.atz_max_alter.value!,
      paddingPriority: 998,
      nettoFaktor: this.atz_netto.value! * 100,
      bruttoFaktor: this.atz_brutto.value! * 100,
    };
    if (this.atz_latest_end_pension.value != 'none') {
      atz['latest_end_pension'] = this.atz_latest_end_pension.value;
    }
    return atz;
  }

  getGapData(): any {
    if (this.empty_spaces.value == 'bridge') {
      return {
        nettoFaktor: this.bridge_netto.value! * 100,
        bruttoFaktor: this.bridge_brutto.value! * 100,
      };
    } else {
      return {};
    }
  }

  getUserOverlay() {
    return {
      sozialversicherung: {
        zusatzbeitrag_kv: this.pv_zusatzbeitrag.value,
        zusatzbeitrag_pv: this.kv_zusatzbeitrag.value,
      },
    };
  }


  now = new Date();
  datensatzChanged(event: any) {
    this.datensatz = event?.target?.files[0];
    this.renderRequest();
  }

  timelineBatch: TimelineBatch;
  userFields: Array<string> = [
    'action',
    'identifier',
    'vorname',
    'nachname',
    'standort',
    'date_of_birth',
    'austrittstermin_1',
    'gdb',
    'kv',
    'wartezeit_35_fehlend',
    'wartezeit_45_fehlend',
    'ep',
    'ep_bis',
    'wz_bis',
    'jahresentgelt',
    'jahresnettoentgelt',
    'jahresnettoentgelt_alg',
    'jahresnettoentgelt_fuer_phase_x',
    'jahresbruttoentgelt_fuer_phase_x',
    'jahresnettoentgelt_fuer_alg_nach_phase_x',
    'steuerklasse',
    'betriebszugehoerigkeit',
  ];

  _parsedDatensatz: string | ArrayBuffer | null = null;
  parseDatensatz(datensatz: string | ArrayBuffer | null) {
    this._parsedDatensatz = datensatz;
    this.timelineBatch.fromCSV(datensatz, environment.production ? -1 : -1);
    this.refreshUserContextOverwrites();
    this._dataSource = undefined;
  }

  // kinder_unter_23: 1
  // zusatzbeitrag_kv: 1.3
  // zusatzbeitrag_pv: 3.05
  // krankenkasse: 'gesetzlich'
  // kündigungsfrist: 7M
  //
  renderRequest() {
    if (this.datensatz) {
      let datensatzReader = new FileReader();
      if (datensatzReader) {
        datensatzReader.onloadend = (e) => {
          this.parseDatensatz(datensatzReader.result);
        };
        datensatzReader.readAsText(this.datensatz);
      }
    }
  }

  getBetriebsaufloesungDate(): Date {
    return new Date(
      this.formatDate(this.betriebsaufloesung.value!)
        .split('.')
        .reverse()
        .join('/'),
    );
  }
  getDate(date: Date | Moment): Date {
    if (date instanceof Date) {
      return date;
    }
    return date.toDate();
  }

  formatDate(date: Date | Moment): string {
    if (date instanceof Date) {
      return (
        date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      );
    } else {
      return date.format('DD.MM.YYYY');
    }
  }

  getTimelineByWayName(name: string, invert?: boolean) {
    let vr: any = {};
    let personen = Object.keys(this.result);
    for (let person of personen) {
      vr[person] = { timelines: {} };
      let timelineNames = Object.keys(this.result[person].timelines);
      for (let timelineName of timelineNames) {
        let timeline = this.result[person].timelines[timelineName];

        if (
          ([undefined, false].includes(invert) &&
            timeline.originalName == name) ||
          (invert === true && timeline.originalName !== name)
        ) {
          vr[person].timelines[timelineName] = timeline;
        }
      }
    }
    return vr;
  }

  timelineSelected(id: string): boolean {
    let tl = this.timelineBatch.timelineRequest(id);
    if (tl) {
      return tl.enabled;
    } else {
      //console.warn("No timeline found for : "+id);
    }
    return false;
  }
  vergleichsEinschraenkungen: string = '';

  updateTableSource(): MatTableDataSource<UserContext> {
    this.timelineBatch.updateMaps(false);
    this._dataSource = new MatTableDataSource<UserContext>(
      this.timelineBatch.UserContexts(),
    );
    this.applySorting();
    if (this.detailedView) {
      this.detailedView = this.timelineBatch.user(this.detailedView.identifier);
    }
    return this._dataSource;
  }

  ngOnInit(): void {
    this.timelineBatch.changes$.subscribe(() => {
      this._dataSource = undefined;
      this.getDataSource();
      this.send();
    });
  }
  getDetails(): any {
    this.detailedView?.timelines;
  }

  detailedView?: UserContext;

  toggleDetails(row: Partial<IUserContext>) {
    // nothing to select.

    if (row === undefined || row.identifier === undefined) {
      return;
    }

    // nothing was selected before.
    if (this.detailedView === undefined) {
      this.detailedView = this.timelineBatch.user(row.identifier);

      return;
    } else {
      // something was selected before... deleselect first.
      let id = row.identifier;

      if (this.detailedView.identifier == row.identifier) {
        this.detailedView = undefined;

        return;
      }
      this.detailedView = undefined;
      setTimeout(() => {
        this.detailedView = this.timelineBatch.user(id);
      }, 100);
    }
  }

  reloadUserData(data: any) {
    this.spinner.show();

    this.timelineApi
      .getTimelinesForBatch(this.timelineBatch)
      .subscribe((response: TimelineBatch) => {
        console.log('got response.');
        this.timelineBatch.onLoad().next(true);
        this.triggerResize();
        this.spinner.hide();
      });
  }

  triggerResize() {
    try {
      getWindow().dispatchEvent(new Event('resize'));
    } catch {
      console.log('Error dispatching resize-event..');
    }
  }

  send() {
    //this.parseDatensatz(this._parsedDatensatz);

    this.result = undefined;
    this.detailedView = undefined;
    //console.log("sending to server.");
    //console.log("special renten: "+this.timelineBatch.getSpecialPensions().join(','));
    this.spinner.show();
    this.timelineApi.getTimelinesForBatch(this.timelineBatch).subscribe(
      (data: TimelineBatch) => {
        this.result = data;
        console.log('parsing results..');
        this.spinner.hide();
        this.updateTableSource();

        let debugUser = this.result.UserContexts()[0];
        let earliest = this.ausgleich.earliestPensionTimeline(debugUser);
        console.log("earliest for " + debugUser.name + " would be " + earliest?.getCondensedName());
        console.log("Buyable ep: " + this.ausgleich.buyableEP(debugUser));
        console.log("cost: " + this.ausgleich.maxCost(debugUser));


      },
      (error: HttpErrorResponse) => {
        console.log('got error');
        console.log(error);
        alert('Server-Error.' + error.message);
        this.spinner.hide();
      },
    );
  }

  ngAfterViewInit() {
    if (localStorage.getItem(this.localStorageKey + '.autoload') == 'true') {
      console.log('Autoloading.');
      this.autoload.setValue(true);
      this.load();
      this.send();
      this.spinner.hide();
    } else {
      this.autoload.setValue(false);
    }
    this.autoload.valueChanges.subscribe((x: boolean | null) => {
      this.applyAutoload();
    });
    this.updateTableSource();
    this.applySorting();
    if (!environment.production) {
      setTimeout(() => {
        this.mainTabGroup.nativeElement.scrollIntoView();
      }, 500);
    }
  }
  localStorageKey = 'comcol-werner-settings';
  applyAutoload() {
    if (this.autoload.value) {
      localStorage.setItem(this.localStorageKey + '.autoload', 'true');
    } else {
      localStorage.setItem(this.localStorageKey + '.autoload', 'false');
    }
  }
  save() {
    let obj = {
      datensatz: this._parsedDatensatz,
      betriebsaufloesung: this.betriebsaufloesung.value,
      betriebszugehoerigkeit_datum: this.betriebszugehoerigkeit_datum.value,
      empty_spaces: this.empty_spaces.value,
      empty_spaces_vallourec: this.empty_spaces_vallourec.value,
      consider_regular_pension_unreduced:
        this.consider_regular_pension_unreduced.value,
      pv_zusatzbeitrag: this.pv_zusatzbeitrag.value,
      kv_zusatzbeitrag: this.kv_zusatzbeitrag.value,
      algDauer: this.algDauer.value,
      alg_netto: this.alg_netto.value,
      alg_brutto: this.alg_brutto.value,

      atz_brutto: this.atz_brutto.value,
      atz_netto: this.atz_netto.value,
      atzDauer: this.atzDauer.value,
      atz_latest_end_pension: this.atz_latest_end_pension.value,
      atz_max_alter: this.atz_max_alter.value,
      tgDauer: this.tgDauer.value,
      tg_netto: this.tg_netto.value,
      tg_brutto: this.tg_brutto.value,
      assume_gdb_zero: this.assume_gdb_zero.value,
      erfuellte_wz_35: this.erfuellte_wz_35.value,
      erfuellte_wz_45: this.erfuellte_wz_45.value,
      kuendigungsFrist: this.kuendigungsFrist.value,
      rente_mit_enabled: this.rente_mit_enabled.value,
      rente_mit: this.rente_mit.value,
      timelines: {
        arbeit_bis_rente: this.timelineSelected('arbeit_bis_rente'),
        arbeit_bis_betriebsaufloesung: this.timelineSelected(
          'arbeit_bis_betriebsaufloesung',
        ),
        arbeit_bis_betriebsaufloesung_kuendigungsfrist: this.timelineSelected(
          'arbeit_bis_betriebsaufloesung_kuendigungsfrist',
        ),
        arbeit_tg_alg_rente: this.timelineSelected('arbeit_tg_alg_rente'),
        arbeit_atz_alg_rente: this.timelineSelected('arbeit_atz_alg_rente'),
        arbeit_tg_rente: this.timelineSelected('arbeit_tg_rente'),
        vallourec_1: this.timelineSelected('vallourec_1'),
        vallourec_2: this.timelineSelected('vallourec_2'),
        vallourec_3: this.timelineSelected('vallourec_3'),
        vallourec_4: this.timelineSelected('vallourec_4'),
        vallourec_5: this.timelineSelected('vallourec_5'),
        vallourec_6: this.timelineSelected('vallourec_6'),
      }
    };
    localStorage.setItem(this.localStorageKey, JSON.stringify(obj));
  }

  load() {
    let storageString = localStorage.getItem(this.localStorageKey);
    if (storageString) {
      let obj = JSON.parse(storageString);
      if (obj.datensatz) {
        this._parsedDatensatz = obj.datensatz;
        this.parseDatensatz(this._parsedDatensatz);
      }

      if (obj.betriebsaufloesung) {
        this.betriebsaufloesung.setValue(new Date(obj.betriebsaufloesung));
      }

      if (obj.betriebszugehoerigkeit_datum) {
        this.betriebszugehoerigkeit_datum.setValue(
          new Date(obj.betriebszugehoerigkeit_datum),
        );
      }
      if (obj.empty_spaces) {
        this.empty_spaces.setValue(obj.empty_spaces);
      }
      if (obj.empty_spaces_vallourec) {
        this.empty_spaces_vallourec.setValue(obj.empty_spaces_vallourec);
      }
      if (obj.rente_mit) {
        this.rente_mit.setValue(obj.rente_mit);
      }
      if (obj.rente_mit_enabled) {
        this.rente_mit_enabled.setValue(obj.rente_mit_enabled);
      }
      if (obj.consider_regular_pension_unreduced) {
        this.consider_regular_pension_unreduced.setValue(
          obj.consider_regular_pension_unreduced,
        );
      }
      if (obj.pv_zusatzbeitrag) {
        this.pv_zusatzbeitrag.setValue(obj.pv_zusatzbeitrag);
      }
      if (obj.kv_zusatzbeitrag) {
        this.kv_zusatzbeitrag.setValue(obj.kv_zusatzbeitrag);
      }
      if (obj.algDauer) {
        this.algDauer.setValue(obj.algDauer);
      }
      if (obj.alg_netto) {
        this.alg_netto.setValue(obj.alg_netto);
      }
      if (obj.alg_brutto) {
        this.alg_brutto.setValue(obj.alg_brutto);
      }
      if (obj.atzDauer) {
        this.atzDauer.setValue(obj.atzDauer);
      }
      if (obj.atz_latest_end_pension) {
        this.atz_latest_end_pension.setValue(obj.atz_latest_end_pension);
      }
      if (obj.atz_netto) {
        this.atz_netto.setValue(obj.atz_netto);
      }
      if (obj.atz_brutto) {
        this.atz_brutto.setValue(obj.atz_brutto);
      }

      if (obj.atz_max_alter) {
        this.atz_max_alter.setValue(obj.atz_max_alter);
      }
      if (obj.tgDauer) {
        this.tgDauer.setValue(obj.tgDauer);
      }
      if (obj.tg_netto) {
        this.tg_netto.setValue(obj.tg_netto);
      }
      if (obj.tg_brutto) {
        this.tg_brutto.setValue(obj.tg_brutto);
      }
      if (obj.assume_gdb_zero) {
        this.assume_gdb_zero.setValue(obj.assume_gdb_zero);
      }
      if (obj.erfuellte_wz_35) {
        this.erfuellte_wz_35.setValue(obj.erfuellte_wz_35);
      }
      if (obj.erfuellte_wz_45) {
        this.erfuellte_wz_45.setValue(obj.erfuellte_wz_45);
      }
      if (obj.kuendigungsFrist) {
        this.kuendigungsFrist.setValue(obj.kuendigungsFrist);
      }

      if (obj.timelines) {
        if (obj.timelines['arbeit_bis_rente'] === true) {
          if (this.timelineBatch.timelineRequest('arbeit_bis_rente')) {
            let tl = this.timelineBatch.timelineRequest('arbeit_bis_rente');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['arbeit_bis_betriebsaufloesung'] === true) {
          if (
            this.timelineBatch.timelineRequest('arbeit_bis_betriebsaufloesung')
          ) {
            let tl = this.timelineBatch.timelineRequest(
              'arbeit_bis_betriebsaufloesung',
            );
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (
          obj.timelines['arbeit_bis_betriebsaufloesung_kuendigungsfrist'] ===
          true
        ) {
          if (
            this.timelineBatch.timelineRequest(
              'arbeit_bis_betriebsaufloesung_kuendigungsfrist',
            )
          ) {
            let tl = this.timelineBatch.timelineRequest(
              'arbeit_bis_betriebsaufloesung_kuendigungsfrist',
            );
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['arbeit_tg_alg_rente'] === true) {
          if (this.timelineBatch.timelineRequest('arbeit_tg_alg_rente')) {
            let tl = this.timelineBatch.timelineRequest('arbeit_tg_alg_rente');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['arbeit_atz_alg_rente'] === true) {
          if (this.timelineBatch.timelineRequest('arbeit_atz_alg_rente')) {
            let tl = this.timelineBatch.timelineRequest('arbeit_atz_alg_rente');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['arbeit_tg_rente'] === true) {
          if (this.timelineBatch.timelineRequest('arbeit_tg_rente')) {
            let tl = this.timelineBatch.timelineRequest('arbeit_tg_rente');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['vallourec_1'] === true) {
          if (this.timelineBatch.timelineRequest('vallourec_1')) {
            let tl = this.timelineBatch.timelineRequest('vallourec_1');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['vallourec_2'] === true) {
          if (this.timelineBatch.timelineRequest('vallourec_2')) {
            let tl = this.timelineBatch.timelineRequest('vallourec_2');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['vallourec_3'] === true) {
          if (this.timelineBatch.timelineRequest('vallourec_3')) {
            let tl = this.timelineBatch.timelineRequest('vallourec_3');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['vallourec_4'] === true) {
          if (this.timelineBatch.timelineRequest('vallourec_4')) {
            let tl = this.timelineBatch.timelineRequest('vallourec_4');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['vallourec_5'] === true) {
          if (this.timelineBatch.timelineRequest('vallourec_5')) {
            let tl = this.timelineBatch.timelineRequest('vallourec_5');
            if (tl) {
              tl.enabled = true;
            }
          }
        }

        if (obj.timelines['vallourec_6'] === true) {
          if (this.timelineBatch.timelineRequest('vallourec_6')) {
            let tl = this.timelineBatch.timelineRequest('vallourec_6');
            if (tl) {
              tl.enabled = true;
            }
          }
        }
      }
    }
    if (!environment.production) {
      setTimeout(() => {
        if (
          this.timelineBatch.UserContexts() != undefined &&
          this.timelineBatch.UserContexts().length > 0
        ) {
          let users = this.timelineBatch
            .UserContexts()
            .filter((user) => user.identifier == 'M20');

          this.toggleDetails(
            users[0] ??
            this.timelineBatch.UserContexts()[
            this.timelineBatch.UserContexts().length - 1
            ],
          );
          this.selectedTabIndex = 1;
        } else {
          alert('datasoure is empty');
        }
      }, 500);
    }
  }
  selecteElementAndChangeTabindex(element: UserContext, tab: number) {
    setTimeout(() => {
      if (element != this.detailedView) {
        this.toggleDetails(element);
      }

      setTimeout(() => {
        this.selectedTabIndex = tab;
      }, 50);
    }, 50);
    return false;
  }
  delete() {
    localStorage.removeItem(this.localStorageKey);
  }
}
