<ng-container>
  <app-pdf-controls [user]="user" [markerAge]="markerAge"></app-pdf-controls>
  <div id="chart" *ngIf="show()">
    <apx-chart
      #chartElement
      [series]="chart.series"
      [chart]="chart.chart"
      [fill]="chart.fill"
      [legend]="chart.legend"
      [plotOptions]="chart.plotOptions"
      [xaxis]="chart.xaxis"
      [tooltip]="chart.tooltip"
      [annotations]="chart.annotations"
    ></apx-chart>
  </div>
  <div>
    <button type="button" mat-raised-button (click)="toggleShowResponse()">
      Response (click)
    </button>
    <pre *ngIf="showResponse">
			{{ user?.timelines | json }}
		</pre
    >
  </div>
</ng-container>
