import { TimelineBatch } from './timeline-batch';
import { Timeline } from './timeline';
import { UserContext } from './user-context';
import { Slot } from './slot';
import { Functoid } from './functoid';
export class FunctionCompiler {
  timelineBatch: TimelineBatch;
  vergleichsweg: string;
  maximalBetrag: number = 99999999;
  rbDeckel: boolean = false;
  rentenDeckel: string = 'dynamisch';
  constructor() {}
  setTimelineBatch(timelineBatch: TimelineBatch): void {
    this.timelineBatch = timelineBatch;
  }
  setVergleichsweg(vw: string): void {
    //console.log("Vergleichsweg: "+vw);
    this.vergleichsweg = vw;
    this.reinit();
  }
  getFunctionString(): string {
    return this.functoids
      .filter((x) => x.enabled)
      .map(
        (x: Functoid) =>
          '[' +
          x.slotName +
          ']' +
          ('(' + x.getFunctionDisplay() + ')').replace(':=', ''),
      )
      .join(' + ');
  }
  availableSlots: Array<string> = new Array<string>();
  functoids: Array<Functoid> = new Array<Functoid>();

  reinit() {
    this.availableSlots = this.getAvailableSlots();
    this.createFunctoids();
    //console.log("Available slots:");
    //console.log(this.availableSlots);
  }

  arrayUnique(value: any, index: number, self: Array<any>): boolean {
    return self.indexOf(value) === index;
  }
  getAvailableSlots(): Array<string> {
    if (this.timelineBatch === undefined) {
      console.error('TimelineBatch undefined');
      return new Array<string>();
    }
    if (this.vergleichsweg === undefined) {
      console.error('vergleichsweg undefined');
      return new Array<string>();
    }

    let timelines = this.timelineBatch
      .UserContexts()
      .map((user: UserContext) =>
        user.getTimelineByCondensedName(this.vergleichsweg),
      );
    let slotNames = new Array<string>();
    for (let tl of timelines) {
      if (tl !== undefined) {
        slotNames.push(...tl.slots.map((slot: Slot) => slot.name));
      }
    }
    return slotNames
      .filter(this.arrayUnique)
      .filter((x) => !x.toLowerCase().includes('rente'));
  }

  calculateForUsers(users: Array<UserContext>): number {
    let sum = 0;

    this.calculateForTimelines(
      users.map((x: UserContext) =>
        x.getTimelineByCondensedName(this.vergleichsweg),
      ) as Array<Timeline>,
    );
    return sum;
  }
  calculateForUsersArray(
    users: Array<UserContext>,
  ): Array<{ user: UserContext; value: number }> {
    this.rentenDeckelUnavailable = 0;
    return users.map((user: UserContext) => {
      return {
        user: user,
        value: this.calculateForTimeline(
          user.getTimelineByCondensedName(this.vergleichsweg),
        ),
      };
    });
  }
  calculateForTimelinesArray(timelines: Array<Timeline>): Array<number> {
    return timelines.map((tl: Timeline) => this.calculateForTimeline(tl));
  }
  rentenDeckelUnavailable: number = 0;

  getRentenDeckel(timeline: Timeline | undefined): number {
    if (this.rbDeckel === false) {
      this.rentenDeckelUnavailable++;
      return 999999999999999999;
    }

    let name = '';
    //console.log("getting deckel for "+this.rentenDeckel);
    if (this.rentenDeckel == 'dynamisch') {
      let pension = timeline?.getPension();
      if (pension != null) {
        name = pension.name;
      } else {
        console.log(
          'dynamic pension unavailable for ' + timeline?.user?.identifier,
        );
        this.rentenDeckelUnavailable++;
      }
    } else {
      name = this.rentenDeckel;
    }

    if (name) {
      let referenzwegRente = timeline?.user
        ?.getTimelineByCondensedName('Referenzweg: Arbeit -> ' + name)
        ?.getPension();
      if (referenzwegRente) {
        if (referenzwegRente.startBruttoeinkuenfte) {
          //console.log("RentenDeckel: "+referenzwegRente.startBruttoeinkuenfte);
          return referenzwegRente.startBruttoeinkuenfte;
        }
      }
    }
    console.log(
      'pension ' +
        this.rentenDeckel +
        ' unavailable for ' +
        timeline?.user?.identifier,
    );
    this.rentenDeckelUnavailable++;
    return 999999999999999999;
  }
  calculateForTimeline(timeline: Timeline | undefined): number {
    if (timeline === undefined) {
      return 0;
    }
    let sum = 0;
    for (let func of this.functoids) {
      if (func.enabled) {
        sum += func.calculate(timeline);
      }
    }
    if (sum > this.maximalBetrag) {
      sum = this.maximalBetrag;
    }

    let rd = this.getRentenDeckel(timeline);
    if (sum > rd) {
      sum = rd;
    }

    return sum;
  }
  calculateForTimelines(timelines: Array<Timeline>): number {
    let sum = 0;

    for (let tl of timelines) {
      sum += this.calculateForTimeline(tl);
    }
    return sum;
  }

  createFunctoids(): void {
    this.functoids = new Array<Functoid>();
    for (let slot of this.availableSlots) {
      let func = new Functoid();
      func.nFactor = 1;
      func.bFactor = 0;
      func.enabled = false;
      func.setSlotName(slot);
      this.functoids.push(func);
    }
    let betriebsZugFunc = new Functoid();
    betriebsZugFunc.nFactor = 1;
    betriebsZugFunc.bFactor = 0;
    betriebsZugFunc.setSlotName('Betriebszugehörigkeit');
    betriebsZugFunc.baseAmount = 0;
    let gdbFunc = new Functoid();
    gdbFunc.nFactor = 1;
    gdbFunc.bFactor = 0;
    gdbFunc.setSlotName('GDB');
    gdbFunc.baseAmount = 0;
    this.functoids.push(betriebsZugFunc);
    this.functoids.push(gdbFunc);
  }
}
