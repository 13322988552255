<ng-container>
  <mat-card *ngIf="functoid">
    <mat-card-title class="functoid-title">
      <span (click)="toggleDetails()">
        {{ functoid.slotName }}
        <span class="function-preview">
          {{ functoid.getFunctionDisplay() }}
        </span>
      </span>
      <mat-checkbox [formControl]="enabled"> Aktiv </mat-checkbox>
    </mat-card-title>
    <mat-card-content *ngIf="detailsVisible()">
      <mat-form-field *ngIf="functoid.slotName == 'GDB'">
        <mat-label>GDB Schwellwert</mat-label>
        <input matInput value="0" [formControl]="gdbThreshold" type="number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Sockelbetrag</mat-label>
        <input matInput value="0" [formControl]="baseAmount" type="number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>BruttoFaktor</mat-label>
        <input matInput value="0" [formControl]="bFactor" type="number" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>NettoFaktor</mat-label>
        <input matInput value="0" [formControl]="nFactor" type="number" />
      </mat-form-field>
      <mat-checkbox *ngIf="functoid.slotName == 'GDB'" [formControl]="timesGDB">
        &times; GDB
      </mat-checkbox>
      <mat-form-field>
        <mat-label>Monatsbetrag</mat-label>
        <input matInput value="0" [formControl]="monatsBetrag" type="number" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Monatsdeckel</mat-label>
        <input matInput value="0" [formControl]="monatsDeckel" type="number" />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</ng-container>
