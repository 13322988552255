import {
  ISozialversicherung,
  UserContext,
  IUserContextJSON,
  IUserContext,
} from './user-context';
import { TimelineRequest } from './timeline-request';
import { UserTimeline } from './user-timeline';
import { Subject } from 'rxjs';
import { FunctionCompiler } from './function-compiler';
import { environment } from '@environments/environment';

interface SortState {
  active: string;
  direction: 'asc' | 'desc';
}

export class TimelineBatch {
  meta: any = {};
  clearMeta() {
    this.meta = {};
  }
  setMeta(key: string, value: any) {
    this.meta[key] = value;
  }
  userContexts: Array<UserContext> = new Array<UserContext>();
  userContextsMap: Map<string, UserContext> = new Map<string, UserContext>();
  timelineRequestFilters: Array<string> = new Array<string>();
  timelineRequests: Array<TimelineRequest> = new Array<TimelineRequest>();
  timelineRequestsMap: Map<string, TimelineRequest> = new Map<
    string,
    TimelineRequest
  >();
  sortState: SortState = { active: '', direction: 'asc' };
  additionalBetriebszugehoerigkeitsMonths: number = 0;
  setAdditionalBzMonths(diff: number): void {
    this.additionalBetriebszugehoerigkeitsMonths = diff;
    for (let user of this.userContexts) {
      user.setAdditionalBzMonths(diff);
    }
  }
  setSortState(sortState: SortState) {
    this.sortState = sortState;
    this.sortUsers();
  }
  abfindungen: Map<string, FunctionCompiler> = new Map<
    string,
    FunctionCompiler
  >();
  setCompiler(str: string, comp: FunctionCompiler) {
    this.abfindungen.set(str, comp);
  }

  getCompiler(str: string): FunctionCompiler | undefined {
    return this.abfindungen.get(str);
  }
  removeCompiler(str: string): void {
    if (this.abfindungen.has(str)) {
      this.abfindungen.delete(str);
    }
  }
  clearCompilers() {
    this.abfindungen.clear();
  }
  sortUsers() {
    if (this.sortState.active == '') {
      return;
    }
    this.userContexts.sort((_a: UserContext, _b: UserContext) => {
      let a = _a;
      let b = _b;
      if (this.sortState.direction == 'desc') {
        a = _b;
        b = _a;
      }
      let field = this.sortState.active;
      let aItem = (a as any)[field];
      let bItem = (b as any)[field];
      if (['date_of_birth', 'wz_bis', 'ep_bis'].includes(field)) {
        aItem = aItem.getTime();
        bItem = bItem.getTime();
      }
      if (aItem < bItem) {
        return -1;
      }
      if (bItem < aItem) {
        return 1;
      }
      return 0;
    });
  }


  getColor(rente: string): string {
    switch (rente) {
      case 'Regelaltersrente':
        return this.colors.regelaltersrente;
      case 'Geminderte Rente':
        return this.colors.geminderte;
      case 'Ungeminderte Rente':
        return this.colors.ungeminderte;
      default:
        if (rente.startsWith('Rente mit')) {
          return this.colors.renteMit;
        }
        if (rente.startsWith('Rente nach')) {
          return this.colors.renteNachMax;
        }
        return this.colors.special;
    }
  }
  colors: any = {
    regelaltersrente: '#123D68',
    ungeminderte: '#008694',
    geminderte: '#D41E18',
    keine: '#ACD9DA',
    renteMit: '#EEAA00',
    renteNachMax: '#EEAA01',
    Arbeit: '#EEAA00',
    Kündigungsfrist: '#00ACA5',
    Transfergesellschaft: '#182746',
    Sperre_vor_ALG1: '#000000',
    ATZ: '#00ACA5',
    ALG1: '#E73934',
    'ALG1 mit Minijob': '#E73934',
    'ALG1 durch Geschäftsaufgabe oder Insolvenz': '#E73934',
    'ALG1 durch Geschäftsaufgabe oder Insolvenz mit Minijob': '#E73934',
    Lücke: '#00ACA5',
    Joker: '#00ACA5',
    Brücke: '#00ACA5',
    phaseX: '#6DD2BB',
    phaseY: '#ACD9DA',
    PhaseX: '#6DD2BB',
    PhaseY: '#ACD9DA'
  };

  fgColors: any = {
    regelaltersrente: '#000000',
    ungeminderte: '#000000',
    geminderte: '#000000',
    keine: '#000000',
    renteMit: '#000000',
    renteNachMax: '#000000',
    Arbeit: '#000000',
    Kündigungsfrist: '#000000',
    Transfergesellschaft: '#ffffff',
    ATZ: '#000000',
    ALG1: '#000000',
    'ALG1 mit Minijob': '#000000',
    'ALG1 durch Geschäftsaufgabe oder Insolvenz': '#000000',
    'ALG1 durch Geschäftsaufgabe oder Insolvenz mit Minijob': '#000000',
    Lücke: '#000000',
    Joker: '#000000',
    Brücke: '#000000',
    phaseX: '#000000',
    phaseY: '#000000',
    PhaseX: '#000000',
    PhaseY: '#000000',
    Sperre_vor_ALG1: '#ffffff',
  };
  _onLoad: Subject<boolean> = new Subject<boolean>();
  changes$: Subject<boolean> = new Subject<boolean>();
  onLoad() {
    return this._onLoad;
  }

  getUserBirthYears(): Array<number> {
    let years = new Array<number>();
    for (let user of this.UserContexts()) {
      if (!years.includes(user.date_of_birth.getFullYear())) {
        years.push(user.date_of_birth.getFullYear());
      }
    }
    return years;
  }
  checkSv() {
    this.UserContexts().forEach((user) => user.checkSv());
  }
  getUsersBornIn(year: number): Array<UserContext> {
    return this.userContexts.filter(
      (user: UserContext) => user.date_of_birth.getFullYear() == year,
    );
  }
  clearTimelineFilters(): void {
    this.timelineRequestFilters.length = 0;
  }
  addTimelineFilter(filter: string): void {
    if (!this.timelineRequestFilters.includes(filter)) {
      this.timelineRequestFilters.push(filter);
    }
  }
  setTimelineFilters(filter: Array<string>): void {
    this.timelineRequestFilters = [...filter];
  }

  reset() {
    this._usersSortedByDate = null;
    this.userContexts.length = 0;
  }
  specialPensions: Array<string> = [
    'Rente mit 65',
    'Rente nach maximiertem Vorruhestand',
  ];
  clearSpecialPensions(): void {
    this.specialPensions.length = 0;
  }
  addSpecialPension(r: string) {
    this.specialPensions.push(r);
  }
  getSpecialPensions(): Array<string> {
    return this.specialPensions;
  }
  setSpecialPensions(sp: Array<string>): void {
    this.specialPensions = [...sp];
  }
  _usersSortedByDate: Array<UserContext> | null = null;
  getUsersSortedByDate(): Array<UserContext> {
    if (this._usersSortedByDate === null) {
      this._usersSortedByDate = this.UserContexts().sort((_a, _b) => {
        let a = _a.date_of_birth.getTime();
        let b = _b.date_of_birth.getTime();
        if (a < b) {
          return -1;
        }
        if (b < a) {
          return 1;
        }
        return 0;
      });
    }
    return this._usersSortedByDate;
  }
  getTimelineIdentifier(way: string): string {
    for (let user of this.UserContexts()) {
      for (let timeline of user.timelines) {
        if (timeline.getCondensedName() == way) {
          if (timeline.identifier !== undefined) {
            return timeline.identifier;
          }
        }
      }
    }
    return '';
  }
  removeTimelineFilter(filter: string) {
    if (this.timelineRequestFilters.includes(filter)) {
      this.timelineRequestFilters.splice(
        this.timelineRequestFilters.indexOf(filter),
        1,
      );
    }
  }
  clearTimelineRequests() {
    this.timelineRequests.length = 0;
    this.timelineRequestsMap.clear();
  }

  addTimelineRequest(tlr: TimelineRequest, name?: string) {
    this.timelineRequests.push(tlr);
    this.timelineRequestsMap.set(name === undefined ? tlr.name : name, tlr);
  }

  UserContexts(): Array<UserContext> {
    return this.userContexts;
  }

  timelineRequest(identifier: string): TimelineRequest | undefined {
    if (this.timelineRequestsMap.has(identifier)) {
      return this.timelineRequestsMap.get(identifier);
    }
    return undefined;
  }

  setUserContextSozialversicherungOverlay(data?: Partial<ISozialversicherung>) {
    for (let userContext of this.userContexts) {
      userContext.setSozialversicherungOverlay(data);
    }
  }

  setUserContextOverlay(data?: Partial<IUserContextJSON>) {
    for (let userContext of this.userContexts) {
      userContext.setOverlay(data);
    }
  }

  user(identifier: string): UserContext | undefined {
    if (this.userContextsMap.has(identifier)) {
      return this.userContextsMap.get(identifier);
    }

    console.log('ContextMap has no entry for [' + identifier + ']');
    this.userContextsMap.forEach((v) => console.log(v.identifier));
    return undefined;
  }

  parseResponse(response: any): TimelineBatch {
    let userIdentifiers = Object.keys(response);
    for (let id of userIdentifiers) {
      this.user(id)?.parseTimelinesResponse(response[id]['timelines']);
    }
    return this;
  }

  getCondensedNames(): Array<string> {
    let names = new Array<string>().concat(
      ...this.userContexts.map((user: UserContext) => user.getCondensedNames()),
    );
    names = names.filter((item, pos) => names.indexOf(item) === pos);
    return names;
  }
  getRequestData() {
    return {
      context: {
        users: this.userContexts.map((x) => x.toJSON()),
        metadata: this.meta,
      },
      timelines: this.timelineRequests
        .filter((x) => x.enabled)
        .map((x) => x.toAPIJSON()),
      filters: this.timelineRequestFilters,
      output: {
        mode: 'selected-fields',
        fields: [
          'start',
          'end',
          'epPerMonth',
          'sourceIndex',
          'startEp',
          'userIdentifier',
          'bruttoeinkuenfte',
          'nettoeinkuenfte',
          'durationMonths',
          'startNettoeinkuenfte',
          'endNettoeinkuenfte',
          'startBruttoeinkuenfte',
          'type',
          'reduced',
          'regular_pension',
          'alt_name',
          'originalName',
          'timelineIdentifier',
          'markers',
          'zuschläge',
          'abschläge',
          'startage',
          'wz35',
          'wz45',
          'wz35Start',
          'wz45Start',
        ],
      },
    };
  }

  setSozialversicherung(sv: ISozialversicherung) {
    for (let userContext of this.userContexts) {
      userContext.setSozialversicherung(sv);
    }
  }
  updateMaps(triggerChangeEvent: boolean) {
    console.log('updating maps.');
    this.userContextsMap.clear();
    for (let user of this.userContexts) {
      this.userContextsMap.set(user.identifier, user);
    }
    if (triggerChangeEvent) {
      this.changes$.next(true);
    }
  }
  addUserContext(context: UserContext, triggerEvent?: boolean) {
    console.log('adding user ' + context.identifier);
    context.setBatch(this);

    this.userContexts.push(context);
    this.userContextsMap.set(context.identifier, context);
    if (triggerEvent === true) {
      this.changes$.next(true);
    }
  }
  determineColDelimiter(line: string): string {
    let colDelimiters = [
      {
        delimiter: '\t',
        items: line.split('\t').length,
      },
      {
        delimiter: ',',
        items: line.split(',').length,
      },
      {
        delimiter: ';',
        items: line.split(';').length,
      },
    ];
    let sorted = colDelimiters.sort((a, b) => {
      if (a.items < b.items) {
        return -1;
      }
      if (a.items > b.items) {
        return 1;
      }
      return 0;
    });
    console.log(colDelimiters);
    console.log(
      "Determined Delimiter: '" + sorted[sorted.length - 1].delimiter + "'",
    );
    return sorted[sorted.length - 1].delimiter;
  }
  parseNum(numString: string): number {
    throw new Error("Not yet implemented.");
    return parseFloat(numString);
  }
  fromCSV(data: string | ArrayBuffer | null, _limit: number): void {
    let rowDelimiter = '\n';
    this.reset();
    let limit = 999999999;
    if (_limit > 0) {
      limit = _limit;
    }
    //console.log(datensatz);
    let colDelimiter = '\t';
    if (typeof data == 'string') {
      let lines = data.split(rowDelimiter);
      let lineNo = 0;
      let indexLine = undefined;
      this.userContexts.length = 0;
      for (let line of lines) {
        if (lineNo > limit) {
          continue;
        }
        //console.log("Parsing line "+lineNo);

        if (lineNo == 0) {
          colDelimiter = this.determineColDelimiter(line);
          let userFields = line.split(colDelimiter);
          indexLine = userFields;
          indexLine = indexLine.map((x) => x.toLocaleLowerCase().trim());
          lineNo++;
          console.log(indexLine);
          continue;
        }

        if (indexLine === undefined) {
          console.error('Indexline undefined.');
          return;
        }

        let cells = line.split(colDelimiter);

        if (cells[indexLine.indexOf('identifier')]) {
          //console.log( "Loading user "+cells[indexLine.indexOf('identifier')] );
          let gdb = parseFloat(
            cells[indexLine.indexOf('gdb')].replace(',', '.'),
          );
          if (gdb <= 1) {
            gdb *= 100;
          }
          //console.log(cells[indexLine.indexOf('ep_bis')]);
          let context = new UserContext({
            identifier: cells[indexLine.indexOf('identifier')].trim(),
            date_of_birth: cells[indexLine.indexOf('date_of_birth')],
            gdb: gdb,
            standort: cells[indexLine.indexOf('standort')],
            jahresnettoentgelt_fuer_phase_x: parseFloat(
              cells[
                indexLine.indexOf('jahresnettoentgelt_für_phase_x')
              ].replace(',', '.'),
            ),
            jahresbruttoentgelt_fuer_phase_x: parseFloat(
              cells[
                indexLine.indexOf('jahresbruttoentgelt_für_phase_x')
              ].replace(',', '.'),
            ),
            vorname: cells[indexLine.indexOf('vorname')],
            nachname: cells[indexLine.indexOf('nachname')],
            wartezeit_5_fehlend: 0,
            wartezeit_35_fehlend: parseInt(
              cells[indexLine.indexOf('wz35_fehlend')],
            ),
            wartezeit_45_fehlend: parseInt(
              cells[indexLine.indexOf('wz45_fehlend')],
            ),
            ep: parseFloat(cells[indexLine.indexOf('ep')].replace(',', '.')),
            ep_bis: cells[indexLine.indexOf('ep_bis')],
            wz_bis: cells[indexLine.indexOf('wz_bis')],
            kv: cells[indexLine.indexOf('kv')],

            jahresentgelt: parseFloat(
              cells[indexLine.indexOf('jahresentgelt')]
                .replace('€', '')
                .replace('.', '')
                .replace(',', '.'),
            ),
            jahresnettoentgelt: parseFloat(
              cells[indexLine.indexOf('jahresnettoentgelt')]
                .replace('€', '')
                .replace('.', '')
                .replace(',', '.'),
            ),
            jahresnettoentgelt_alg: parseFloat(
              cells[indexLine.indexOf('jahresnettoentgelt_für_alg')]
                .replace('€', '')
                .replace('.', '')
                .replace(',', '.'),
            ),
            steuerklasse: parseInt(cells[indexLine.indexOf('steuerklasse')]),
            betriebszugehoerigkeit: parseInt(
              cells[indexLine.indexOf('betriebszugehoerigkeit')].replace(
                '\r',
                '',
              ),
            ),
            jahresnettoentgelt_fuer_alg_nach_phase_x: parseFloat(
              cells[
              indexLine.indexOf('jahresnettoentgelt_für_alg_nach_phase_x')
              ],
            ),
            sozialversicherung: {
              krankenkasse: cells[indexLine.indexOf('kv')],
              kinder_unter_23: 1,
              zusatzbeitrag_kv: 1.6,
              zusatzbeitrag_pv: 3.05,
            },
          });
          console.log("adding metadata.");

          context.metadata.set(
            'atz_brutto',
            cells[indexLine.indexOf('atz_brutto')],
          );
          context.metadata.set(
            'atz_netto',
            cells[indexLine.indexOf('atz_netto')],
          );
          context.metadata.set(
            'bav_brutto',
            cells[indexLine.indexOf('bav_brutto')],
          );
          context.metadata.set(
            'bav_netto',
            cells[indexLine.indexOf('bav_netto')],
          );
          context.metadata.set(
            'abfindung_1',
            cells[indexLine.indexOf('abfindung_1')],
          );
          context.metadata.set(
            'abfindung_2',
            cells[indexLine.indexOf('abfindung_2')],
          );
          context.metadata.set(
            'abfindung_3',
            cells[indexLine.indexOf('abfindung_3')],
          );
          context.metadata.set(
            'abfindung_4',
            cells[indexLine.indexOf('abfindung_4')],
          );
          context.metadata.set(
            'abfindung_5',
            cells[indexLine.indexOf('abfindung_5')],
          );
          context.metadata.set(
            'dauer_phase_x',
            parseFloat(
              cells[
              indexLine.indexOf('dauer_phase_x')
              ]
            )
          );
          context.metadata.set(
            'dauer_phase_y',
            parseFloat(
              cells[
              indexLine.indexOf('dauer_phase_y')
              ]
            )
          );

          context.metadata.set(
            'start_phase_x',
            cells[
            indexLine.indexOf('start_phase_x')
            ]
          );
          context.metadata.set(
            'start_phase_y',
            cells[
            indexLine.indexOf('start_phase_y')
            ]
          );
          context.metadata.set(
            'start_atz',
            cells[
            indexLine.indexOf('start_atz')
            ]
          );
          context.metadata.set(
            'start_tg',
            cells[
            indexLine.indexOf('start_tg')
            ]
          );
          context.metadata.set(
            'jahresnettoentgelt_fuer_alg_nach_phase_y',
            parseFloat(
              cells[
              indexLine.indexOf('jahresnettoentgelt_für_alg_nach_phase_y')
              ],
            ),
          );
          context.metadata.set(
            'jahresnettoentgelt_fuer_alg_nach_phase_x',
            parseFloat(
              cells[
              indexLine.indexOf('jahresnettoentgelt_für_alg_nach_phase_x')
              ],
            ),
          );

          context.metadata.set(
            'jahresnettoentgelt_fuer_alg_nach_tg',
            parseFloat(
              cells[
              indexLine.indexOf('jahresnettoentgelt_für_alg_nach_tg')
              ],
            ),
          );
          context.metadata.set(
            'jahresnettoentgelt_fuer_phase_y',
            parseFloat(
              cells[
                indexLine.indexOf('jahresnettoentgelt_für_phase_y')
              ].replace(',', '.'),
            ),
          );
          context.metadata.set(
            'jahresbruttoentgelt_fuer_phase_y',
            parseFloat(
              cells[
                indexLine.indexOf('jahresbruttoentgelt_für_phase_y')
              ].replace(',', '.'),
            ),
          );

          context.metadata.set(
            'jahresnettoentgelt_fuer_phase_x',
            parseFloat(
              cells[
                indexLine.indexOf('jahresnettoentgelt_für_phase_x')
              ].replace(',', '.'),
            ),
          );
          context.metadata.set(
            'jahresbruttoentgelt_fuer_phase_x',
            parseFloat(
              cells[
                indexLine.indexOf('jahresbruttoentgelt_für_phase_x')
              ].replace(',', '.'),
            ),
          );
          context.metadata.set('jahresentgelt', parseFloat(
            cells[indexLine.indexOf('jahresentgelt')]
              .replace('€', '')
              .replace('.', '')
              .replace(',', '.'),
          ));
          context.metadata.set('jahresnettoentgelt', parseFloat(
            cells[indexLine.indexOf('jahresnettoentgelt')]
              .replace('€', '')
              .replace('.', '')
              .replace(',', '.'),
          ));
          context.metadata.set('jahresnettoentgelt_alg', parseFloat(
            cells[indexLine.indexOf('jahresnettoentgelt_für_alg')]
              .replace('€', '')
              .replace('.', '')
              .replace(',', '.'),
          ));
          context.metadata.set(
            'jahresbruttoentgelt_tg',
            parseFloat(cells[indexLine.indexOf('jahresbruttoentgelt_für_tg')]),
          );
          context.metadata.set(
            'jahresnettoentgelt_tg',
            parseFloat(cells[indexLine.indexOf('jahresnettoentgelt_für_tg')]),
          );
          context.metadata.set(
            'austrittstermin_1',
            cells[indexLine.indexOf('austrittstermin_1')],
          );

          this.addUserContext(context);
        }

        lineNo++;
      }
      this.setAdditionalBzMonths(this.additionalBetriebszugehoerigkeitsMonths);
    }
    this.checkSv();
  }
}
