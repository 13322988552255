import { Slot } from './slot';
import { UserContext } from './user-context';
import { Timeline } from './timeline';

export class Functoid {
  enabled: boolean = false;
  bFactor: number = 0;
  nFactor: number = 1;
  monatsBetrag: number = 0;
  timesGDB: boolean = false;
  baseAmount: number = 0;
  slotName: string = '';
  monatsDeckel: number = 999999;
  field: string = 'durationMonths';
  displayName: string = '<Dauer-in-Monaten>';
  specialSlots = ['Betriebszugehörigkeit', 'Alter', 'GDB'];
  threshold: number = 50;
  calcFn: (timeline: Timeline) => number = this.calculateTimelineSlot;
  setSlotName(name: string) {
    this.slotName = name;
    //console.log("Setting slotname to "+this.slotName);
    switch (name) {
      case 'Betriebszugehörigkeit': {
        this.calcFn = this.calculateBetriebszugehoerigkeit;
        return;
      }
      case 'GDB': {
        this.calcFn = this.calculateGDB;
        this.timesGDB = true;
        return;
      }
      default: {
        this.calcFn = this.calculateTimelineSlot;
        return;
      }
    }
  }
  getFunctionDisplay(): string {
    let str = '';
    str =
      ':= ' +
      this.baseAmount +
      ' + ( ' +
      this.displayName +
      ' x (' +
      this.bFactor +
      ' x  <Monatsbrutto> +' +
      this.nFactor +
      'x  <Monatsnetto> + ' +
      this.monatsBetrag +
      ') )';
    if (this.timesGDB && this.slotName == 'GDB') {
      str =
        ':= ' +
        this.baseAmount +
        ' + ( ' +
        this.displayName +
        ' x (' +
        this.bFactor +
        ' x  <Monatsbrutto> +' +
        this.nFactor +
        'x  <Monatsnetto> + ' +
        this.monatsBetrag +
        ') x GDB ) )';
    }
    if (this.slotName == 'Betriebszugehörigkeit') {
      str =
        ':= ' +
        this.baseAmount +
        ' + ( ' +
        this.displayName +
        ' x (' +
        this.bFactor +
        ' x  <Monatsbrutto> +' +
        this.nFactor +
        'x  <Monatsnetto> + ' +
        this.monatsBetrag +
        ') x <Betriebszugehoerigkeit> ) )';
    }
    return str;
  }

  getDisplayName(): string {
    return this.displayName + '( ' + this.slotName + ' )';
  }
  calculate(timeline: Timeline): number {
    return this.calcFn(timeline);
  }

  calculateGDB(timeline: Timeline): number {
    let gdb = timeline.user?.gdb;
    if (gdb === undefined) {
      gdb = 0;
    }
    //console.log("checking against threshold: "+gdb+" against "+this.threshold);
    if (gdb < this.threshold) {
      return 0;
    }

    let sum = this.baseAmount;
    let klammer = 0;
    if (this.bFactor != 0) {
      klammer += this.bFactor * this.getMonatsBrutto(timeline);
    }
    if (this.nFactor > 0) {
      klammer += this.nFactor * this.getMonatsNetto(timeline);
    }
    klammer += this.monatsBetrag;

    if (this.timesGDB) {
      klammer *= gdb;
    }
    if (klammer > this.monatsDeckel) {
      klammer = this.monatsDeckel;
    }
    return sum + klammer;
  }

  calculateBetriebszugehoerigkeit(timeline: Timeline): number {
    let betriebszugehoerigkeit = timeline.user?.betriebszugehoerigkeit;
    if (betriebszugehoerigkeit === undefined) {
      betriebszugehoerigkeit = 0;
    }

    let sum = this.baseAmount;
    let klammer = 0;
    if (this.bFactor != 0) {
      klammer += this.bFactor * this.getMonatsBrutto(timeline);
    }
    if (this.nFactor > 0) {
      klammer += this.nFactor * this.getMonatsNetto(timeline);
    }
    if (this.slotName == 'Betriebszugehörigkeit') {
      klammer *= betriebszugehoerigkeit;
    }
    klammer += this.monatsBetrag;
    if (klammer > this.monatsDeckel) {
      klammer = this.monatsDeckel;
    }
    return sum + klammer;
  }

  getMonatsBrutto(timeline: Timeline): number {
    let jahresentgelt = timeline?.user?.jahresentgelt;
    let bruttoEntgelt = 1;
    if (jahresentgelt !== undefined) {
      bruttoEntgelt = jahresentgelt / 12;
    }
    return bruttoEntgelt;
  }
  getMonatsNetto(timeline: Timeline): number {
    let jahresnettoentgelt = timeline?.user?.jahresnettoentgelt;
    let bruttoEntgelt = 1;
    if (jahresnettoentgelt !== undefined) {
      bruttoEntgelt = jahresnettoentgelt / 12;
    }
    return bruttoEntgelt;
  }
  calculateTimelineSlot(timeline: Timeline): number {
    let sum = this.baseAmount != undefined ? this.baseAmount : 0;
    let gdb = timeline.user?.gdb;
    if (gdb === undefined) {
      gdb = 0;
    }
    let betriebszugehoerigkeit = timeline.user?.betriebszugehoerigkeit;
    if (betriebszugehoerigkeit === undefined) {
      betriebszugehoerigkeit = 0;
    }
    let slots: Array<Slot> = timeline.slots.filter(
      (slot: Slot) => slot.name == this.slotName,
    );
    let bruttoEntgelt = this.getMonatsBrutto(timeline);
    let nettoEntgelt = this.getMonatsNetto(timeline);
    for (let _slot of slots) {
      let slot = _slot as any;
      if (slot[this.field]) {
        let klammer = 0;
        klammer = this.bFactor * bruttoEntgelt;
        klammer += this.nFactor * nettoEntgelt;
        if (this.slotName == 'Betriebszugehörigkeit') {
          klammer *= betriebszugehoerigkeit;
        }
        if (this.timesGDB) {
          klammer *= gdb;
        }
        klammer += this.monatsBetrag;
        if (klammer > this.monatsDeckel * slot[this.field]) {
          klammer = this.monatsDeckel * slot[this.field];
        }
        sum += klammer * slot[this.field];
      }
    }
    return sum;
  }
}
