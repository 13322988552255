import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formular',
  templateUrl: './formular.component.html',
  styleUrls: ['./formular.component.scss'],
})
export class FormularComponent implements OnInit {
  constructor() {}
  now: Date = new Date();
  startDateGeburtstag: Date = new Date(1960, 0, 1);
  startDateWZBis: Date = new Date(this.now.getFullYear() - 1, 11, 31);
  startDateEPBis: Date = new Date(this.now.getFullYear() - 1, 11, 31);
  wz35_type: string = '';
  wz45_type: string = '';
  weg: string | undefined = undefined;
  ngOnInit(): void {}
}
