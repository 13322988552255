<ng-container>
  <div class="wrap">
    <div
      [style.margin-top.em]="3"
      [style.width.em]="80"
      fxLayout="column"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="1em"
      fxLayoutAlign="center"
    >
      <mat-stepper orientation="vertical">
        <mat-step
          fxLayout="column"
          flex
          fxLayoutGap="30px"
          label="Angaben zur Person 1/2"
        >
          <mat-card>
            <mat-card-subtitle>Angaben zur Person</mat-card-subtitle>
            <mat-card-subtitle
              >Bitte geben Sie Ihr Geburtsdatum ein</mat-card-subtitle
            >

            <mat-card-content>
              <mat-form-field>
                <input
                  hintLabel="Geburtsdatum"
                  matInput
                  [matDatepicker]="picker"
                  placeholder="Geburtsdatum"
                />

                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>

                <mat-datepicker
                  startView="year"
                  [startAt]="startDateGeburtstag"
                  #picker
                >
                </mat-datepicker>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </mat-step>
        <mat-step label="Angaben zur Person 2/2">
          <mat-card>
            <mat-card-subtitle>Angaben zur Person</mat-card-subtitle>
            <mat-card-subtitle
              >Liegt ein Grad der Behinderung von mindestens 50%
              vor?</mat-card-subtitle
            >
            <mat-card-content>
              <mat-button-toggle-group
                name="gdb"
                aria-label="Grad der Behinderung"
              >
                <mat-button-toggle value="ja">Ja</mat-button-toggle>
                <mat-button-toggle value="nein">Nein</mat-button-toggle>
              </mat-button-toggle-group>
            </mat-card-content>
          </mat-card>
        </mat-step>
        <mat-step label="Rentenbeginnermittlung 1/2">
          <mat-card>
            <mat-card-subtitle>35-jährige Wartezeit</mat-card-subtitle>
            <mat-card-subtitle>Die 35-jährige Wartezeit...</mat-card-subtitle>
            <mat-card-content>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="wz35_type"
              >
                <mat-radio-button class="example-radio-button" value="erfuellt">
                  Ist erfüllt!
                </mat-radio-button>
                <mat-radio-button
                  class="example-radio-button"
                  value="nicht_erfuellt"
                >
                  Ist nicht erfüllt...
                </mat-radio-button>
                <div *ngIf="wz35_type == 'nicht_erfuellt'">
                  <div>
                    <span> ... denn es fehlen noch </span
                    ><input
                      matInput
                      [style.width.em]="4"
                      min="1"
                      max="420"
                      type="number"
                    /><span> Monate am</span>
                  </div>
                  <div>
                    <mat-form-field>
                      <input
                        hintLabel="Stand"
                        matInput
                        [matDatepicker]="pickerwz35"
                        placeholder="Stand der Wartezeiten"
                      />

                      <mat-datepicker-toggle matSuffix [for]="pickerwz35">
                      </mat-datepicker-toggle>

                      <mat-datepicker
                        startView="year"
                        [startAt]="startDateWZBis"
                        #pickerwz35
                      >
                      </mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <mat-radio-button
                  class="example-radio-button"
                  value="unerfuellbar"
                >
                  Kann nicht mehr erfüllt werden
                </mat-radio-button>
              </mat-radio-group>
            </mat-card-content>
          </mat-card>
        </mat-step>

        <mat-step label="Rentenbeginnermittlung 2/2">
          <mat-card>
            <mat-card-subtitle>45-jährige Wartezeit</mat-card-subtitle>
            <mat-card-subtitle>Die 45-jährige Wartezeit...</mat-card-subtitle>
            <mat-card-content>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="wz45_type"
              >
                <mat-radio-button class="example-radio-button" value="erfuellt">
                  Ist erfüllt!
                </mat-radio-button>
                <mat-radio-button
                  class="example-radio-button"
                  value="nicht_erfuellt"
                >
                  Ist nicht erfüllt...
                </mat-radio-button>
                <div *ngIf="wz45_type == 'nicht_erfuellt'">
                  <div>
                    <span> ... denn es fehlen noch </span
                    ><input
                      matInput
                      [style.width.em]="4"
                      min="1"
                      max="420"
                      type="number"
                    /><span> Monate am</span>
                  </div>
                  <div>
                    <mat-form-field>
                      <input
                        hintLabel="Stand"
                        matInput
                        [matDatepicker]="pickerwz45"
                        placeholder="Stand der Wartezeiten"
                      />

                      <mat-datepicker-toggle matSuffix [for]="pickerwz45">
                      </mat-datepicker-toggle>

                      <mat-datepicker
                        startView="year"
                        [startAt]="startDateWZBis"
                        #pickerwz45
                      >
                      </mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <mat-radio-button
                  class="example-radio-button"
                  value="unerfuellbar"
                >
                  Kann nicht mehr erfüllt werden
                </mat-radio-button>
              </mat-radio-group>
            </mat-card-content>
          </mat-card>
        </mat-step>

        <mat-step label="Rentenhöhenermittlung 1/2">
          <mat-card>
            <mat-card-subtitle
              >Wieviele EP (Entgeltpunkte) haben Sie?</mat-card-subtitle
            >
            <mat-card-subtitle></mat-card-subtitle>
            <mat-card-content>
              <mat-form-field>
                <mat-label>Gesammelte Entgeltpunkte</mat-label>
                <input
                  matInput
                  placeholder="Gesammelte Entgeltpunkte"
                  hintLabel="EP"
                  min="1"
                  max="420"
                  type="number"
                />
              </mat-form-field>
              <div>
                <mat-form-field>
                  <input
                    placeholder="Stand der Entgeltpunkte"
                    hintLabel="Stand"
                    matInput
                    [matDatepicker]="pickerEPBis"
                  />

                  <mat-datepicker-toggle matSuffix [for]="pickerEPBis">
                  </mat-datepicker-toggle>

                  <mat-datepicker
                    startView="year"
                    [startAt]="startDateEPBis"
                    #pickerEPBis
                  >
                  </mat-datepicker>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-step>

        <mat-step label="Rentenhöhenermittlung 2/2">
          <mat-card>
            <mat-card-subtitle
              >Rentenversicherungspflichtiges Einkommen</mat-card-subtitle
            >
            <mat-card-subtitle></mat-card-subtitle>
            <mat-card-content>
              <mat-form-field>
                <mat-label>Einkommen</mat-label>
                <input
                  matInput
                  placeholder="Einkommen"
                  hintLabel="Einkommen"
                  min="1"
                  max="420"
                  type="number"
                />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Jahr des Einkommens</mat-label>
                <input
                  matInput
                  placeholder="Jahr des Einkommens"
                  hintLabel="Jahr des Einkommens"
                  min="1"
                  max="420"
                  type="number"
                />
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </mat-step>
        <mat-step label="Weg in die Rente">
          <mat-card>
            <mat-card-subtitle>Weg in die Rente</mat-card-subtitle>
            <mat-card-subtitle></mat-card-subtitle>
            <mat-card-content>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="weg"
              >
                <mat-radio-button
                  class="example-radio-button"
                  value="laengste_alg"
                >
                  Transfergesellschaft -> ALG1 mit maximierter Dauer ->
                  Rentenbeginn
                </mat-radio-button>
                <mat-radio-button
                  class="example-radio-button"
                  value="frueheste_rente"
                  hintLabel="Wählen Sie diesen Weg, um so früh wie möglich in Rente zu gehen"
                >
                  Transfergesellschaft -> ALG1 mit kürzester Dauer -> frühester
                  Rentenbeginn
                </mat-radio-button>
              </mat-radio-group>

              <mat-form-field *ngIf="weg != undefined">
                <mat-label>Dauer der Transfergesellschaft</mat-label>
                <br />
                <input
                  matInput
                  hintLabel="Dauer der Transfergesellschaft"
                  placeHolder="Dauer der Transfergesellschaft"
                  type="number"
                  value="6"
                  min="1"
                  max="72"
                /> </mat-form-field
              ><br />
              <mat-form-field *ngIf="weg != undefined">
                <mat-label>Beginn der Transfergesellschaft</mat-label>
                <input
                  hintLabel="Beginn der Transfergesellschaft"
                  matInput
                  [matDatepicker]="tgPicker"
                  placeholder="Beginn der Transfergesellschaft"
                />

                <mat-datepicker-toggle matSuffix [for]="tgPicker">
                </mat-datepicker-toggle>
                <mat-datepicker startView="year" [startAt]="now" #tgPicker>
                </mat-datepicker>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </mat-step>
        <mat-step label="Sozialversicherung">
          <mat-card>
            <mat-card-subtitle>Sozialversicherung</mat-card-subtitle>
            <mat-card-subtitle></mat-card-subtitle>
            <mat-card-content>
              <div>
                <mat-label>Krankenversicherung</mat-label>
                <mat-button-toggle-group
                  name="krankenversicherung"
                  aria-label="Krankenversicherung"
                >
                  <mat-button-toggle value="gesetzlich"
                    >Gesetzlich</mat-button-toggle
                  >
                  <mat-button-toggle value="privat">Privat</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <mat-form-field>
                <mat-label>KV-Zusatzbeitrag</mat-label>
                <br />
                <input
                  matInput
                  placeholder="Zusatzbeitrag (Krankenkasse)"
                  hintLabel="Zusatzbeitrag (Krankenkasse)"
                  min="0"
                  max="5"
                  step="0.1"
                  value="1.3"
                  type="number"
                />
              </mat-form-field>
              <div>
                <mat-label>Haben Sie mindestens ein Kind?</mat-label><br />
                <mat-button-toggle-group
                  name="Kinderlosenzuschlag"
                  aria-label="Kinderlosenzuschlag"
                >
                  <mat-button-toggle value="mit_kind">Ja</mat-button-toggle>
                  <mat-button-toggle value="ohne_kind">Nein</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-step>
      </mat-stepper>
    </div>
  </div>

  <!--
					<mat-card-actions  
					fxLayout="row"
					fxLayoutAlign="space-evenly"
					>
					<button mat-raised-button 
					[class.invisible]="stepIdx == 0"
					[disabled]="stepIdx == 0"
					(click)="back()"
					>Zurück</button>
					<button 
					mat-raised-button 
					[class.invisible]="(stepIdx == (steps.length-1))"[disabled]="(stepIdx == steps.length-1)"
					(click)="forward()"
					>Weiter</button>
					</mat-card-actions>
				-->
</ng-container>
