import { ISlotRequest, SlotRequest } from './slot-request';

export interface ITimelineMarkerRequest {
  title: string;
  at: string;
}

export interface ITimelineRequest {
  name: string;
  slots: Array<ISlotRequest>;
  enabled?: boolean;
  padder?: string;
  gapdata?: any;
  identifier?: string;
  markers?: Array<ITimelineMarkerRequest>;
  originalData?: any;
}
export class TimelineRequest {
  name: string = '';
  order: string = 'ltr';
  slots: Array<SlotRequest> = new Array<SlotRequest>();
  enabled: boolean = false;
  identifier?: string;
  padder?: string;
  gapdata?: any;
  markers?: Array<ITimelineMarkerRequest>;


  originalData: any = undefined;
  setOriginalData(od: any) {
    this.originalData = od;
  }
  getOriginalData(): any {
    return this.originalData;
  }


  constructor(data: ITimelineRequest) {
    this.fromJSON(data);
  }

  enable() {
    this.enabled = true;
  }
  disable() {
    this.enabled = false;
  }
  setName(name: string): void {
    this.name = name;
  }
  getName(): string {
    return this.name;
  }
  insertSlot(
    slotData: Partial<ISlotRequest>,
    position: number,
  ): TimelineRequest {
    let s = new SlotRequest().fromJSON(slotData);
    this.slots.splice(position, 0, s);
    return this;
  }

  setSlots(slots: Array<ISlotRequest>) {
    this.slots.length = 0;
    for (let slotData of slots) {
      this.slots.push(new SlotRequest().fromJSON(slotData));
    }
    return this;
  }
  fromJSON(data: ITimelineRequest): TimelineRequest {
    this.name = data.name;
    this.setSlots(data.slots);
    if (data.enabled !== undefined) {
      this.enabled = data.enabled;
    }
    if (data.originalData) {
      this.originalData = data.originalData;
    }
    if (data.padder) {
      this.padder = data.padder;
    }
    if (data.gapdata) {
      this.gapdata = data.gapdata;
    }
    if (data.identifier) {
      this.identifier = data.identifier;
    }
    if (data.markers) {
      this.markers = data.markers;
    }

    return this;
  }
  toAPIJSON() {
    return {
      order: this.order,
      name: this.name,
      padder: this.padder,
      gapdata: this.gapdata,
      identifier: this.identifier,
      slots: this.slots.map((x) => x.toJSON()),
      markers: this.markers,
    };
  }
  toJSON() {
    return {
      ...this.toAPIJSON(),
      originalData: this.originalData
    };
  }
}
