import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Moment } from 'moment/moment';
import { UserContext } from '@classes/user-context';
import { Timeline } from '@classes/timeline';
import { Slot } from '@classes/slot';
import { PdfRenderService } from '@services/pdf-render.service';
import { Subject } from 'rxjs';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexXAxis,
  ApexFill,
  ApexLegend,
  ApexTooltip,
  ApexAnnotations,
} from 'ng-apexcharts';

@Component({
  selector: 'app-vorruhestand-display',
  templateUrl: './vorruhestand-display.component.html',
  styleUrls: ['./vorruhestand-display.component.scss'],
})
export class VorruhestandDisplayComponent implements OnInit, OnDestroy {
  update$: Subject<any> = new Subject<any>();
  @ViewChild('chartElement') chartElement: any;
  @Input('user') user: UserContext | undefined = undefined;
  @Input('betriebsaufloesung') betriebsaufloesung: Date;
  @Input('visible') visible: boolean = false;
  @Input('markerAge') markerAge: number = 85;
  constructor(pdf: PdfRenderService) { }
  showResponse: boolean = false;
  toggleShowResponse() {
    this.showResponse = !this.showResponse;
  }
  parseDate(str: string): Date {
    return new Date(str.split('.').reverse().join('/'));
  }
  timelineBuckets: any = {};
  getBuckets(): Array<any> | undefined {
    return this.user?.getTimelineBucketList();
  }
  getClasses(slot: any) {
    let o: any = {};
    o[slot.name] = true;
    return o;
  }

  latest: Date = new Date(8640000000000000);
  earliest: Date = new Date(-8640000000000000);
  earliestPension: Date = new Date(8640000000000000);
  init() {
    if (this.user) {
      if (this.user.latest()) {
        this.latest = this.user.latest();
        //console.log("Latest point in time for user "+this.user.identifier);
        //console.log(this.latest);
      } else {
        //console.log("unable to determine latest point:");
      }
      if (this.user.earliest()) {
        this.earliest = this.user.earliest();
        //console.log("earliest point in time for user "+this.user.identifier);
        //console.log(this.earliest);
      } else {
        //console.log("unable to determine earliest point:");
      }

      this.earliest.setMonth(this.earliest.getMonth() - 2);
      this.latest.setMonth(this.latest.getMonth() + 6);

      this.setChartOptions();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['user']) {
        if (this.chart.series) {
          this.chart.series.length = 0;
        }
        this._show = false;
        setTimeout(() => {
          this.init();
          this.update$.next(true);
        }, 150);
      }
    }
  }
  ngOnDestroy() {
    //console.log("destroying!");
  }

  ngOnInit(): void { }

  /**
   * Chart-Kack
   */
  public chart: any = {
    series: [],
    chart: {
      height: 450,
      zoom: {
        enabled: true,
      },
      type: 'rangeBar',
      animations: {
        enabled: false,
        speed: 10,
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }: { series: any, seriesIndex: any, dataPointIndex: any, w: any }) => {
        console.log("series");
        console.log(series);
        console.log(seriesIndex);
        console.log(dataPointIndex);
        console.log(w);

        let weg = w.globals.seriesX[seriesIndex][dataPointIndex];
        let wegIdentifier = weg;
        if (weg.charAt(weg.length - 1) == ':') {
          wegIdentifier = weg.slice(0, weg.length - 1);
        }
        let tl = this.user
          ?.getTimelineByCondensedName(wegIdentifier, true);
        if (tl === undefined) {
          console.log("cannot read timeline " + weg);
        }

        let phase = w.globals.seriesNames[seriesIndex];

        let phaseName = phase
          .replace(':', '')
          .trim()
          .replace('Phase ', 'Phase');
        console.log("Phase Name: " + phaseName);
        let slot = tl?.getSlot(phaseName, (slot: Slot) => (slot.end?.getTime() === series[seriesIndex][dataPointIndex]));
        if (phaseName.toLowerCase().includes('rente')) {
          slot = tl?.getPension();
        }
        let currency_fmt: any = ['de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'currency', currency: 'EUR' }];
        let fmt: any = ['de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 2 }];
        let manyDigits: any = ['de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 8 }];
        let dateFmt: any = ['fr-CH'];
        return '<div class="arrow_box">' +
          '<table>' +
          /*'< tr > <th>Name < /th><th>Wert</th > </tr>' +*/
          '<tr><td>Weg</td><td>' + weg + '</td></tr>' +
          '<tr><td><b>Phase</td><td>' + (slot?.name ?? ('(' + phase + ')')) + '</td></tr>' +
          '<tr><td>Dauer</td><td>' + (slot?.durationMonths ?? ' - ') + ' Monate</td></tr>' +
          '<tr><td>Begin</td><td>' + (slot?.start?.toLocaleDateString(...dateFmt) ?? ' - ') + ' ( ' + (slot?.startage?.split(',').join(' ') ?? ' - ') + ' )</td></tr>' +
          '<tr><td>End</td><td>' + (slot?.end?.toLocaleDateString(...dateFmt) ?? ' - ') + '</td></tr>' +
          '<tr><td>Bruttoeinkuenfte</td><td> ' + (slot?.bruttoeinkuenfte?.toLocaleString(...currency_fmt) ?? '-') + '</td></tr>' +
          '<tr><td>Nettoeinkuenfte</td><td> ' + (slot?.nettoeinkuenfte?.toLocaleString(...currency_fmt) ?? '-') + '</td></tr>' +
          '<tr><td>EP</td><td> ' + (slot?.epPerMonth?.toLocaleString(...manyDigits) ?? '-') + ' pro Monat </td></tr>' +
          '<tr><td>Abschläge</td><td> ' + (slot?.abschläge?.toLocaleString(...manyDigits) ?? '-') + '% </td></tr>' +
          '<tr><td>Zuschläge</td><td> ' + (slot?.zuschläge?.toLocaleString(...manyDigits) ?? '-') + '% </td></tr>' +
          '<tr><td>Netto Gesamt zum Start</td><td> ' + (slot?.startNettoeinkuenfte?.toLocaleString(...currency_fmt) ?? '-') + '</td></tr>' +
          '<tr><td>Netto Gesamt zum Ende</td><td> ' + (slot?.endNettoeinkuenfte?.toLocaleString(...currency_fmt) ?? '-') + '</td></tr>' +
          '<tr><td>Brutto Gesamt zum Start</td><td> ' + (slot?.startBruttoeinkuenfte?.toLocaleString(...currency_fmt) ?? '-') + '</td></tr>' +
          '<tr><td>Brutto Gesamt zum Ende</td><td> ' + (slot?.endBruttoeinkuenfte?.toLocaleString(...currency_fmt) ?? '-') + '</td></tr>' +
          '</table></div>'
      },
      aus_custom: (opts: any) => {
        const fromYear = new Date(opts.y1).toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        const toYear = new Date(opts.y2).toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        console.log(opts);
        const values = opts.ctx.rangeBar.getTooltipValues?.(opts);
        let weg = values?.ylabel; //.replace(':','');
        if (weg.charAt(weg.length - 1) == ':') {
          weg = weg.slice(0, weg.length - 1);
        }

        let phase = values.seriesName
          .replace(':', '')
          .trim()
          .replace('Phase ', 'Phase');
        //console.log("Phase:");
        //console.log(phase);
        let nettoEnd = this.getProMonat('endNettoeinkuenfte', weg, phase);
        let bruttoBegin = this.getProMonat('startBruttoeinkuenfte', weg, phase);
        let nettoBegin = this.getProMonat('startNettoeinkuenfte', weg, phase);
        let nettoSpan = 'N/A';

        if (nettoEnd != undefined && nettoBegin != undefined) {
          nettoSpan = nettoEnd - nettoBegin + '';
        }
        let startAge = this.user
          ?.getTimelineByCondensedName(weg, true)
          ?.getSlot(phase)?.startage;
        if (startAge == undefined) {
          startAge = this.user
            ?.getTimelineByCondensedName(weg, true)
            ?.getPension()?.startage;
        }
        return (
          '<div class="arrow_box">' +
          '<span>Weg: ' +
          weg +
          '</span><br/>' +
          '<span>Phase: ' +
          phase +
          '</span><br/>' +
          '<span>Nettoeinkünfte pro Monat:' +
          this.getProMonat('nettoeinkuenfte', weg, phase) +
          ' €</span><br/>' +
          '<span>Brutto pro Monat:' +
          this.getProMonat('bruttoeinkuenfte', weg, phase) +
          ' €</span><br/>' +
          '<span>EP pro Monat:' +
          this.getProMonat('epPerMonth', weg, phase) +
          '</span><br/>' +
          '<span>Dauer:' +
          this.getProMonat('durationMonths', weg, phase) +
          ' Monate</span><br/>' +
          '<span>Abschläge:' +
          this.getProMonat('abschläge', weg, phase) +
          ' %</span><br/>' +
          '<span>Zuschläge:' +
          this.getProMonat('zuschläge', weg, phase) +
          ' %</span><br/>' +
          '<span>Brutto Start:' +
          bruttoBegin +
          ' €</span><br/>' +
          '<span>Netto Start:' +
          nettoBegin +
          ' €</span><br/>' +
          '<span>Netto End:' +
          nettoEnd +
          ' €</span><br/>' +
          '<span>Netto End-Start:' +
          nettoSpan +
          ' €</span><br/>' +
          '<span>StartAge: ' +
          startAge +
          '</span><br/>'
        );
        '<span><b>' +
          values.seriesName +
          '</b></span><br/>' +
          '<span>' +
          fromYear +
          ' - ' +
          toYear +
          '</span>' +
          '</div>';
      },
    },
    annotations: {
      xaxis: [],
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.75,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    legend: {},
    xaxis: {
      type: 'datetime',
      tickAmount: 64,
      labels: {
        format: 'MM/yyyy',
        rotate: -15,
        rotateAlways: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        rangeBarGroupRows: true,
      },
    },
  };

  getProMonat(
    field:
      | 'nettoeinkuenfte'
      | 'epPerMonth'
      | 'bruttoeinkuenfte'
      | 'durationMonths'
      | 'abschläge'
      | 'zuschläge'
      | 'startBruttoeinkuenfte'
      | 'endBruttoeinkuenfte'
      | 'startNettoeinkuenfte'
      | 'endNettoeinkuenfte',
    weg: string,
    phase: string,
  ): number | undefined {
    if (this.user?.getTimelineByCondensedName(weg, true) !== undefined) {
      if (
        this.user?.getTimelineByCondensedName(weg, true)?.getSlot(phase) !==
        undefined
      ) {
        if (
          this.user?.getTimelineByCondensedName(weg, true)?.getSlot(phase)![
          field
          ] !== undefined
        ) {
          //console.log("got "+field+" on " +phase + " of "+weg);
        } else {
          //console.log("missing "+field+" on " +phase + " of "+weg);
        }
      } else {
        //console.log("missing phase " +phase + " of "+weg);
      }
    } else {
      //console.log("missing weg "+weg);
    }
    let tl = this.user?.getTimelineByCondensedName(weg, true);
    let slot: Slot | undefined;
    if (tl != undefined) {
      if (phase.toLowerCase().includes('rente')) {
        slot = tl.getPension();
      } else {
        slot = tl.getSlot(phase);
      }
    }
    if (slot === undefined) {
      if (
        [
          'epPerMonth',
          'zuschläge',
          'abschläge',
          'nettoeinkuenfte',
          'durationMonths',
        ].includes(field)
      ) {
        return 0;
      }
      return undefined;
    }
    if (slot[field] != undefined) {
      let retVal = slot[field];
      return retVal!;
    } else {
      return undefined;
    }
  }

  getSeriesData(type: string): Array<any> {
    if (this.user === undefined) {
      return [];
    }
    return this.user.getSlotsByType(type).map((slot: Slot) => {
      return {
        x: slot.timeline?.getCondensedName(true),
        y: [slot.start?.getTime(), slot.end?.getTime()],
      };
    });
  }

  getUngeminderteRentenSeries() {
    if (this.user === undefined) {
      return [];
    }
    return this.user
      .getSlotsByFilterFunction(
        (slot: Slot) =>
          slot.name.toLowerCase().includes('rente') &&
          !slot.timeline?.reduced &&
          !slot.timeline?.regular_pension,
      )
      .map((slot: Slot) => {
        return {
          x: slot.timeline?.getCondensedName(true),
          y: [slot.start?.getTime(), this.latest.getTime()],
        };
      });
  }

  getGeminderteRentenSeries() {
    if (this.user === undefined) {
      return [];
    }
    return this.user
      .getSlotsByFilterFunction(
        (slot: Slot) =>
          slot.name.toLowerCase().includes('rente') && slot.timeline?.reduced,
      )
      .map((slot: Slot) => {
        return {
          x: slot.timeline?.getCondensedName(true),
          y: [slot.start?.getTime(), this.latest.getTime()],
        };
      });
  }

  getRegelaltersRenteSeries() {
    if (this.user === undefined) {
      return [];
    }
    return this.user
      .getSlotsByFilterFunction(
        (slot: Slot) =>
          slot.name.toLowerCase().includes('rente') &&
          slot.timeline?.regular_pension,
      )
      .map((slot: Slot) => {
        return {
          x: slot.timeline?.getCondensedName(true),
          y: [slot.start?.getTime(), this.latest.getTime()],
        };
      });
  }

  getSeries(): Array<any> {
    //console.log("calc series from");
    //console.log(this.user?.constructor.name);
    let arbeit: any = {
      name: 'Arbeit',
      data: this.getSeriesData('Arbeit'),
      color: this.user?.getBatch()?.colors.Arbeit,
    };
    let tg: any = {
      name: 'Transfergesellschaft',
      data: this.getSeriesData('Transfergesellschaft'),
      color: this.user?.getBatch()?.colors.Transfergesellschaft,
    };

    let atz: any = {
      name: 'ATZ',
      data: this.getSeriesData('ATZ'),
      color: this.user?.getBatch()?.colors.ATZ,
    };

    let alg1: any = {
      name: 'ALG1',
      data: this.getSeriesData('ALG1'),
      color: this.user?.getBatch()?.colors.ALG1,
    };

    let alg1_gesch: any = {
      name: 'ALG1 durch Geschäftsaufgabe oder Insolvenz',
      data: this.getSeriesData('ALG1 durch Geschäftsaufgabe oder Insolvenz'),
      color: this.user?.getBatch()?.colors.ALG1,
    };

    let alg1_mj: any = {
      name: 'ALG1 mit Minijob',
      data: this.getSeriesData('ALG1 mit Minijob'),
      color: this.user?.getBatch()?.colors.ALG1,
    };

    let alg1_gesch_mj: any = {
      name: 'ALG1 durch Geschäftsaufgabe oder Insolvenz mit Minijob',
      data: this.getSeriesData(
        'ALG1 durch Geschäftsaufgabe oder Insolvenz mit Minijob',
      ),
      color: this.user?.getBatch()?.colors.ALG1,
    };

    let luecke: any = {
      name: 'Lücke',
      data: this.getSeriesData('Lücke'),
      color: this.user?.getBatch()?.colors.Lücke,
    };

    let sperre: any = {
      name: 'Sperre vor ALG1',
      data: this.getSeriesData('Sperre_vor_ALG1'),
      color: this.user?.getBatch()?.colors.Sperre_vor_ALG1,
    };
    let kündigungsfrist: any = {
      name: 'Kündigungsfrist',
      data: this.getSeriesData('Kündigungsfrist'),
      color: this.user?.getBatch()?.colors.Kündigungsfrist,
    };
    let bruecke: any = {
      name: 'Brücke',
      data: this.getSeriesData('Brücke'),
      color: this.user?.getBatch()?.colors.Brücke,
    };
    let joker: any = {
      name: 'Joker',
      data: this.getSeriesData('Joker'),
      color: this.user?.getBatch()?.colors.Joker,
    };

    let phaseY: any = {
      name: 'Phase Y',
      data: this.getSeriesData('PhaseY'),
      color: this.user?.getBatch()?.colors.phaseY,
    };

    let phaseX: any = {
      name: 'Phase X',
      data: this.getSeriesData('PhaseX'),
      color: this.user?.getBatch()?.colors.phaseX,
    };

    let renten: Array<any> = [
      {
        name: 'Geminderte Rente',
        data: this.getGeminderteRentenSeries(),
        color: this.user?.getBatch()?.colors.geminderte,
      },
      {
        name: 'Ungeminderte Rente',
        data: this.getUngeminderteRentenSeries(),
        color: this.user?.getBatch()?.colors.ungeminderte,
      },
      {
        name: 'Regelaltersrente',
        data: this.getRegelaltersRenteSeries(),
        color: this.user?.getBatch()?.colors.regelaltersrente,
      },
    ];

    this.earliestPension = new Date(this.latest.getTime());
    let rentenSlots: Array<Slot> | undefined =
      this.user?.getSlotsByFilterFunction((slot: Slot) => {
        return slot.name.toLowerCase().includes('rente');
      });
    if (rentenSlots !== undefined) {
      let ungeminderteRenten: Array<Slot> | undefined = rentenSlots?.filter(
        (slot: Slot) => {
          return slot?.timeline?.reduced === false;
        },
      );
      if (ungeminderteRenten) {
        let timestamps = ungeminderteRenten.map(
          (slot: Slot) => slot?.start?.getTime(),
        );
        let earliestPensionTimestamp: number | undefined = timestamps.sort()[0];
        if (earliestPensionTimestamp !== undefined) {
          this.earliestPension = new Date(earliestPensionTimestamp);
        }
      }
    }

    let series = [
      arbeit,
      tg,
      atz,
      alg1,
      alg1_gesch,
      alg1_mj,
      alg1_gesch_mj,
      luecke,
      joker,
      bruecke,
      phaseX,
      phaseY,
      kündigungsfrist,
      sperre,
      ...renten,
    ].filter((x) => x.data.length != 0);
    return series;
  }

  _show: boolean = false;
  show(): boolean {
    return this._show && this.visible;
  }

  setChartOptions() {
    if (this.chart.series) {
      this.chart.series.length = 0;
    }
    for (let serie of this.getSeries()) {
      this.chart.series.push(serie);
    }

    if (this.chart.xaxis) {
      this.chart.xaxis.min = this.earliest?.getTime();
      this.chart.xaxis.max = this.latest?.getTime();

      //console.log("this.chart.xaxis");
      //console.log(this.chart.xaxis);
      //console.log(new Date(this.chart.xaxis.min));
      //console.log(new Date(this.chart.xaxis.max));
    }

    this.chart.annotations = {
      xaxis: [
        {
          x: this.earliestPension.getTime(),
          borderColor: '#000',
          label: {
            position: 'top',
            text: 'Früheste Rente ohne Abschlag',
            style: {
              color: '#000',
            },
          },
        },
        {
          x: this.betriebsaufloesung.getTime(),
          borderColor: '#000',
          label: {
            position: 'bottom',
            text: 'Betriebsauflösung',
            style: {
              color: '#000',
            },
          },
        },
      ],
    };

    this.chart.legend = {
      position: 'top',
      horizontalAlign: 'left',
    };

    this._show = true;
    //console.log(this.chart);
  }
}
