import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TimelineBatch } from '@classes/timeline-batch';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TimelineApiService {
  apiURL: string = 'https://buber-entwicklungsbackend.00101010.de/api';
  //apiURL:string = 'http://localhost:8000/api';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) {
    this.apiURL = environment.apiURL;
  }

  getTimelinesForBatch(batch: TimelineBatch): Observable<TimelineBatch> {
    return this.httpClient
      .post<TimelineBatch>(
        this.apiURL + '/timeline',
        batch.getRequestData(),
        this.httpOptions,
      )
      .pipe(map((data) => batch.parseResponse(data)));
  }
}
