<ng-container>
  Aktuelle Funktion: {{ getOverallFunctionString() }}
  <br />
  <mat-card>
    <mat-card-title>Begrenzungen</mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <mat-label>MaximalBetrag</mat-label>
        <input matInput value="0" [formControl]="maximalBetrag" type="number" />
      </mat-form-field>
      <br /><br /><br />
      <mat-checkbox class="example-margin" [formControl]="rbDeckel">
        Abfindung durch Bruttoeinkünfte bis Rentenbeginn auf dem Referenzweg
        deckeln
        <br /><br /><span *ngIf="missingRentenDeckel > 0"
          >Rentendeckel konnte für {{ missingRentenDeckel }} Benutzer nicht
          berechnet werden da die entsprechende Rente für diese Personen nicht
          verfügbar ist. Der Maximalbetrag greift in diesen Fällen als
          Deckel.</span
        ><br />
      </mat-checkbox>
      <br /><br /><br />
      <div *ngIf="rbDeckel.value">
        <mat-label> RentendeckelTyp: </mat-label>

        <mat-select
          [formControl]="rentenDeckelTyp"
          panelClass="rentenWahlPanel"
        >
          <mat-option
            [style.width]="'30em'"
            *ngFor="let _rentenDeckelTyp of rentenDeckelTypen"
            style="width: 30em"
            value="{{ _rentenDeckelTyp }}"
            [selected]="_rentenDeckelTyp == rentenDeckelTyp.value"
          >
            {{ _rentenDeckelTyp }}
          </mat-option>
        </mat-select>
      </div>
    </mat-card-content>
  </mat-card>
  <!--
	    <select *ngIf="rbDeckel">
	    	<option *ngFor="let _rentenDeckelTyp of rentenDeckelTypen" value="_rentenDeckelTyp">{{_rentenDeckelTyp}}</option>
	    </select>
	-->

  <br />
  <div *ngFor="let functoid of getFunctoids()">
    <app-functoid [functoid]="functoid" (onChange)="redraw()"></app-functoid>
  </div>
  <h4>Abfindung / Boxplot</h4>
  <plotly-plot
    *ngIf="charts.perYearBoxPlot"
    [data]="charts.perYearBoxPlot.data"
    [updateOnDataChange]="true"
    [style]="{ position: 'relative', display: 'inline-block', width: '75vw' }"
    [useResizeHandler]="true"
    [divId]="'perYearBoxPlot'"
    [revision]="revision"
  >
  </plotly-plot>

  <h4>Abfindung pro Jahrgang</h4>
  <plotly-plot
    *ngIf="charts.perYear"
    [data]="charts.perYear.data"
    [updateOnDataChange]="true"
    [style]="{ position: 'relative', display: 'inline-block', width: '75vw' }"
    [useResizeHandler]="true"
    [divId]="'perYear'"
    [revision]="revision"
  >
  </plotly-plot>

  <h4>Abfindung pro Person</h4>
  <plotly-plot
    *ngIf="charts.perUser"
    [data]="charts.perUser.data"
    [layout]="charts.perUser.layout"
    [updateOnDataChange]="true"
    [style]="{ position: 'relative', display: 'inline-block', width: '75vw' }"
    [useResizeHandler]="true"
    [divId]="'perUser'"
    [revision]="revision"
  >
  </plotly-plot>

  <h4>Abfindung pro Jahrgang pro Person</h4>
  <plotly-plot
    *ngIf="charts.perYearPerPerson"
    [data]="charts.perYearPerPerson.data"
    [updateOnDataChange]="true"
    [style]="{ position: 'relative', display: 'inline-block', width: '75vw' }"
    [useResizeHandler]="true"
    [divId]="'perYear'"
    [revision]="revision"
  >
  </plotly-plot>
  <h4>Abfindung pro Einkommensgruppe (Nach Bruttoentgelt in der Tabelle)</h4>
  <plotly-plot
    *ngIf="charts.perIncomeGroup"
    [data]="charts.perIncomeGroup.data"
    [updateOnDataChange]="true"
    [style]="{ position: 'relative', display: 'inline-block', width: '75vw' }"
    [useResizeHandler]="true"
    [divId]="'perIncomeGroup'"
    [revision]="revision"
  >
  </plotly-plot>
  <h4>
    Abfindung pro Einkommensgruppe pro Person (Nach Bruttoentgelt in der
    Tabelle)
  </h4>
  <plotly-plot
    *ngIf="charts.perIncomeGroupPerPerson"
    [data]="charts.perIncomeGroupPerPerson.data"
    [updateOnDataChange]="true"
    [style]="{ position: 'relative', display: 'inline-block', width: '75vw' }"
    [useResizeHandler]="true"
    [divId]="'perIncomeGroupPerPerson'"
    [revision]="revision"
  >
  </plotly-plot>
  <div>Gesamt: {{ gesamtausgaben | number: "1.0-0" }}€</div>
</ng-container>
