<ng-container>
  <fieldset>
    <legend>Persönliche Daten</legend>
    <mat-form-field>
      <mat-label>Identifier</mat-label>
      <input matInput type="text" [formControl]="identifier" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Geburtsdatum</mat-label>
      <input
        matInput
        hintLabel="Geburtstag"
        [matDatepicker]="birthday_picker"
        [formControl]="date_of_birth"
        placeholder="Geburtstag"
      />
      <mat-datepicker-toggle matSuffix [for]="birthday_picker">
      </mat-datepicker-toggle>
      <mat-datepicker
        startView="year"
        [startAt]="this.date_of_birth.value"
        #birthday_picker
      >
      </mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Grad der Behinderung</mat-label>
      <input matInput type="number" [formControl]="gdb" />
    </mat-form-field>
  </fieldset>

  <fieldset>
    <legend>Daten vom Rentenbescheid</legend>
    <mat-form-field>
      <mat-label>Fehlende 5-jährige Wartezeit </mat-label>
      <input matInput type="number" [formControl]="wartezeit_5_fehlend" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fehlende 35-jährige Wartezeit </mat-label>
      <input matInput type="number" [formControl]="wartezeit_35_fehlend" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fehlende 45-jährige Wartezeit </mat-label>
      <input matInput type="number" [formControl]="wartezeit_45_fehlend" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>WZ Bis</mat-label>
      <input
        matInput
        hintLabel="WZ bis"
        [matDatepicker]="wz_bis_picker"
        [formControl]="wz_bis"
        placeholder="EP bis"
      />
      <mat-datepicker-toggle matSuffix [for]="wz_bis_picker">
      </mat-datepicker-toggle>
      <mat-datepicker
        startView="year"
        [startAt]="this.wz_bis.value"
        #wz_bis_picker
      >
      </mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>EP</mat-label>
      <input matInput type="number" [formControl]="ep" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>EP Bis</mat-label>
      <input
        matInput
        hintLabel="EP bis"
        [matDatepicker]="ep_bis_picker"
        [formControl]="ep_bis"
        placeholder="EP bis"
      />
      <mat-datepicker-toggle matSuffix [for]="ep_bis_picker">
      </mat-datepicker-toggle>
      <mat-datepicker
        startView="year"
        [startAt]="this.ep_bis.value"
        #ep_bis_picker
      >
      </mat-datepicker>
    </mat-form-field>
  </fieldset>
  <fieldset>
    <legend>Einkommen</legend>
    <mat-form-field>
      <mat-label>Jahresentgelt</mat-label>
      <input matInput type="number" [formControl]="jahresentgelt" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Jahresentgelt Netto </mat-label>
      <input matInput type="number" [formControl]="jahresnettoentgelt" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Jahresentgelt Netto ALG</mat-label>
      <input matInput type="number" [formControl]="jahresnettoentgelt_alg" />
    </mat-form-field>
  </fieldset>

  <fieldset>
    <legend>Sozialversicherung</legend>
    <mat-form-field>
      <mat-label>Kinder unter 23</mat-label>
      <input matInput type="number" [formControl]="kinder_unter_23" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Zusatzbeitrag KV</mat-label>
      <input matInput type="number" [formControl]="zusatzbeitrag_kv" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Zusatzbeitrag PV</mat-label>
      <input matInput type="number" [formControl]="zusatzbeitrag_pv" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Krankenkasse</mat-label>
      <mat-select [formControl]="krankenkasse">
        <mat-option [value]="'gesetzlich'">Gesetzlich</mat-option>
        <mat-option [value]="'privat'">Privat</mat-option>
      </mat-select>
    </mat-form-field>
  </fieldset>
  <pre>{{ user | json }}</pre>
</ng-container>
