import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, FormControl } from '@angular/forms';
import { UserContext } from '@classes/user-context';
import { DateAdapter } from '@angular/material/core';
@Component({
  selector: 'app-userform',
  templateUrl: './userform.component.html',
  styleUrls: ['./userform.component.scss'],
})
export class UserformComponent implements OnInit {
  @Input('user') user?: UserContext;
  identifier = new FormControl<string>('');
  wartezeit_5_fehlend = new FormControl<number>(0);
  wartezeit_35_fehlend = new FormControl<number>(0);
  wartezeit_45_fehlend = new FormControl<number>(0);
  gdb = new FormControl<number>(0);
  ep = new FormControl<number>(0);
  jahresentgelt = new FormControl<number>(0);
  jahresnettoentgelt = new FormControl<number>(0);
  jahresnettoentgelt_alg = new FormControl<number>(0);
  betriebszugehoerigkeit = new FormControl<number>(0);
  steuerklasse = new FormControl<number>(0);
  kinder_unter_23 = new FormControl<number>(1);
  zusatzbeitrag_kv = new FormControl<number>(1.3);
  zusatzbeitrag_pv = new FormControl<number>(3.05);
  krankenkasse = new FormControl<string>('gesetzlich');

  date_of_birth = new FormControl<Date>(new Date());
  ep_bis = new FormControl<Date>(new Date());
  wz_bis = new FormControl<Date>(new Date());
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('de-DE');
  }

  ngOnInit(): void {
    for (let control of [
      this.identifier,
      this.date_of_birth,
      this.ep_bis,
      this.wz_bis,
      this.gdb,
      this.wartezeit_5_fehlend,
      this.wartezeit_35_fehlend,
      this.wartezeit_45_fehlend,
      this.ep,
      this.jahresentgelt,
      this.jahresnettoentgelt,
      this.jahresnettoentgelt_alg,
      this.steuerklasse,
      this.kinder_unter_23,
      this.zusatzbeitrag_kv,
      this.zusatzbeitrag_pv,
      this.krankenkasse,
    ]) {
      (control as UntypedFormControl).valueChanges.subscribe((x) =>
        this.writeUser(),
      );
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['user']) {
      this.readUser();
    }
  }
  readUser() {
    if (this.user === undefined) {
      return;
    }
    this.identifier.setValue(this.user.identifier);
    this.date_of_birth.setValue(this.user.date_of_birth);
    this.ep_bis.setValue(this.user.ep_bis);
    this.wz_bis.setValue(this.user.wz_bis);
    this.gdb.setValue(this.user.gdb);
    this.wartezeit_5_fehlend.setValue(this.user.wartezeit_5_fehlend);
    this.wartezeit_35_fehlend.setValue(this.user.wartezeit_35_fehlend);
    this.wartezeit_45_fehlend.setValue(this.user.wartezeit_45_fehlend);
    this.ep.setValue(this.user.ep);
    this.jahresentgelt.setValue(this.user.jahresentgelt);
    this.jahresnettoentgelt.setValue(this.user.jahresnettoentgelt);
    this.jahresnettoentgelt_alg.setValue(this.user.jahresnettoentgelt_alg);
    this.steuerklasse.setValue(this.user.steuerklasse);
    this.kinder_unter_23.setValue(this.user.sozialversicherung.kinder_unter_23);
    this.zusatzbeitrag_kv.setValue(
      this.user.sozialversicherung.zusatzbeitrag_kv,
    );
    this.zusatzbeitrag_pv.setValue(
      this.user.sozialversicherung.zusatzbeitrag_pv,
    );
    this.krankenkasse.setValue(this.user.sozialversicherung.krankenkasse);
  }

  serializeDate(date: Date): string {
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  parseDate(date: any): Date {
    if (date instanceof Date) {
      return date;
    }

    return new Date(date.split('.').reverse().join('-') + ' 00:00:01');
  }
  writeUser() {
    if (this.user === undefined) {
      return;
    }

    this.user.identifier = this.identifier.value ?? this.user.identifier;
    this.user.date_of_birth =
      this.date_of_birth.value ?? this.user.date_of_birth;
    this.user.ep_bis = this.ep_bis.value ?? this.user.ep_bis;
    this.user.wz_bis = this.wz_bis.value ?? this.user.wz_bis;
    this.user.gdb = this.gdb.value ?? this.user.gdb;
    this.user.wartezeit_5_fehlend =
      this.wartezeit_5_fehlend.value ?? this.user.wartezeit_5_fehlend;
    this.user.wartezeit_35_fehlend =
      this.wartezeit_35_fehlend.value ?? this.user.wartezeit_35_fehlend;
    this.user.wartezeit_45_fehlend =
      this.wartezeit_45_fehlend.value ?? this.user.wartezeit_45_fehlend;
    this.user.ep = this.ep.value ?? this.user.ep;
    this.user.jahresentgelt =
      this.jahresentgelt.value ?? this.user.jahresentgelt;
    this.user.jahresnettoentgelt =
      this.jahresnettoentgelt.value ?? this.user.jahresnettoentgelt;
    this.user.jahresnettoentgelt_alg =
      this.jahresnettoentgelt_alg.value ?? this.user.jahresnettoentgelt_alg;
    this.user.steuerklasse = this.steuerklasse.value ?? this.user.steuerklasse;
    this.user.sozialversicherung.kinder_unter_23 =
      this.kinder_unter_23.value ??
      this.user.sozialversicherung.kinder_unter_23;
    this.user.sozialversicherung.zusatzbeitrag_kv =
      this.zusatzbeitrag_kv.value ??
      this.user.sozialversicherung.zusatzbeitrag_kv;
    this.user.sozialversicherung.zusatzbeitrag_pv =
      this.zusatzbeitrag_pv.value ??
      this.user.sozialversicherung.zusatzbeitrag_pv;

    this.user.sozialversicherung.krankenkasse =
      this.krankenkasse?.value ?? this.user.sozialversicherung.krankenkasse;
    this.user.kv =
      this.krankenkasse?.value ?? this.user.sozialversicherung.krankenkasse;
  }
}
