import * as moment from 'moment';
import { Timeline } from './timeline';

export interface ISlot {
  name: string;
  type: string;
  start?: Date;
  end?: Date;
  align?: string;
  durationMonths?: number;
  originalName?: string;
  epPerMonth?: number;
  timeline?: Timeline;
  bruttoeinkuenfte?: number;
  nettoeinkuenfte?: number;
  startBruttoeinkuenfte?: number;
  endBruttoeinkuenfte?: number;
  endNBruttoeinkuenfte?: number;
  startNettoeinkuenfte?: number;
  endNettoeinkuenfte?: number;
  zuschläge?: number;
  abschläge?: number;
  startage?: string;
  wz35Start?: number;
  wz45Start?: number;
  startEp?: number;
}

export class Slot {
  name: string;
  type: string;
  start?: Date;
  end?: Date;
  align?: string;
  durationMonths?: number;
  originalName?: string;
  epPerMonth?: number;
  timeline?: Timeline;
  bruttoeinkuenfte?: number;
  nettoeinkuenfte?: number;
  startBruttoeinkuenfte?: number;
  endBruttoeinkuenfte?: number;
  startNettoeinkuenfte?: number;
  endNettoeinkuenfte?: number;
  zuschläge?: number;
  abschläge?: number;
  startage?: string;
  wz35Start?: number;
  wz45Start?: number;
  startEp?: number;

  dates(): Array<Date> {
    let arr: Array<Date> = [];
    if (this.start != undefined) {
      arr.push(this.start);
    }
    if (this.end != undefined) {
      arr.push(this.end);
    }

    return arr;
  }

  earliest(): Date {
    return new Date(
      Math.min(...this.dates().map((x: Date) => x.getTime()), 8640000000000000),
    );
  }

  latest(): Date {
    return new Date(
      Math.max(
        ...this.dates().map((x: Date) => x.getTime()),
        -8640000000000000,
      ),
    );
  }

  fromJSON(data: Partial<ISlot>): Slot {
    if (data.type) {
      this.type = data.type;
    }
    if (data.name) {
      this.name = data.name;
    }
    if (data.align) {
      this.align = data.align;
    }
    if (data.start) {
      this.start = this.parseDate(data.start);
    }
    if (data.end) {
      this.end = this.parseDate(data.end);
    }
    if (data.startEp) {
      this.startEp = data.startEp;
    }
    if (data.durationMonths) {
      this.durationMonths = data.durationMonths;
    }
    if (data.originalName) {
      this.originalName = data.originalName;
    }
    if (data.epPerMonth) {
      this.epPerMonth = data.epPerMonth;
    }
    if (data.timeline) {
      this.timeline = data.timeline;
    }
    if (data.bruttoeinkuenfte) {
      this.bruttoeinkuenfte = data.bruttoeinkuenfte;
    }
    if (data.nettoeinkuenfte) {
      this.nettoeinkuenfte = data.nettoeinkuenfte;
    }
    if (data.startBruttoeinkuenfte) {
      this.startBruttoeinkuenfte = data.startBruttoeinkuenfte;
    }
    if (data.endBruttoeinkuenfte) {
      this.endBruttoeinkuenfte = data.endBruttoeinkuenfte;
    }
    if (data.startNettoeinkuenfte) {
      this.startNettoeinkuenfte = data.startNettoeinkuenfte;
    }
    if (data.endNettoeinkuenfte) {
      this.endNettoeinkuenfte = data.endNettoeinkuenfte;
    }
    if (data.zuschläge) {
      this.zuschläge = data.zuschläge;
    }
    if (data.abschläge) {
      this.abschläge = data.abschläge;
    }
    if (data.startage) {
      this.startage = data.startage;
    }
    if (data.wz35Start !== undefined) {
      this.wz35Start = data.wz35Start;
    }
    if (data.wz45Start !== undefined) {
      this.wz45Start = data.wz45Start;
    }
    return this;
  }
  toJSON(): any {
    let data = {} as Partial<ISlot>;
    if (this.name) {
      data['name'] = this.name;
    }
    if (this.start) {
      data['start'] = this.start;
    }
    if (this.align) {
      data['align'] = this.align;
    }
    if (this.end) {
      data['end'] = this.end;
    }
    if (this.startEp) {
      data['startEp'] = this.startEp;
    }
    if (this.durationMonths) {
      data['durationMonths'] = this.durationMonths;
    }
    if (this.originalName) {
      data['originalName'] = this.originalName;
    }
    if (this.epPerMonth) {
      data['epPerMonth'] = this.epPerMonth;
    }
    if (this.bruttoeinkuenfte) {
      data['bruttoeinkuenfte'] = this.bruttoeinkuenfte;
    }
    if (this.nettoeinkuenfte) {
      data['nettoeinkuenfte'] = this.nettoeinkuenfte;
    }
    if (this.startage) {
      data['startage'] = this.startage;
    }

    if (this.startBruttoeinkuenfte) {
      data['startBruttoeinkuenfte'] = this.startBruttoeinkuenfte;
    }

    if (this.endBruttoeinkuenfte) {
      data['endBruttoeinkuenfte'] = this.endBruttoeinkuenfte;
    }

    if (this.startNettoeinkuenfte) {
      data['startNettoeinkuenfte'] = this.startNettoeinkuenfte;
    }

    if (this.endNettoeinkuenfte) {
      data['endNettoeinkuenfte'] = this.endNettoeinkuenfte;
    }
    if (this.wz35Start !== undefined) {
      data['wz35Start'] = this.wz35Start;
    }
    if (this.wz45Start !== undefined) {
      data['wz45Start'] = this.wz45Start;
    }
    return data;
  }
  parseDate(date: any): Date {
    if (date instanceof Date) {
      return date;
    }
    if (date instanceof String || typeof date == 'string') {
      let d = new Date(date.split('.').reverse().join('-') + ' 00:00:01');
      if (d instanceof Date && !isNaN(d.valueOf())) {
        return d;
      }
      d = new Date(date.split('.').reverse().join('-'));
      if (date instanceof Date && !isNaN(d.valueOf())) {
        return d;
      }
    }
    if (moment.isMoment(date)) {
      return date.toDate();
    }
    throw 'Cannot parse date of ' + date;
  }
}
