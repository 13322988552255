import { Injectable } from '@angular/core';
import { TimelineRequest, ITimelineRequest } from '@classes/timeline-request';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TimelineCreatorService {
  timelines: Array<ITimelineRequest> = new Array<ITimelineRequest>();

  constructor() {
    this.load();
  }
  load() {
    this.loadFromJson(localStorage.getItem('tsc.service.data') ?? "[]")
    this.changed.next(true);

  }
  loadFromJson(json: string) {
    let savedTimelines = <ITimelineRequest[]>JSON.parse(json);
    for (let tl of savedTimelines) {
      if (this.getTimelineByIdentifier(tl.identifier!) === undefined) {
        console.log("Loading: ");
        console.log(tl);
        this.timelines.push((new TimelineRequest(tl)));
      }
    }
    this.save();
  }
  save() {
    console.log({ msg: "saving", tls: this.timelines });
    localStorage.setItem('tsc.service.data', JSON.stringify(this.timelines));
  }
  changed: Subject<boolean> = new Subject<boolean>();

  addTimeline(tlr: ITimelineRequest) {
    console.log("adding dyn tl:");
    console.log(tlr);
    this.timelines.push(tlr);
    this.changed.next(true);
    this.save();
  }

  getTimelines(): TimelineRequest[] {
    return this.timelines as TimelineRequest[];
  }

  getTimelineByIdentifier(identifier: string): ITimelineRequest | undefined {
    for (let tl of this.timelines) {
      if (tl.identifier == identifier) {
        return tl;
      }
    }
    return undefined;
  }

  deleteTimelineByIdentifier(identifier: string) {
    let tl = this.getTimelineByIdentifier(identifier);
    if (tl !== undefined) {
      let idx = this.timelines.indexOf(tl);
      if (idx >= 0) {
        this.timelines.splice(idx, 1);
      }
    }
    this.changed.next(true);
    this.save();
  }
}
