import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import {
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { CommonModule } from '@angular/common';

import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';

PlotlyModule.plotlyjs = PlotlyJS;

import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormularComponent } from './components/formular/formular.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatCheckboxModule,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from '@angular/material/checkbox';

import {
  MatDateFormats,
  DateAdapter,
  MatNativeDateModule,
  NativeDateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { DatumComponent } from './components/formular/datum/datum.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { VorruhestandDisplayComponent } from './components/vorruhestand-display/vorruhestand-display.component';
import { ComcolWernerComponent } from './components/comcol-werner/comcol-werner.component';
import { ScatterComponent } from './components/scatter/scatter.component';
import { PlotlyScatterComponent } from './components/plotly-scatter/plotly-scatter.component';
import { JahrgangsUebersichtComponent } from './components/jahrgangs-uebersicht/jahrgangs-uebersicht.component';
import { AbfindungComponent } from './components/abfindung/abfindung.component';
import { FunctoidComponent } from './components/functoid/functoid.component';
import { VerlusteComponent } from './components/verluste/verluste.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { TimelineCreationComponent } from './components/timeline-creation/timeline-creation.component';
import { PdfControlsComponent } from './components/pdf-controls/pdf-controls.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { UserformComponent } from './components/userform/userform.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class MyDateAdapter extends NativeDateAdapter {
  override parse(value: any, parseFormat: any) {
    console.log('Parsing date ');
    console.log(value);
    console.log(parseFormat);

    if (typeof value == 'number') {
      let date = new Date(value);
    }
    if (typeof value == 'string') {
      return new Date(value.split('.').reverse().join('-'));
    }
    let retVal = value ? new Date(Date.parse(value)) : null;

    console.log('retVal: ' + retVal);
    return retVal;
  }
  override format(date: Date, displayFormat: Object): string {
    if (displayFormat == 'input') {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return this._to2digit(day) + '.' + this._to2digit(month) + '.' + year;
    } else {
      return date.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    FormularComponent,
    DatumComponent,
    VorruhestandDisplayComponent,
    ComcolWernerComponent,
    ScatterComponent,
    PlotlyScatterComponent,
    JahrgangsUebersichtComponent,
    AbfindungComponent,
    FunctoidComponent,
    VerlusteComponent,
    TimelineCreationComponent,
    PdfControlsComponent,
    UserEditComponent,
    UserformComponent,
    SidePanelComponent,
  ],
  imports: [
    NgxSpinnerModule,
    CommonModule,
    PlotlyModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatStepperModule,
    MatButtonModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatDividerModule,
    MatIconModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatSelectModule,
    MatChipsModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: MyDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        clickAction: 'check-indeterminate',
      } as MatCheckboxDefaultOptions,
    },
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
