import { TimelineBatch } from './timeline-batch';
import { ITimeline, Timeline } from './timeline';
import { Slot } from './slot';
import { Subject, throwIfEmpty } from 'rxjs';
import { validateBasis } from '@angular/flex-layout';
export interface ISozialversicherung {
  krankenkasse: string;
  kinder_unter_23: number;
  zusatzbeitrag_kv: number;
  zusatzbeitrag_pv: number;
}

export interface IUserContextJSON {
  kv?: string;
  vorname?: string;
  nachname?: string;
  jahresbruttoentgelt_fuer_phase_x?: number;
  jahresnettoentgelt_fuer_phase_x?: number;
  standort?: string;
  identifier: string;
  date_of_birth: string;
  gdb: number;
  wartezeit_5_fehlend: number;
  wartezeit_35_fehlend: number;
  wartezeit_45_fehlend: number;
  ep: number;
  ep_bis: string;
  wz_bis: string;
  jahresentgelt: number;
  jahresnettoentgelt: number;
  jahresnettoentgelt_alg: number;
  steuerklasse: number;
  betriebszugehoerigkeit: number;
  sozialversicherung: ISozialversicherung;
  timelines?: Array<Timeline>;
  jahresnettoentgelt_fuer_alg_nach_phase_x?: number;
  meta?: any;
}
export interface IUserContext {
  kv?: string;
  vorname?: string;
  meta?: any;
  nachname?: string;
  jahresbruttoentgelt_fuer_phase_x?: number;
  jahresnettoentgelt_fuer_phase_x?: number;
  standort?: string;
  identifier: string;
  date_of_birth: Date;
  gdb: number;
  wartezeit_5_fehlend: number;
  wartezeit_35_fehlend: number;
  wartezeit_45_fehlend: number;
  ep: number;
  ep_bis: Date;
  wz_bis: Date;
  jahresentgelt: number;
  jahresnettoentgelt: number;
  jahresnettoentgelt_alg: number;
  steuerklasse: number;
  betriebszugehoerigkeit: number;
  sozialversicherung: ISozialversicherung;
  timelines?: Array<Timeline>;
}

export class UserContext implements IUserContext {
  kv?: string;
  vorname?: string;
  nachname?: string;
  jahresbruttoentgelt_fuer_phase_x?: number;
  jahresnettoentgelt_fuer_phase_x?: number;
  standort?: string;
  batch: TimelineBatch;
  identifier: string;
  meta?: any;
  date_of_birth: Date;
  gdb: number;
  wartezeit_5_fehlend: number;
  wartezeit_35_fehlend: number;
  wartezeit_45_fehlend: number;
  ep: number;
  ep_bis: Date;
  wz_bis: Date;
  jahresentgelt: number;
  jahresnettoentgelt: number;
  jahresnettoentgelt_alg: number;
  steuerklasse: number;
  sv_valid: boolean = true;
  jahresnettoentgelt_fuer_alg_nach_phase_x?: number;
  changed$: Subject<boolean> = new Subject<boolean>();
  public metadata: Map<string, any> = new Map<string, any>();

  get betriebszugehoerigkeit(): number {
    return (
      this._betriebszugehoerigkeit +
      this.additionalBetriebszugehoerigkeitsMonths
    );
  }
  set betriebszugehoerigkeit(bz: number) {
    this._betriebszugehoerigkeit = bz;
  }
  _betriebszugehoerigkeit: number;
  sozialversicherung: ISozialversicherung;

  timelines: Array<Timeline> = new Array<Timeline>();
  additionalBetriebszugehoerigkeitsMonths: number = 0;
  setAdditionalBzMonths(diff: number): void {
    this.additionalBetriebszugehoerigkeitsMonths = diff;
  }
  constructor(data: IUserContextJSON) {
    this.fromJSON(data);
  }

  getBatch(): TimelineBatch | undefined {
    return this.batch;
  }

  setBatch(batch: TimelineBatch) {
    this.batch = batch;
  }
  getCondensedNames(): Array<string> {
    return this.timelines.map((tl: Timeline) => tl.getCondensedName());
  }
  parseTimelinesResponse(timelines: any) {
    let names = Object.keys(timelines);
    this.timelines.length = 0;
    for (let name of names) {
      let timeline = timelines[name];
      this.timelines.push(
        new Timeline().fromJSON({ ...timeline, name: name, user: this }),
      );
    }
    this.triggerChangeEmitter();
  }
  _changeEmitterTimeout: any = undefined;
  triggerChangeEmitter() {
    if (this._changeEmitterTimeout !== undefined) {
      clearTimeout(this._changeEmitterTimeout);
      this._changeEmitterTimeout = undefined;
    }
    this._changeEmitterTimeout = setTimeout(() => {
      this.changed$.next(true);
    });
  }

  /**
   * The best pension available without gaps in the timeline, in order:
   * - Regelaltersrente
   * - Ungeminderte Rente
   * - Geminderte Rente
   */
  getBestPensionFromTimeline(timelineIdentifier: string): Timeline | undefined {
    let renten = ['Regelaltersrente', 'Ungeminderte Rente', 'Geminderte Rente'];

    let identifier = this.batch.getTimelineIdentifier(timelineIdentifier);
    let tls: Array<Timeline> = this.timelines
      .filter((timeline: Timeline) => {
        return (
          timeline.identifier == identifier &&
          !timeline.hasSlots(['Lücke', 'Joker'])
        );
      })
      .filter((item) => item != undefined);

    if (tls.length === undefined) {
      return undefined;
    }

    let unreduced: Timeline | undefined;
    let reduced: Timeline | undefined;

    for (let tl of tls) {
      if (tl !== undefined) {
        if (tl.is('Regelaltersrente')) {
          return tl;
        } else if (tl.is('Geminderte Rente')) {
          reduced = tl;
        } else if (tl.is('Ungeminderte Rente')) {
          unreduced = tl;
        }
      }
    }
    if (unreduced !== undefined) {
      return unreduced;
    }
    if (reduced !== undefined) {
      return reduced;
    }
    return undefined;
  }

  /**
   * The best pension available without gaps in the timeline, in order:
   * - Regelaltersrente
   * - Ungeminderte Rente
   * - Geminderte Rente
   */
  getBestPensionFromTimelineWithGap(
    timelineIdentifier: string,
  ): Timeline | undefined {
    let renten = ['Regelaltersrente', 'Ungeminderte Rente', 'Geminderte Rente'];
    let identifier = this.batch.getTimelineIdentifier(timelineIdentifier);
    let tls: Array<Timeline> = this.timelines
      .filter((timeline: Timeline) => {
        return (
          timeline.identifier == identifier &&
          !timeline.hasSlots(['Lücke', 'Joker'])
        );
      })
      .filter((item) => item != undefined);

    if (tls.length === undefined) {
      return undefined;
    }

    let unreduced: Timeline | undefined;
    let reduced: Timeline | undefined;

    for (let tl of tls) {
      if (tl !== undefined) {
        if (tl.regular_pension) {
          return tl;
        } else if (tl.reduced === true) {
          reduced = tl;
        } else if (tl.reduced === false) {
          unreduced = tl;
        }
      }
    }
    if (unreduced !== undefined) {
      return unreduced;
    }
    if (reduced !== undefined) {
      return reduced;
    }
    tls = this.timelines
      .filter((timeline: Timeline) => {
        return timeline.identifier == identifier;
      })
      .filter((item) => item != undefined);
    /* 
      TODO: sortiere nach lückendauer, nimm den mit der kürzesten lücke.
      Wenn gleiche Lücken: Siehe obere Priorisierung
    */

    return undefined;
  }
  getSlotsByType(type: string): Array<Slot> {
    return this.getSlotsByFilterFunction((x: Slot) => {
      return x.name == type;
    });
  }

  getSlotsByFilterFunction(fn: CallableFunction): Array<Slot> {
    let slots = new Array<Slot>();
    for (let tl of this.timelines) {
      for (let slot of tl.slots.filter((x) => {
        return fn(x);
      })) {
        slots.push(slot);
      }
    }
    return slots;
  }

  earliest(): Date {
    return new Date(
      Math.min(...this.timelines.map((x) => x.earliest().getTime())),
    );
  }

  latest(): Date {
    return new Date(
      Math.max(...this.timelines.map((x) => x.latest().getTime())),
    );
  }
  getTimelinesByPartialTemplate(tl: Partial<ITimeline>): Array<Timeline> {
    let timelines = new Array<Timeline>();
    for (let timeline of this.timelines) {
      let match = true;
      for (let key of Object.keys(tl)) {
        if (
          !Object.keys(timeline).includes(key) ||
          (tl as any)[key] != (timeline as any)[key]
        ) {
          match = false;
        }
      }
      if (match) {
        timelines.push(timeline);
      }
    }
    return timelines;
  }

  getTimelineByCondensedName(
    name: string,
    includeIndex?: boolean,
  ): Timeline | undefined {
    for (let tl of this.timelines) {
      if (tl.getCondensedName(includeIndex) == name) {
        console.log(tl.getCondensedName(includeIndex));
        return tl;
      }
    }
    return undefined;
  }

  /**
   *
   * Gets a list of all available sourceIndexes
   *
   */
  getTimelineBucketList(): Array<number> {
    let arr: Array<number> = new Array<number>();
    for (let tl of this.timelines) {
      if (tl.sourceIndex !== undefined) {
        if (!arr.includes(tl.sourceIndex)) {
          arr.push(tl.sourceIndex);
        }
      }
    }
    return arr;
  }
  /**
   * Gets the timeline by the bucket-id (sourceIndex)
   */
  getTimelineBucket(id: number) {
    return this.timelines.filter((x: Timeline) => {
      return x.sourceIndex == id;
    });
  }
  svBirthDate: string = '';
  parseIdentifier(_str: string): string {
    let str = _str;
    if (str.length != 12) {
      let str: string = _str.replace(/\s/g, '');
      if (str.length != 12) {
        return _str;
      }
    }
    this.svBirthDate = str.substring(2, 8);

    return (
      str.substring(0, 2) +
      ' ' +
      str.substring(2, 8) +
      ' ' +
      str.substring(8, 9) +
      ' ' +
      str.substring(9, 11) +
      ' ' +
      str.substring(11, 12)
    );
  }
  twoDigits(num: number): string {
    let str = num + '';
    if (str.length == 1) {
      return '0' + str;
    }
    return str;
  }
  checkSv() {
    this.checkSvDate();
    this.checkSvChecksum();
  }
  crossSum(num: number): number {
    return Array.from(num + '')
      .map((x: string) => parseInt(x))
      .reduce((p, c) => p + c, 0);
  }
  checkSvChecksum() {
    // factors
    let factors = Array.from('212571212121').map((x: string) => parseInt(x)); // create integer-array from string
    let sv = this.identifier.replace(/\s/g, ''); // remove whitespace-chars from sv-number
    sv = sv.replace(
      /[A-z]/g,
      this.twoDigits(sv.toUpperCase().charCodeAt(8) - 64),
    ); // replace 8'th char (a letter) with its 1-indexed 2-digit-representation of position in the alphabet (i.E. A=01, B=02)
    let svArr = Array.from(sv).map((x: string) => parseInt(x)); // create an integer array
    let checkSum = svArr.pop(); // remove checksum, save it for later

    let sum = svArr.reduce(
      (prev, cur, idx, arr) => prev + this.crossSum(cur * factors[idx]),
      0,
    ); // add up cross-sums of products of digits and factors

    if (sum % 10 == checkSum) {
      //console.log("checksum passed for "+this.identifier);
      this.sv_valid = true;
      return true;
    } else {
      //alert("SV-Checksum-Error for "+this.identifier+", expected "+checkSum+" got "+(sum % 10));
      this.sv_valid = false;
      return false;
    }
  }
  checkSvDate() {
    let comparison =
      this.twoDigits(this.date_of_birth.getDate()) +
      '' +
      this.twoDigits(this.date_of_birth.getMonth() + 1);
    let year = this.date_of_birth.getFullYear() + '';
    comparison += year.substring(2);
    if (comparison != this.svBirthDate) {
      //alert("SV-Number seems odd, comparison: "+comparison +"!="+this.svBirthDate);
    }
  }
  fromJSON(data: IUserContextJSON) {
    if (data.vorname) {
      this.vorname = data.vorname;
    }
    if (data.nachname) {
      this.nachname = data.nachname;
    }
    if (data.standort) {
      this.standort = data.standort;
    }
    if (data.jahresnettoentgelt_fuer_phase_x) {
      this.jahresnettoentgelt_fuer_phase_x =
        data.jahresnettoentgelt_fuer_phase_x;
    }
    if (data.jahresbruttoentgelt_fuer_phase_x) {
      this.jahresbruttoentgelt_fuer_phase_x =
        data.jahresbruttoentgelt_fuer_phase_x;
    }
    if (data.jahresnettoentgelt_fuer_alg_nach_phase_x) {
      this.jahresnettoentgelt_fuer_alg_nach_phase_x =
        data.jahresnettoentgelt_fuer_alg_nach_phase_x;
    }
    this.identifier = data.identifier; //this.parseIdentifier(data.identifier);
    this.date_of_birth = this.parseDate(data.date_of_birth);
    this.gdb = data.gdb;
    this.kv = data.kv;
    this.wartezeit_5_fehlend = data.wartezeit_5_fehlend;
    this.wartezeit_35_fehlend = data.wartezeit_35_fehlend;
    this.wartezeit_45_fehlend = data.wartezeit_45_fehlend;
    this.ep = data.ep;
    this.ep_bis = this.parseDate(data.ep_bis);
    this.wz_bis = this.parseDate(data.wz_bis);
    this.jahresentgelt = data.jahresentgelt;
    this.jahresnettoentgelt = data.jahresnettoentgelt;
    this.jahresnettoentgelt_alg = data.jahresnettoentgelt_alg;
    this.jahresnettoentgelt_fuer_phase_x = data.jahresnettoentgelt_fuer_phase_x;
    this.jahresbruttoentgelt_fuer_phase_x =
      data.jahresbruttoentgelt_fuer_phase_x;
    this.steuerklasse = data.steuerklasse;
    this.betriebszugehoerigkeit = data.betriebszugehoerigkeit;
    this.sozialversicherung = {
      krankenkasse: data.sozialversicherung.krankenkasse,
      kinder_unter_23: data.sozialversicherung.kinder_unter_23,
      zusatzbeitrag_kv: data.sozialversicherung.zusatzbeitrag_kv,
      zusatzbeitrag_pv: data.sozialversicherung.zusatzbeitrag_pv,
    };
  }

  _overlay: Partial<IUserContextJSON> | undefined;

  set overlay(o: Partial<IUserContextJSON> | undefined) {
    this._overlay = o;
  }

  get overlay(): Partial<IUserContextJSON> | undefined {
    return this._overlay;
  }

  //overlay:Partial<IUserContextJSON>|undefined;

  svOverlay: Partial<ISozialversicherung> | undefined;

  setOverlay(overlay?: Partial<IUserContextJSON>) {
    if (overlay === undefined) {
      this.overlay = undefined;
      return;
    }
    this.overlay = overlay;
  }

  get austrittstermin_1(): any {
    return this.metadata.get('austrittstermin_1');
  }

  setSozialversicherungOverlay(overlay?: Partial<ISozialversicherung>) {
    this.svOverlay = overlay;
  }
  setSozialversicherung(sv: ISozialversicherung) {
    this.sozialversicherung = sv;
  }
  toJSON(): IUserContextJSON {
    let md: any = {}
    this.metadata.forEach((value, key) => { md[key] = value; });
    let json: IUserContextJSON = {
      identifier: this.identifier,
      kv: this.kv,
      meta: md,
      date_of_birth: this.serializeDate(this.date_of_birth),
      gdb: this.gdb,
      wartezeit_5_fehlend: this.wartezeit_5_fehlend,
      wartezeit_35_fehlend: this.wartezeit_35_fehlend,
      wartezeit_45_fehlend: this.wartezeit_45_fehlend,
      ep: this.ep,
      ep_bis: this.serializeDate(this.ep_bis),
      wz_bis: this.serializeDate(this.wz_bis),
      jahresentgelt: this.jahresentgelt,
      jahresnettoentgelt: this.jahresnettoentgelt,
      jahresnettoentgelt_alg: this.jahresnettoentgelt_alg,
      steuerklasse: this.steuerklasse,
      betriebszugehoerigkeit: this.betriebszugehoerigkeit,
      sozialversicherung: {
        krankenkasse: this.kv ?? this.sozialversicherung.krankenkasse,
        kinder_unter_23: this.sozialversicherung.kinder_unter_23,
        zusatzbeitrag_kv: this.sozialversicherung.zusatzbeitrag_kv,
        zusatzbeitrag_pv: this.sozialversicherung.zusatzbeitrag_pv,
      },
    };
    if (this.vorname) {
      json.vorname = this.vorname;
    }
    if (this.nachname) {
      json.nachname = this.nachname;
    }
    if (this.standort) {
      json.standort = this.standort;
    }
    if (this.jahresnettoentgelt_fuer_phase_x) {
      json.jahresnettoentgelt_fuer_phase_x =
        this.jahresnettoentgelt_fuer_phase_x;
    }
    if (this.jahresbruttoentgelt_fuer_phase_x) {
      json.jahresbruttoentgelt_fuer_phase_x =
        this.jahresbruttoentgelt_fuer_phase_x;
    }
    if (this.setSozialversicherungOverlay !== undefined) {
      json.sozialversicherung = {
        ...json.sozialversicherung,
        ...this.svOverlay,
      };
    }
    if (this.overlay) {
      return {
        ...json,
        ...this.overlay,
      };
    }

    return json;
  }
  earliest_ep_wz(): Date {
    if (this.wz_bis.getTime() < this.ep_bis.getTime()) {
      return this.wz_bis;
    }
    return this.ep_bis;
  }
  serializeDate(date: Date): string {
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  parseDate(date: any): Date {
    if (date instanceof Date) {
      return date;
    }
    return new Date(date.split('.').reverse().join('-') + ' 00:00:01');
  }
}
