import * as moment from 'moment';
export interface ISlotRequest {
  start?: Date | string;
  end?: Date | string;
  dauer?: number | string;
  align?: string;
  maximal_dauer?: number;
  max_alter_ende?: number;
  latest_end_pension?: string;
  type: string;

  nettoFaktor?: number;
  bruttoFaktor?: number;
  keep_conditions?: string;
  paddingPriority?: number;
  durch_geschaeftsaufgabe?: boolean;
  minijob?: boolean;
  alt_name?: string;
}
export class SlotRequest {
  type: string;
  start?: Date | string;
  end?: Date | string;
  dauer?: number | string;
  align?: string;
  maximal_dauer?: number;
  nettoFaktor?: number;
  bruttoFaktor?: number;
  max_alter_ende?: number;
  latest_end_pension?: string;
  keep_conditions?: string;
  paddingPriority?: number;
  minijob?: boolean;
  durch_geschaeftsaufgabe?: boolean;
  alt_name?: string;
  fromJSON(data: Partial<ISlotRequest>): SlotRequest {
    if (data.type) {
      this.type = data.type;
    }
    if (data.start) {
      this.start = this.parseDate(data.start);
    }
    if (data.end) {
      this.end = this.parseDate(data.end);
    }
    if (data.align) {
      this.align = data.align;
    }
    if (data.alt_name) {
      this.alt_name = data.alt_name;
    }
    if (data.dauer) {
      this.dauer = data.dauer;
    }
    if (data.maximal_dauer) {
      this.maximal_dauer = data.maximal_dauer;
    }
    if (data.max_alter_ende) {
      this.max_alter_ende = data.max_alter_ende;
    }
    if (data.latest_end_pension) {
      this.latest_end_pension = data.latest_end_pension;
    }
    if (data.bruttoFaktor !== undefined) {
      this.bruttoFaktor = data.bruttoFaktor;
    }
    if (data.nettoFaktor !== undefined) {
      this.nettoFaktor = data.nettoFaktor;
    }
    if (data.keep_conditions) {
      this.keep_conditions = data.keep_conditions;
    }
    if (data.paddingPriority) {
      this.paddingPriority = data.paddingPriority;
    }
    if (data.minijob !== undefined) {
      this.minijob = data.minijob;
    }
    if (data.durch_geschaeftsaufgabe !== undefined) {
      this.durch_geschaeftsaufgabe = data.durch_geschaeftsaufgabe;
    }
    return this;
  }

  parseDate(date: any): Date | string {
    if (date instanceof Date) {
      return date;
    }
    if (date instanceof String || typeof date == 'string') {
      let d = new Date(date.split('.').reverse().join('-') + ' 00:00:01');
      if (d instanceof Date && !isNaN(d.valueOf())) {
        return d;
      }
      d = new Date(date.split('.').reverse().join('-'));
      if (d instanceof Date && !isNaN(d.valueOf())) {
        return d;
      }
    }
    if (date['toDate'] != undefined) {
      return date.toDate();
    }
    return date;
  }

  simplyfyDate(d: any): any {
    if (d instanceof Date) {
      return d.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    }
    return d;
  }

  toJSON(): Partial<ISlotRequest> {
    let data = {} as Partial<ISlotRequest>;
    data.type = this.type;
    if (this.start) {
      data.start = this.simplyfyDate(this.start);
    }
    if (this.end) {
      data.end = this.simplyfyDate(this.end);
    }
    if (this.dauer) {
      data.dauer = this.dauer;
    }
    if (this.maximal_dauer) {
      data.maximal_dauer = this.maximal_dauer;
    }
    if (!['', undefined, null].includes(this.alt_name)) {
      data.alt_name = this.alt_name;
    }
    if (this.max_alter_ende) {
      data.max_alter_ende = this.max_alter_ende;
    }
    if (this.align) {
      data.align = this.align;
    }
    if (this.latest_end_pension) {
      data.latest_end_pension = this.latest_end_pension;
    }
    if (this.keep_conditions) {
      data.keep_conditions = this.keep_conditions;
    }
    if (this.paddingPriority) {
      data.paddingPriority = this.paddingPriority;
    }
    if (this.bruttoFaktor !== undefined) {
      data.bruttoFaktor = this.bruttoFaktor;
    }
    if (this.nettoFaktor !== undefined) {
      data.nettoFaktor = this.nettoFaktor;
    }
    if (this.paddingPriority) {
      data.paddingPriority = this.paddingPriority;
    }
    if (this.durch_geschaeftsaufgabe === true) {
      data.durch_geschaeftsaufgabe = true;
    }
    if (this.minijob === true) {
      data.minijob = true;
    }
    return data;
  }
}
