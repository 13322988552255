import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TimelineBatch } from '@classes/timeline-batch';
import { Timeline } from '@classes/timeline';
import { Slot } from '@classes/slot';
import { UserContext } from '@classes/user-context';
import { Moment } from 'moment/moment';
import { FunctionCompiler } from '@classes/function-compiler';
import { Functoid } from '@classes/functoid';
@Component({
  selector: 'app-abfindung',
  templateUrl: './abfindung.component.html',
  styleUrls: ['./abfindung.component.scss'],
})
export class AbfindungComponent implements OnInit {
  maximalBetrag = new UntypedFormControl(99999999);
  rbDeckel = new UntypedFormControl(false);
  rentenDeckelTyp = new UntypedFormControl('dynamisch');
  @Input('timelineBatch') timelineBatch: TimelineBatch;
  @Input('betriebsaufloesung') betriebsaufloesung: Date;
  @Input('vergleichsweg') vergleichsweg: string;
  @Input('consider_regular_pension_unreduced')
  consider_regular_pension_unreduced: boolean = false;

  revision: number = 0;
  renten: Array<string> = [
    'Regelaltersrente',
    'Geminderte Rente',
    'Ungeminderte Rente',
  ];
  years: Array<number> = new Array<number>();
  timelineIdentifier: string = '';
  functionCompiler = new FunctionCompiler();
  missingRentenDeckel = 0;
  get rentenDeckelTypen(): Array<string> {
    return ['dynamisch', ...this.renten];
  }
  constructor() {}

  prepare() {
    this.timelineIdentifier = this.getTimelineIdentifier();

    this.years = this.getYears().sort();
    this.functionCompiler.setTimelineBatch(this.timelineBatch);
    this.functionCompiler.setVergleichsweg(this.vergleichsweg);
    this.functionCompiler.reinit();
    this.maximalBetrag.setValue(this.functionCompiler.maximalBetrag);
    this.rentenDeckelTyp.setValue(this.functionCompiler.rentenDeckel);
    this.rbDeckel.setValue(this.functionCompiler.rbDeckel);
  }

  getFunctoids(): Array<Functoid> {
    return this.functionCompiler.functoids;
  }
  prepared(): boolean {
    if ([null, undefined, '', 'Keine'].includes(this.timelineIdentifier)) {
      return false;
    }
    if (this.years.length == 0) {
      return false;
    }
    return true;
  }

  gesamtausgaben: number = 0;

  charts: any = {};
  getOverallFunctionString(): string {
    return this.functionCompiler.getFunctionString();
  }
  redraw() {
    if (!this.prepared()) {
      this.prepare();
    }
    if (this.prepared()) {
      this.getChartData();
      this.revision++;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['timelineBatch']) {
      this.functionCompiler.setTimelineBatch(this.timelineBatch);
      this.redraw();
    }
    if (
      changes['vergleichsweg'] ||
      changes['consider_regular_pension_unreduced']
    ) {
      //this.functionCompiler.setVergleichsweg(this.vergleichsweg);
      this.getFunctionCompiler();
      this.redraw();
    }
  }

  getChartData(): any {
    this.charts = {
      perYearBoxPlot: {
        data: [],
      },
      perYear: {
        data: [
          {
            x: [],
            y: [],
            type: 'bar',
          },
        ],
      },
      perYearPerPerson: {
        data: [
          {
            x: [],
            y: [],
            type: 'bar',
          },
        ],
      },
      perIncomeGroup: {
        data: [
          {
            x: [],
            y: [],
            type: 'bar',
          },
        ],
      },
      perIncomeGroupPerPerson: {
        data: [
          {
            x: [],
            y: [],
            type: 'bar',
          },
        ],
      },
      perUser: {
        data: [
          {
            x: [],
            y: [],
            type: 'bar',
          },
        ],
        layout: {
          xaxis: {
            showgrid: false,
            showline: false,
            type: 'category',
            //rangemode:'nonnegative',
            nticks: 25,
            title: 'Personen-ID',
          },
        },
      },
    };
    this.gesamtausgaben = 0;
    let perYear: any[] = [];
    this.missingRentenDeckel = 0;
    let incomeGroups = [
      {
        min: 0,
        max: 40000,
        abf: 0,
        users: 0,
      },
      {
        min: 40000,
        max: 50000,
        abf: 0,
        users: 0,
      },
      {
        min: 50000,
        max: 60000,
        abf: 0,
        users: 0,
      },
      {
        min: 60000,
        max: 70000,
        abf: 0,
        users: 0,
      },
      {
        min: 70000,
        max: 80000,
        abf: 0,
        users: 0,
      },
      {
        min: 80000,
        max: 90000,
        abf: 0,
        users: 0,
      },
      {
        min: 90000,
        max: 100000,
        abf: 0,
        users: 0,
      },
      {
        min: 100000,
        max: 99999999,
        abf: 0,
        users: 0,
      },
    ];
    let incomes = incomeGroups.map((x) => 0);

    for (let year of this.years) {
      let allUsers = this.getUsersBornIn(year);
      let values = this.functionCompiler.calculateForUsersArray(allUsers);
      this.missingRentenDeckel += this.functionCompiler.rentenDeckelUnavailable;

      for (let user of values) {
        this.gesamtausgaben += user.value;
        this.charts.perUser.data[0].y.push(user.value);
        this.charts.perUser.data[0].x.push(user.user.identifier);
        let income = user.user.jahresnettoentgelt;
        let groups = incomeGroups.filter(
          (x) => x.min <= income && x.max > income,
        );
        if (groups.length == 1) {
          groups[0].abf += user.value;
          groups[0].users++;
        }
      }

      this.charts.perYearBoxPlot.data.push({
        y: values.map((x) => x.value),
        type: 'box',
        whiskerwidth: 0.2,
        boxpoints: 'all',
        jitter: 0.2,
        name: 'Jahrgang ' + year,
      });

      this.charts.perYearPerPerson.data[0].y.push(
        values.reduce((p, c, i, a) => {
          return p + c.value;
        }, 0) / values.length,
      );
      this.charts.perYearPerPerson.data[0].x.push('Jahrgang ' + year);

      this.charts.perYear.data[0].y.push(
        values.reduce((p, c, i, a) => {
          return p + c.value;
        }, 0),
      );
      this.charts.perYear.data[0].x.push('Jahrgang ' + year);

      this.charts.perIncomeGroup.data[0].y = incomeGroups.map((x) => x.abf);
      this.charts.perIncomeGroup.data[0].x = incomeGroups.map(
        (x) =>
          x.min.toLocaleString('de-DE') +
          ' - ' +
          x.max.toLocaleString('de-DE') +
          ' €',
      );

      this.charts.perIncomeGroupPerPerson.data[0].y = incomeGroups.map((x) =>
        x.users > 0 ? x.abf / x.users : 0,
      );
      this.charts.perIncomeGroupPerPerson.data[0].x = incomeGroups.map(
        (x) =>
          x.min.toLocaleString('de-DE') +
          ' - ' +
          x.max.toLocaleString('de-DE') +
          ' €',
      );
    }
  }

  listAvailableSlots() {
    let slots = new Array<string>();
    this.timelineBatch.UserContexts().map((user: UserContext) => {
      return user.getTimelineByCondensedName(this.vergleichsweg);
    });
  }
  getTimelineIdentifier(): string {
    for (let user of this.timelineBatch.UserContexts()) {
      for (let timeline of user.timelines) {
        if (timeline.getCondensedName() == this.vergleichsweg) {
          if (timeline.identifier !== undefined) {
            return timeline.identifier;
          }
        }
      }
    }
    return '';
  }
  getUsersBornIn(birthYear: number): Array<UserContext> {
    return this.timelineBatch
      .UserContexts()
      .filter(
        (user: UserContext) => user.date_of_birth.getFullYear() == birthYear,
      );
  }
  getPeopleThatCan(rente: string, birthYear: number): Array<UserContext> {
    let condensedName = this.timelineIdentifier + '->' + rente;
    let users = this.getUsersBornIn(birthYear);
    let tlFilter = (timeline: Timeline) => {
      if (timeline.hasSlots(['Lücke', 'Joker'])) {
        return false;
      }
      return timeline.isTimeline(condensedName);
    };
    if (
      condensedName.includes('Ungeminderte Rente') &&
      this.consider_regular_pension_unreduced
    ) {
      tlFilter = (timeline: Timeline) => {
        if (timeline.hasSlots(['Lücke', 'Joker'])) {
          return false;
        }
        return (
          timeline.isTimeline(condensedName) ||
          timeline.isTimeline(this.timelineIdentifier + '->Regelaltersrente')
        );
      };
    }

    users = users.filter((user: UserContext) => {
      return user.timelines.filter(tlFilter).length > 0;
    });
    return users;
  }

  getYears(): Array<number> {
    let years: Array<number> = this.timelineBatch
      .UserContexts()
      .map((user: UserContext) => user.date_of_birth.getFullYear());
    return years.filter((item, pos) => years.indexOf(item) === pos);
  }

  getFunctionCompiler() {
    if (this.timelineBatch.getCompiler(this.vergleichsweg)) {
      this.functionCompiler = this.timelineBatch.getCompiler(
        this.vergleichsweg,
      )!;
      console.log(
        'Getting function-compiler for ' +
          this.vergleichsweg +
          ': ' +
          this.functionCompiler.getFunctionString(),
      );
    } else {
      console.log('creating new function-compiler for ' + this.vergleichsweg);
      this.functionCompiler = new FunctionCompiler();
      this.functionCompiler.setTimelineBatch(this.timelineBatch);
      this.functionCompiler.setVergleichsweg(this.vergleichsweg);
      this.timelineBatch.setCompiler(this.vergleichsweg, this.functionCompiler);
    }
  }

  ngOnInit(): void {
    this.getFunctionCompiler();
    this.maximalBetrag.valueChanges.subscribe((x) => {
      this.functionCompiler.maximalBetrag = x;
      this.redraw();
    });
    this.rbDeckel.valueChanges.subscribe((x) => {
      this.functionCompiler.rbDeckel = x;
      this.redraw();
    });
    this.rentenDeckelTyp.valueChanges.subscribe((x) => {
      this.functionCompiler.rentenDeckel = x;
      this.redraw();
    });
  }
}
