<ng-container>
  <mat-card style="margin-bottom: 3em">
    <mat-form-field>
      <mat-label>Zeilenhöhe</mat-label>
      <input
        matInput
        type="number"
        [formControl]="zeilenhoehe"
        placeholder="Zeilenhöhe"
        min="1"
        max="12"
      />
    </mat-form-field>
    <mat-card-content>
      <plotly-plot
        [data]="graph.data"
        [layout]="graph.layout"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '98vw'
        }"
        [useResizeHandler]="true"
        [divId]="'gesamtuebersichtGraph'"
        [revision]="revision"
      >
      </plotly-plot>
    </mat-card-content>
  </mat-card>
  <!--
	<mat-card *ngIf="vergleichswegDaten" style="margin-bottom:3em;">
	    <mat-card-title>Statistik über den Vergleichsweg <br/><br/>"{{vergleichsweg}}"</mat-card-title>
	    <mat-card-content [style.width.vw]="98">

	      <table mat-table [dataSource]="vergleichswegDaten">

	        
	        <ng-container matColumnDef="name">
	          <th mat-header-cell *matHeaderCellDef> Name </th>
	          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
	        </ng-container>

	        <ng-container matColumnDef="count">
	          <th mat-header-cell class="text-centered" *matHeaderCellDef> Anzahl </th>
	          <td mat-cell *matCellDef="let element" class="text-centered"> {{element.count}} </td>
	        </ng-container>

			<ng-container matColumnDef="percent">
	          <th mat-header-cell *matHeaderCellDef class="text-centered"> Prozent </th>
	          <td mat-cell *matCellDef="let element" class="text-centered"> 
	          	<span *ngIf="element.percent != '---'">
	          		{{element.percent|number: '1.1-1'}}% 
	          	</span>
	          </td>
	        </ng-container>
	        <tr mat-header-row *matHeaderRowDef="vergleichtableFields"></tr>
	        <tr mat-row *matRowDef="let row; columns: vergleichtableFields;"></tr>
	      </table>
	     
	    </mat-card-content>
 	</mat-card>
	-->
  <mat-card>
    <mat-card-title>Gegenüberstellung: Beste verfügbare Rente</mat-card-title>
    <mat-card-content>
      <div>
        <div>Berücksichtigte Wege:</div>
        <ul>
          <li *ngFor="let weg of piechart_renten_beruecksichtigte_wege">
            {{ weg }}
          </li>
        </ul>
        <div class="fl full-width">
          Dieser Graph zeigt an, für wieviele Personen die jeweils angezeigte
          Rente, die beste Rente ist, die ohne Lücke (das heißt der Zeitraum
          'Lücke' oder 'Joker' darf nicht vorkommen) möglich ist.
          <br />
          Achtung: Ist als Füll-Zeitraum für leere Zeiträume 'Brücke' gewählt,
          kommt hier nur die Regelaltersrente heraus, da die Brücke nicht als
          Lücke zählt.0
        </div>
      </div>
      <plotly-plot
        class="ml15"
        [data]="pieChart.renten.data"
        [layout]="pieChart.renten.layout"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '30vw',
          height: '30vw',
          'margin-left': '0px'
        }"
        [useResizeHandler]="true"
        [divId]="'pieChartGesamtUebersicht'"
        [revision]="revision"
      >
      </plotly-plot>
    </mat-card-content>
  </mat-card>
  <mat-card *ngFor="let specialPension of timelineBatch.getSpecialPensions()">
    <mat-card-title
      >Gegenüberstellung: Verteilung der Renten innerhalb der
      {{ specialPension }}</mat-card-title
    >
    <mat-card-content>
      <div class="fl full-width">
        Dieser Graph zeigt an, wie oft die {{ specialPension }} in welche Rente
        (ohne Lücke, das heißt der Zeitraum 'Lücke' oder 'Joker' darf nicht
        vorkommen) endet.
      </div>
      <plotly-plot
        *ngIf="pieChart.specialPensions[specialPension]"
        class="ml15"
        [data]="pieChart.specialPensions[specialPension].data"
        [layout]="pieChart.renten.layout"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '30vw',
          height: '30vw',
          'margin-left': '0px'
        }"
        [useResizeHandler]="true"
        [divId]="'pieChartUebersicht' + specialPension"
        [revision]="revision"
      >
      </plotly-plot>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="referenzwegStats" style="margin-bottom: 3em">
    <mat-card-title>Statistik über den Referenzweg:</mat-card-title>
    <mat-card-content>
      <table mat-table [dataSource]="referenzwegStats">
        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="count">
          <th mat-header-cell *matHeaderCellDef>Anzahl</th>
          <td mat-cell *matCellDef="let element" class="text-centered">
            {{ element.count }}
          </td>
        </ng-container>

        <ng-container matColumnDef="percent">
          <th mat-header-cell *matHeaderCellDef>Prozent</th>
          <td mat-cell *matCellDef="let element" class="text-centered">
            <span *ngIf="element.percent != '---'"
              >{{ element.percent | number: "1.1-1" }}%
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="before_deadline">
          <th mat-header-cell *matHeaderCellDef>Vor Betriebsauflösung</th>
          <td mat-cell *matCellDef="let element" class="text-centered">
            {{ element.before_deadline }}
          </td>
        </ng-container>

        <ng-container matColumnDef="before_deadline_percent">
          <th mat-header-cell *matHeaderCellDef>Vor Betriebsauflösung (%)</th>
          <td mat-cell *matCellDef="let element" class="text-centered">
            <span *ngIf="element.before_deadline_percent != '---'">
              {{ element.before_deadline_percent | number: "1.1-1" }}%
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableFields"></tr>
        <tr mat-row *matRowDef="let row; columns: tableFields"></tr>
      </table>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Zeiträume</mat-card-title>
    <mat-card-content>
      <table>
        <tr>
          <th>Name</th>
          <th>Monate Gesamt</th>
          <th>Min. Monate</th>
          <th>Max. Monate</th>
          <th>Datenpunkte</th>
          <th>Durchschnitt</th>
        </tr>
        <tr *ngFor="let item of slotStats">
          <td>{{ item.name }}</td>
          <td>{{ item.total }}</td>
          <td>{{ item.min }}</td>
          <td>{{ item.max }}</td>
          <td>{{ item.samples }}</td>
          <td>{{ item.total / item.samples }}</td>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>
</ng-container>
