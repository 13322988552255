import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ISlotRequest } from '@classes/slot-request';
import { TimelineRequest, ITimelineRequest } from '@classes/timeline-request';
import { TimelineCreatorService } from '@services/timeline-creator.service';
import { saveAs } from 'file-saver';
import { Moment } from 'moment/moment';
import { throwIfEmpty } from 'rxjs';

@Component({
  selector: 'app-timeline-creation',
  templateUrl: './timeline-creation.component.html',
  styleUrls: ['./timeline-creation.component.scss'],
})
export class TimelineCreationComponent implements OnInit, OnChanges {
  form: FormGroup;
  createForm() {
    this.form = this.fb.group(
      {
        showEditSlotDetails: [false, Validators.nullValidator],
        newTimeslotType: ['none', Validators.nullValidator],
        gapType: ['joker', Validators.nullValidator],
        startValueFrom: ['undefined', Validators.nullValidator],
        endValueFrom: ['undefined', Validators.nullValidator],
        startDatum: [new Date(), Validators.nullValidator],
        endDatum: [new Date(), Validators.nullValidator],
        max_duration: [48, Validators.nullValidator],
        dauer: [48, Validators.nullValidator],
        max_age: [63, Validators.nullValidator],
        nettoFaktor: [0.85, Validators.nullValidator],
        bruttoFaktor: [0.85, Validators.nullValidator],
        bridge_brutto: [0.85, Validators.nullValidator],
        bridge_netto: [0.85, Validators.nullValidator],
        paddingPriority: [0.85, Validators.nullValidator],
        padding_enabled: [true, Validators.nullValidator],
        brutto_faktor_enabled: [true, Validators.nullValidator],
        netto_faktor_enabled: [true, Validators.nullValidator],
        dauer_enabled: [false, Validators.nullValidator],
        max_dauer_enabled: [true, Validators.nullValidator],
        max_age_enabled: [true, Validators.nullValidator],
        ende_enabled: [true, Validators.nullValidator],
        start_enabled: [true, Validators.nullValidator],
        minijob: [false, Validators.nullValidator],
        geschaeftsaufgabe: [false, Validators.nullValidator],
        dauer_phase_x_csv_enabled: [false, Validators.nullValidator],
        dauer_phase_y_csv_enabled: [false, Validators.nullValidator],
        dauer_phase_x_csv: [false, Validators.nullValidator],
        dauer_phase_y_csv: [false, Validators.nullValidator],
        alt_name: ["", Validators.nullValidator]
      }
    );
  }
  slots: Array<any> = [];

  now = new Date();

  slotToEdit: any = undefined;

  @Input('markerAge') markerAge: number | undefined;
  @Input('betriebsaufloesung') betriebsaufloesung: Date | undefined;

  constructor(private tcs: TimelineCreatorService, private fb: FormBuilder) {
    this.createForm();
  }

  formatDate(date: Date | Moment): string {
    if (date instanceof Date) {
      return (
        date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      );
    } else {
      return date.format('DD.MM.YYYY');
    }
  }

  requestForSlot(slot: any): ISlotRequest {
    let formData = { ...this.form.value };
    let sr: ISlotRequest = {
      type: slot.type,
    };
    if (!['', null, undefined].includes(formData.alt_name)) {
      sr.alt_name = formData.alt_name;
    }
    if (slot.start_enabled) {
      if (slot.startValueFrom != 'last_end') {
        if (slot.startValueFrom == 'earliest_of(wz_bis,ep_bis)') {
          sr.start = 'earliest_of(wz_bis,ep_bis)';
        } else if (slot.startValueFrom == 'begin_of_next_year') {
          sr.start = 'begin_of_next_year';
        } else if (slot.startValueFrom == 'end_of_this_year') {
          sr.start = 'end_of_this_year';
        } else if (slot.startValueFrom == 'user.meta.start_tg') {
          sr.start = 'user.meta.start_tg';
        } else if (slot.startValueFrom == 'user.meta.start_phase_x') {
          sr.start = 'user.meta.start_phase_x';
        } else if (slot.startValueFrom == 'user.meta.start_phase_y') {
          sr.start = 'user.meta.start_phase_y';
        } else if (slot.startValueFrom == 'user.meta.start_atz') {
          sr.start = 'user.meta.start_atz';
        } else {
          sr.start = this.formatDate(slot.startDatum);
        }
      }
    }
    if (slot.geschaeftsaufgabe === true) {
      sr.durch_geschaeftsaufgabe = true;
    }
    if (slot.minijob === true) {
      sr.minijob = true;
    }
    if (slot.padding_enabled) {
      sr.paddingPriority = slot.paddingPriority;
    }
    if (slot.dauer_enabled) {
      sr.dauer = slot.dauer;
    } else if (slot.dauer_phase_x_csv === true && slot.type === 'PhaseX') {
      sr.dauer = 'user.meta.dauer_phase_x';
    } else if (slot.dauer_phase_y_csv === true && slot.type === 'PhaseY') {
      sr.dauer = 'user.meta.dauer_phase_y';
    }
    if (slot.ende_enabled) {
      if (slot.endValueFrom != 'auto') {
        if (slot.endValueFrom == 'custom_date') {
          sr.end = this.formatDate(slot.endDatum);
        } else if (slot.endValueFrom == 'user.meta.austrittstermin_1') {
          sr.end = 'user.meta.austrittstermin_1';
        } else if (slot.endValueFrom == 'begin_of_next_year') {
          sr.end = 'begin_of_next_year';
        } else if (slot.endValueFrom == 'end_of_this_year') {
          sr.end = 'end_of_this_year';
        } else {
          if (slot.endValueFrom == 'company_oob_date') {
            console.log('setting end to');
            console.log(this.betriebsaufloesung);
            sr.end = this.formatDate(this.betriebsaufloesung!);
          } else {
            console.log('Unhandled endValueFrom: ' + slot.endValueFrom);
          }
        }
      }
    }

    if (slot.max_dauer_enabled) {
      if (slot.max_duration && slot.max_duration > 0) {
        sr.maximal_dauer = slot.max_duration;
      }
    }

    if (slot.max_age_enabled) {
      if (slot.max_age && slot.max_age > 0) {
        sr.max_alter_ende = slot.max_age;
      }
    }

    if (slot.netto_faktor_enabled) {
      if (slot.nettoFaktor !== undefined) {
        console.log("nettofaktor: " + slot.nettoFaktor);
        sr.nettoFaktor = slot.nettoFaktor;
      }
    } else {
      console.log("nettofaktor disabled.");
    }

    if (slot.brutto_faktor_enabled) {
      if (slot.bruttoFaktor !== undefined) {
        sr.bruttoFaktor = slot.bruttoFaktor;
      }
    } else {
      console.log("bruttofaktor disabled.");
    }

    return sr;
  }
  getSlotRequests(): Array<ISlotRequest> {
    return this.slots.map((x) => this.requestForSlot(x));
  }

  existingIdentifiers(): Array<string> {
    return this.tcs.timelines.map(
      function (x: ITimelineRequest): string {
        return x.identifier ?? 'undefined';
      }
    );
  }
  getMarkers() {
    let markers = [
      {
        title: 'AgeMarkerM10',
        at: 'age:' + (parseInt('' + this.markerAge) - 10),
      },
      { title: 'AgeMarker', at: 'age:' + parseInt('' + this.markerAge) },
      {
        title: 'AgeMarkerP10',
        at: 'age:' + (parseInt('' + this.markerAge) + 10),
      },
    ];

    return markers;
  }


  getTimeline(): TimelineRequest {
    let formData = { ...this.form.value };
    let data: ITimelineRequest = {
      name: this.identifier(),
      slots: [...this.getSlotRequests(), { type: 'Rente' }],
      enabled: true,
      padder: this.form.get('gapType')?.value ?? undefined,
      identifier: this.identifier(),
      markers: this.getMarkers(),
    };
    console.log("timeline constructed: ");
    console.log(data);
    if (formData.gapType == 'bridge') {
      data.gapdata = {
        nettoFaktor: formData.bridge_netto,
        bruttoFaktor: formData.bridge_brutto,
      };
    }
    return new TimelineRequest(data);
  }

  identifier(): string {
    let identifier =
      this.slots.map((slot) => slot.type).join(' -> ') ?? undefined;
    let len = this.tcs.timelines.length + 1;
    return 'Dyn.#' + len + identifier + '->Rente';
  }

  resetUiToDefaults(): any {
    this.form.patchValue(
      {
        newTimeslotType: 'none',
        dauer_phase_x_csv_enabled: false,
        dauer_phase_y_csv_enabled: false,
        dauer_phase_x_csv: null,
        dauer_phase_y_csv: null,
        startValueFrom: 'earliest_of(wz_bis,ep_bis)',
        endValueFrom: 'auto',
        startDatum: new Date(),
        endDatum: new Date(),
        max_duration: 48,
        max_age: 63,
        bruttoFaktor: 0.85,
        nettoFaktor: 0.85,
        paddingPriority: this.slots.length + 1,
        dauer_enabled: true,
        dauer: 24,
        padding_enabled: true,
        brutto_faktor_enabled: true,
        netto_faktor_enabled: true,
        max_dauer_enabled: true,
        max_age_enabled: true,
        ende_enabled: true,
        start_enabled: true,
        minijob: false,
        geschaeftsaufgabe: false,
        alt_name: null
      });
  }

  patchUI() {
    let formData = this.form.value;
    if (this.slots.length == 1) {
      this.form.patchValue({
        startValueFrom: 'earliest_of(wz_bis,ep_bis)',
        endValueFrom: 'company_oob_date'
      });
    } else {
      this.form.patchValue({ startValueFrom: 'last_end' });
    }
    switch (this.slotToEdit?.type) {
      case 'Arbeit': {
        this.form.patchValue({
          netto_faktor_enabled: false,
          brutto_faktor_enabled: false,
          bruttoFaktor: 1,
          nettoFaktor: 1
        });
        break;
      }
      case 'ALG1': {
        this.form.patchValue({
          netto_faktor_enabled: true,
          brutto_faktor_enabled: true,
          bruttoFaktor: 0.8,
          nettoFaktor: 0.6
        });
        break;
      }
      case 'Transfergesellschaft': {
        this.form.patchValue({
          brutto_faktor_enabled: true,
          bruttoFaktor: 0.8,
        });
      }
    }

    if (!['ATZ', 'PhaseX', 'PhaseY'].includes(this.slotToEdit.type)) {
      this.form.patchValue({
        max_age_enabled: false,
        max_dauer_enabled: false
      });
    }
    if (this.slotToEdit.type === 'PhaseX') {
      this.form.patchValue({ 'dauer_phase_x_csv_enabled': true });
    } else {
      this.form.patchValue({ 'dauer_phase_x_csv_enabled': false });
    }

    if (this.slotToEdit.type === 'PhaseY') {
      this.form.patchValue({ 'dauer_phase_y_csv_enabled': true });
    } else {
      this.form.patchValue({ 'dauer_phase_y_csv_enabled': false });
    }
  }
  add() {
    // save the type of the slot that needs to be created.
    let type = this.form.get('newTimeslotType')?.value;
    // make nothing interfere with me while i'm adding a new slot
    this.editSlot(undefined);
    // create "default" slot as indicated by UI.
    this.resetUiToDefaults();
    let newSlot = { ...this.form.value };




    newSlot.type = type;
    this.slots.push(newSlot);
    this.editSlot(newSlot);
    this.patchUI()


  }


  editSlot(slot?: any) {
    if (slot === undefined) {
      this.slotToEdit = undefined;
      this.showControls(null);
      return;
    }
    if (this.slotToEdit == slot && this.slotToEdit !== undefined) {
      this.slotToEdit = undefined;
      this.showControls(null);
      return;
    }

    if (slot !== undefined) {
      let currentFormData = this.form.value;
      let currentEditSlot = this.editSlot;
      this.slotToEdit = slot;
      this.form.patchValue(slot);
      this.showControls(slot.type);
      return;
    }
  }

  updateSlotFromUi(slot: any) {
    let formData = { ...this.form.value };

    for (let key of Object.keys(slot)) {
      if (formData[key] !== undefined) {
        slot[key] = formData[key];
      }
    }
    return slot;
  }

  remove(slot: any) {
    let idx = this.slots.indexOf(slot);
    if (idx >= 0) {
      this.slots.splice(idx, 1);
    }
    if (this.slots.length > idx) {
      this.editSlot(this.slots[idx - 1])
    } else {
      this.editSlot(undefined);
    }
  }

  ngOnInit(): void {
    let endOfYear = new Date();
    endOfYear.setMonth(11);
    endOfYear.setDate(31);
    endOfYear.setHours(23);
    endOfYear.setMinutes(59);
    endOfYear.setSeconds(59);
    if (this.betriebsaufloesung) {
      this.betriebsaufloesung = endOfYear;
    }
    let endOfLastYear = new Date(endOfYear.getTime());

    this.form.valueChanges.subscribe({
      next: () => this.uiChanged(),
      error: console.error
    })
    this.form.controls?.['dauer_enabled']?.valueChanges.subscribe(
      {
        next: (value) => {
          if (value === true) {
            this.form.patchValue({ 'dauer_phase_x_csv': false }, { emitEvent: false });
            this.form.patchValue({ 'dauer_phase_y_csv': false }, { emitEvent: false });
          }
        }
      }
    );

    this.form.controls?.['dauer_phase_x_csv']?.valueChanges.subscribe(
      {
        next: (value) => {
          if (value === true) {
            this.form.patchValue({ 'dauer_enabled': false }, { emitEvent: false });
            this.form.patchValue({ 'dauer_phase_y_csv': false }, { emitEvent: false });
          }
        }
      }
    );
    this.form.controls?.['dauer_phase_y_csv']?.valueChanges.subscribe(
      {
        next: (value) => {
          if (value === true) {
            this.form.patchValue({ 'dauer_enabled': false }, { emitEvent: false });
            this.form.patchValue({ 'dauer_phase_x_csv': false }, { emitEvent: false });
          }
        }
      }
    );
  }


  uiChanged() {
    if (this.slotToEdit !== undefined) {
      let slot = this.updateSlotFromUi(this.slotToEdit);
    }
  }

  controls: any = {
    start: {
      available: false,
    },
    end: {
      available: false,
    },
    max_duration: {
      available: false,
    },
    dauer: {
      available: false,
    },
    nettoFaktor: {
      available: false,
    },
    bruttoFaktor: {
      available: false,
    },
    paddingPriority: {
      available: false,
    },
    max_age: {
      available: false,
    },
    minijob: {
      available: false,
    },
    geschaeftsaufgabe: {
      available: false,
    },
  };

  showControls(_type: string | null) {
    if (_type === null) {
      this.controls = {};
      return;
    }

    let type = _type?.toLowerCase();
    this.setControlsFor(type);
  }

  setControlsFor(type: string) {
    this.controls = {
      start: {
        available: true,
        field: 'start',
        options: [
          {
            caption: "Frühestes datum von 'wz_bis' und 'ep_bis'",
            value: 'earliest_of(wz_bis,ep_bis)',
          },
          {
            caption: 'Datum',
            value: 'custom_date',
          },
          {
            caption: 'Nach Ende des vorherigen Zeitraumes',
            value: 'last_end',
          },
          {
            caption: 'Jahresanfang nach vorh. Zeitr.',
            value: 'begin_of_next_year',
          },
          {
            caption: 'Jahresende',
            value: 'end_of_this_year',
          },
          {
            caption: 'Start TG laut csv',
            value: 'user.meta.start_tg',
          }
          ,
          {
            caption: 'Start Phase X laut csv',
            value: 'user.meta.start_phase_x',
          },
          {
            caption: 'Start Phase Y laut csv',
            value: 'user.meta.start_phase_y',
          },
          {
            caption: 'Start ATZ laut csv',
            value: 'user.meta.start_atz',
          }
        ],
      },
      end: {
        available: true,
        field: 'end',
        options: [
          {
            caption: 'Automatisch',
            value: 'auto',
          },
          {
            caption: 'Datum',
            value: 'custom_date',
          },
          {
            caption: 'Betriebsauflösung',
            value: 'company_oob_date',
          },
          {
            caption: 'Austrittsdatum nach CSV',
            value: 'user.meta.austrittstermin_1',
          },
          {
            caption: 'Jahresanfang nach vorh. Zeitr.',
            value: 'begin_of_next_year',
          },
          {
            caption: 'Jahresende',
            value: 'end_of_this_year',
          }
        ],
      },
      dauer: {
        available: true,
        field: 'dauer',
      },
      max_duration: {
        available: true,
        field: 'max_duration',
      },
      max_age: {
        available: true,
        field: 'max_age',
      },
      nettoFaktor: {
        available: true,
        field: 'nettoFaktor',
      },
      bruttoFaktor: {
        available: true,
        field: 'bruttoFaktor',
      },
      paddingPriority: {
        available: true,
        field: 'paddingPriority',
      },
    };

  }
  addTimeline() {
    let tl = this.getTimeline();
    tl.setOriginalData([...this.slots]);
    console.log(tl);
    this.tcs.addTimeline(tl);
    this.slots.length = 0;
    this.editSlot(undefined);
    this.resetUiToDefaults();
    this.currentTimeline = undefined;
  }
  currentTimeline?: TimelineRequest = undefined;
  ngOnChanges(changes: SimpleChanges) { }

  deleteTimlineByIdentifier(identifier: string) {
    let tl = this.tcs.getTimelineByIdentifier(identifier);
    this.tcs.deleteTimelineByIdentifier(identifier);
    if (tl === this.currentTimeline) {
      this.currentTimeline = undefined;
    }

  }
  saveToExistingTimeline() {
    if (this.currentTimeline === undefined) {
      return;
    }
    let tl = this.currentTimeline;
    tl.setOriginalData([...this.slots]);

    this.slots.length = 0;
    this.editSlot(undefined);
    this.resetUiToDefaults();
    this.currentTimeline = undefined;
    this.tcs.save();
  }


  editTimelineByIdentifer(identifier: string) {
    this.slots.length = 0;
    this.currentTimeline = <TimelineRequest>this.tcs.getTimelineByIdentifier(identifier);

    let tlslots = (<any[]>this.currentTimeline.getOriginalData()).map((x: any) => ({ ...x }));
    this.slots.push(...tlslots);
    if (this.slots.length > 0) {
      this.editSlot(this.slots.length - 1)
    }
  }
  abort() {
    this.editSlot(undefined);
    this.currentTimeline = undefined;
    this.slots.length = 0;

  }
  datensatzChanged(event: any) {
    let file = event?.target?.files[0];
    let datensatzReader = new FileReader();
    if (datensatzReader) {
      datensatzReader.onloadend = (e) => {
        this.tcs.loadFromJson(<string>datensatzReader.result);
      };
      datensatzReader.readAsText(file);
    }
  }
  export() {
    let date = (new Date()).toLocaleDateString();
    let json = JSON.stringify(this.tcs.timelines);
    saveAs(new Blob([json]), 'zeitlinien-' + date + '.json');
  }
}
