<ng-container>
  <mat-card>
    <mat-card-title> Datenbestand </mat-card-title>
    <mat-card-content>
      <button [style.margin-em]="1" type="button" mat-raised-button (click)="fileInput.click()">
        Datensatz laden (CSV)
      </button>
      <input hidden #fileInput type="file" class="file-input" (change)="datensatzChanged($event)" />
      <button [style.margin-em]="1" type="button" mat-raised-button (click)="save()">
        Daten im Browser Speichern
      </button>
      <button [style.margin-em]="1" type="button" mat-raised-button (click)="load()">
        Daten vom Browser laden
      </button>
      <button [style.margin-em]="1" type="button" mat-raised-button (click)="delete()">
        Daten aus dem Browser löschen
      </button>
      <mat-checkbox [formControl]="autoload">Gespeicherte Daten automatisch laden</mat-checkbox>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Parameter</mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <mat-label>Zusatzbeitrag (Krankenkasse)</mat-label>
        <input matInput placeholder="Zusatzbeitrag (Krankenkasse)" hintLabel="Zusatzbeitrag (Krankenkasse)"
          [formControl]="kv_zusatzbeitrag" min="0" max="5" step="0.1" value="1.3" type="number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Zusatzbeitrag (Pflegeversicherung)</mat-label>
        <input matInput [formControl]="pv_zusatzbeitrag" placeholder="Zusatzbeitrag (Pflegeversicherung)"
          hintLabel="Zusatzbeitrag (Pflegeversicherung)" min="0" max="5" step="0.1" value="3.05" type="number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Betriebsauflösung</mat-label>
        <input hintLabel="Betriebsauflösung" matInput [matDatepicker]="picker" [formControl]="betriebsaufloesung"
          placeholder="Betriebsauflösung" />
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker startView="year" [startAt]="now" #picker>
        </mat-datepicker>
      </mat-form-field>
      <mat-checkbox class="example-margin" [formControl]="kv_privat_brutto_gleich_netto">SV = +/-0 für
        PKV</mat-checkbox><br />
      <mat-checkbox class="example-margin" [formControl]="use_austrittstermin">Spezifischen Austrittstermin verwenden
        (statt
        "Betriebsauflösung")</mat-checkbox><br />
      <mat-checkbox class="example-margin" [formControl]="use_kuendigungsfrist">Kündigungsfrist
        verwenden</mat-checkbox><br />
      <mat-checkbox class="example-margin" [formControl]="use_betriebsaufloesung_in_alg1">ALG1 wegen Geschäftsaufgabe
        annehmen</mat-checkbox><br />
      <mat-checkbox class="example-margin" [formControl]="use_minijob_in_alg1">ALG1 mit Minijob
        annehmen</mat-checkbox><br />
      <mat-form-field>
        <mat-label>Betriebszugehörigkeit am</mat-label>
        <input hintLabel="Betriebszugehörigkeit am" matInput [matDatepicker]="picker_betriebszugehoerigkeit"
          [formControl]="betriebszugehoerigkeit_datum" placeholder="Betriebszugehörigkeit am" />
        <mat-datepicker-toggle matSuffix [for]="picker_betriebszugehoerigkeit">
        </mat-datepicker-toggle>
        <mat-datepicker startView="year" [startAt]="now" #picker_betriebszugehoerigkeit>
        </mat-datepicker>
      </mat-form-field>
      (+ {{ additional }} Jahr(e))
      <mat-divider></mat-divider>
      <br /><br />
      <mat-label>Transfergesellschaft</mat-label><br />
      <mat-form-field>
        <mat-label>Dauer</mat-label>
        <input matInput [formControl]="tgDauer" placeholder="TG-Dauer" hintLabel="TG-Dauer" min="0" max="120" step="1"
          type="number" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nettofaktor</mat-label>
        <input matInput type="number" placeholder="Nettofaktor" hintLabel="Nettofaktor" min="0" max="1.3" step="0.05"
          [formControl]="tg_netto" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Bruttofaktor</mat-label>
        <input matInput type="number" placeholder="Bruttofaktor" hintLabel="Bruttofaktor" min="0" max="1.3" step="0.05"
          [formControl]="tg_brutto" />
      </mat-form-field>
      <mat-divider></mat-divider>
      <br /><br />
      <mat-label>ATZ</mat-label><br />
      <mat-form-field>
        <mat-label>Maximale Dauer</mat-label>
        <input matInput [formControl]="atzDauer" placeholder="ATZ-Dauer" hintLabel="ATZ-Dauer" min="0" max="120"
          step="1" type="number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Nettofaktor</mat-label>
        <input matInput type="number" placeholder="Nettofaktor" hintLabel="Nettofaktor" min="0" max="1.3" step="0.05"
          [formControl]="atz_netto" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Bruttofaktor</mat-label>
        <input matInput type="number" placeholder="Bruttofaktor" hintLabel="Bruttofaktor" min="0" max="1.3" step="0.05"
          [formControl]="atz_brutto" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Begrenzung durch Rentenbeginn:</mat-label>
        <mat-select [formControl]="atz_latest_end_pension" panelClass="timelineSelectPanel">
          <mat-option style="width: 30em" [value]="'none'">Keine Begrenzung durch einen Rentenbeginn</mat-option>
          <mat-option style="width: 30em" [value]="'earliest,regular'">Frühester Beginn der
            Regelaltersrente</mat-option>
          <mat-option style="width: 30em" [value]="'earliest,unreduced'">Frühester Beginn einer ungeminderten
            Rente</mat-option>
          <mat-option style="width: 30em" [value]="'earliest,reduced'">Frühester Beginn einer geminderten
            Rente</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Maximal bis Alter</mat-label>
        <input matInput type="number" placeholder="max. Alter" hintLabel="max. Alter" min="40" max="85" step="1"
          [formControl]="atz_max_alter" />
      </mat-form-field>
      <mat-divider></mat-divider>
      <br /><br />
      <mat-label>ALG1</mat-label><br />
      <mat-form-field>
        <mat-label>Dauer</mat-label>
        <input matInput [formControl]="algDauer" placeholder="ALG1-Dauer" hintLabel="ALG1-Dauer" min="0" max="120"
          step="1" type="number" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nettofaktor</mat-label>
        <input matInput type="number" placeholder="Nettofaktor" hintLabel="Nettofaktor" min="0" max="1.3" step="0.05"
          [formControl]="alg_netto" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Bruttofaktor</mat-label>
        <input matInput type="number" placeholder="Bruttofaktor" hintLabel="Bruttofaktor" min="0" max="1.3" step="0.05"
          [formControl]="alg_brutto" />
      </mat-form-field>

      <mat-divider></mat-divider>
      <br />

      <mat-form-field>
        <mat-label>Kündigungsfrist</mat-label>
        <br />
        <input matInput [formControl]="kuendigungsFrist" placeholder="Kündigungsfrist" hintLabel="Kündigungsfrist"
          min="0" max="120" step="1" type="number" />
      </mat-form-field>
      <br />
      <mat-divider></mat-divider>
      <br />
      <mat-label>Erfüllte Bedingungen für alle Personen annhemen (die Werte aus der
        Tabelle ignorieren)</mat-label><br />
      <mat-checkbox class="example-margin" [formControl]="erfuellte_wz_5" [disabled]="true">5 Jahre</mat-checkbox><br />
      <mat-checkbox [formControl]="erfuellte_wz_35" class="example-margin">35 Jahre Wartezeit</mat-checkbox><br />
      <mat-checkbox [formControl]="erfuellte_wz_45" class="example-margin">45 Jahre Wartezeit</mat-checkbox><br />
      <mat-checkbox [formControl]="assume_gdb_zero" class="example-margin">GDB = 0</mat-checkbox><br />
      <br />
      <mat-divider></mat-divider>
      <br />
      <mat-label>Leere Zeiträume</mat-label>
      <br />
      <mat-form-field>
        <mat-label>Fülle leere Zeiträume mit:</mat-label>
        <mat-select [formControl]="empty_spaces" panelClass="timelineSelectPanel">
          <mat-option style="width: 30em" [value]="'bridge'">Brücke</mat-option>
          <mat-option style="width: 30em" [value]="'luecke'">Lücke</mat-option>
          <mat-option style="width: 30em" [value]="'joker'">Joker</mat-option>
        </mat-select> </mat-form-field><br />
      <mat-label>Leere Zeiträume für fest hinterlegte Vallourec-Zeitlinien</mat-label>
      <br />
      <mat-form-field>
        <mat-label>Fülle leere Zeiträume mit:</mat-label>
        <mat-select [disabled]="true" [formControl]="empty_spaces_vallourec" panelClass="timelineSelectPanel">
          <mat-option style="width: 30em" [value]="'bridge'">Brücke</mat-option>
          <mat-option style="width: 30em" [value]="'luecke'">Lücke</mat-option>
          <mat-option style="width: 30em" [value]="'joker'">Joker</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-label *ngIf="empty_spaces.value == 'luecke'">
        Lücke:
        <ul>
          <li>Monatliche Wartezeiten, Entgelte und EP = 0</li>
        </ul>
      </mat-label>
      <br />
      <mat-label *ngIf="empty_spaces.value == 'joker'">
        Joker:
        <ul>
          <li>Monatliche Wartezeiten = 1</li>
          <li>Entgelte und EP = 0</li>
        </ul>
      </mat-label>
      <mat-label *ngIf="empty_spaces.value == 'bridge'">
        Brücke:
        <ul>
          <li>Monatliche Wartezeiten = 1</li>
          <li>Nettoentgelt = {{ bridge_netto.value }} x nettoentgelt</li>
          <li>
            Bruttoentgelt (EP) = {{ bridge_brutto.value }} x bruttoentgelt
          </li>
        </ul>
        <mat-form-field>
          <mat-label>Nettofaktor</mat-label>
          <input matInput type="number" placeholder="Nettofaktor" hintLabel="Nettofaktor" min="0" max="1.3" step="0.05"
            [formControl]="bridge_netto" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Bruttofaktor</mat-label>
          <input matInput type="number" placeholder="Bruttofaktor" hintLabel="Bruttofaktor" min="0" max="1.3"
            step="0.05" [formControl]="bridge_brutto" />
        </mat-form-field>

        <br />
      </mat-label>
      <br />
      <br />
      <mat-divider></mat-divider>
      <br /><br />
      <mat-label>PhaseX</mat-label><br />
      <mat-checkbox class="example-margin" [checked]="phaseXEnabled.value"
        (change)="phaseXEnabled.setValue(!phaseXEnabled.value)">Eingeschaltet</mat-checkbox>
      <br />
      <mat-form-field>
        <mat-label>Dauer</mat-label>
        <input matInput [formControl]="phaseXDauer" placeholder="PhaseX-Dauer" hintLabel="PhaseX-Dauer" min="0"
          max="120" step="1" type="number" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nettofaktor</mat-label>
        <input matInput type="number" placeholder="Nettofaktor" hintLabel="Nettofaktor" min="0" max="1.3" step="0.05"
          [formControl]="phaseX_netto" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Bruttofaktor</mat-label>
        <input matInput type="number" placeholder="Bruttofaktor" hintLabel="Bruttofaktor" min="0" max="1.3" step="0.05"
          [formControl]="phaseX_brutto" />
      </mat-form-field>

      <br /><br />

      <mat-divider></mat-divider>
      <br /><br />
      <mat-label>PhaseY</mat-label><br />
      <mat-checkbox class="example-margin" [checked]="phaseYEnabled.value"
        (change)="phaseYEnabled.setValue(!phaseYEnabled.value)">Eingeschaltet</mat-checkbox>
      <br />
      <mat-form-field>
        <mat-label>Dauer</mat-label>
        <input matInput [formControl]="phaseYDauer" placeholder="PhaseY-Dauer" hintLabel="PhaseY-Dauer" min="0"
          max="120" step="1" type="number" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nettofaktor</mat-label>
        <input matInput type="number" placeholder="Nettofaktor" hintLabel="Nettofaktor" min="0" max="1.3" step="0.05"
          [formControl]="phaseY_netto" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Bruttofaktor</mat-label>
        <input matInput type="number" placeholder="Bruttofaktor" hintLabel="Bruttofaktor" min="0" max="1.3" step="0.05"
          [formControl]="phaseY_brutto" />
      </mat-form-field>

      <br /><br />
      <mat-divider></mat-divider>
      <mat-form-field>
        <mat-label>Lebensalter für Vergleich</mat-label>
        <input matInput placeholder="Lebensalter für Vergleich" hintLabel="Lebensalter für Vergleich"
          [formControl]="markerAge" value="85" />
      </mat-form-field>

      <div>
        <mat-checkbox class="example-margin" [checked]="rente_mit_enabled.value"
          (change)="rente_mit_enabled.setValue(!rente_mit_enabled.value)">
          Rente mit {{ rente_mit.value }} aktiv?
        </mat-checkbox>
      </div>

      <mat-form-field *ngIf="rente_mit_enabled.value">
        <mat-label> Rente mit ... </mat-label>
        <input matInput placeholder="Rente mit" type="text" hintLabel="Rente mit" [formControl]="rente_mit"
          value="65" />
      </mat-form-field>
      <br />

      <div>
        <mat-checkbox class="example-margin" [checked]="maximierterVorruhestandEnabled.value" (change)="
            maximierterVorruhestandEnabled.setValue(
              !maximierterVorruhestandEnabled.value
            )
          ">
          Rente mit maximiertem Vorruhestand aktiv?
        </mat-checkbox>
      </div>

      <br />
      <br />
      <mat-divider></mat-divider>
      <mat-checkbox #arbeit_bis_rente class="example-margin" [checked]="timelineSelected('arbeit_bis_rente')"
        disabled>Arbeit bis Rente (Referenzweg)</mat-checkbox>
      <br />
      <mat-radio-group *ngIf="arbeit_bis_rente.checked" [(ngModel)]="vergleichsEinschraenkungen"
        aria-label="Einschränkungen">
        <mat-radio-button disabled value="rar">Regelaltersrente</mat-radio-button><br />
        <mat-radio-button disabled value="unreduced,earliest">Früheste Ungeminderte</mat-radio-button>
        <br />
        <mat-radio-button disabled value="earliest">Früheste Überhaupt</mat-radio-button><br />
        <mat-radio-button value="">Alle Renten</mat-radio-button>
        <br />
      </mat-radio-group>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('arbeit_bis_betriebsaufloesung')"
          (change)="toggleTimeline('arbeit_bis_betriebsaufloesung')">
          Arbeit bis Betriebsauflösung -> ggf. {{ getEmptySpacesHString() }} ->
          Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="
            timelineSelected('arbeit_bis_betriebsaufloesung_kuendigungsfrist')
          " (change)="
            toggleTimeline('arbeit_bis_betriebsaufloesung_kuendigungsfrist')
          ">
          Arbeit bis Betriebsauflösung + Kündigungsfrist -> ALG1 -> ggf.
          {{ getEmptySpacesHString() }} -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('arbeit_tg_rente')"
          (change)="toggleTimeline('arbeit_tg_rente')">
          Arbeit -> TG -> ggf. {{ getEmptySpacesHString() }} -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('arbeit_tg_alg_rente')"
          (change)="toggleTimeline('arbeit_tg_alg_rente')">
          Arbeit -> TG -> ALG ->
          <span *ngIf="phaseXEnabled.value">Phase X -> </span><span *ngIf="phaseYEnabled.value">Phase Y -> </span>ggf.
          {{ getEmptySpacesHString() }} -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('arbeit_atz_alg_rente')"
          (change)="toggleTimeline('arbeit_atz_alg_rente')">
          Arbeit -> ATZ -> ALG ->
          <span *ngIf="phaseXEnabled.value">Phase X -> </span><span *ngIf="phaseYEnabled.value">Phase Y -> </span>ggf.
          {{ getEmptySpacesHString() }} -> Rente
        </mat-checkbox>
      </ng-container>

      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('vallourec_1')"
          (change)="toggleTimeline('vallourec_1')">
          Vallourec #1: Arbeit -> PhaseX -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('vallourec_2')"
          (change)="toggleTimeline('vallourec_2')">
          Vallourec #2: Arbeit -> PhaseX -> ALG1 -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('vallourec_3')"
          (change)="toggleTimeline('vallourec_3')">
          Vallourec #3: Arbeit -> PhaseX -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('vallourec_4')"
          (change)="toggleTimeline('vallourec_4')">
          Vallourec #4: Arbeit -> PhaseX -> ALG1 -> Rente
        </mat-checkbox>
      </ng-container>

      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('vallourec_5')"
          (change)="toggleTimeline('vallourec_5')">Vallourec #5: Arbeit -> Transfergesellschaft -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('vallourec_6')"
          (change)="toggleTimeline('vallourec_6')">
          Vallourec #6: Arbeit -> Transfergesellschaft -> ALG1 -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('arbeit_alg1_rente')"
          (change)="toggleTimeline('arbeit_alg1_rente')">
          AAR: Arbeit -> ALG1 -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container>
        <mat-checkbox class="example-margin" [checked]="timelineSelected('arbeit_alg1_sperre_rente')"
          (change)="toggleTimeline('arbeit_alg1_sperre_rente')">
          ASAR: Arbeit -> ALG1 Sperre -> ALG1 -> Rente
        </mat-checkbox>
      </ng-container>
      <ng-container *ngFor="let timeline of getDynamicTimelines()">
        <mat-checkbox class="example-margin" [checked]="use_dynamic_timelines.value" disabled="true">
          {{ timeline.identifier }}
        </mat-checkbox>
      </ng-container>
      <button *ngIf="users().length > 0" type="button" mat-raised-button (click)="send()">
        Berechnen
      </button>
      <br /><br />
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title><mat-checkbox [formControl]="use_dynamic_timelines">Dynamische Zeitlinien
        verwenden</mat-checkbox></mat-card-title>
    <mat-card-content *ngIf="use_dynamic_timelines.value">
      <app-timeline-creation [markerAge]="markerAge.value ?? undefined"
        [betriebsaufloesung]="getBetriebsaufloesungDate()"></app-timeline-creation>
    </mat-card-content>
  </mat-card>
  <app-user-edit [user]="detailedView" (onChange)="updateTableSource()" [batch]="timelineBatch"></app-user-edit>
  <mat-form-field *ngIf="users().length > 0">
    <mat-label>Betrachtete Vergleichs-Zeitlinie</mat-label>
    <mat-select [(value)]="selectedTimeline" panelClass="timelineSelectPanel">
      <mat-option style="width: 30em" *ngFor="let tl of getTimelineNames()" value="{{ tl }}">{{ tl }}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button (click)="nextUser()">Next User</button>
  <!--
      <mat-checkbox [formControl]="consider_regular_pension_unreduced" [disabled]="true" class="example-margin">Regelaltersrente als Ungeminderte Rente 
      betrachten</mat-checkbox>
    -->

  <mat-tab-group *ngIf="users().length > 0" [(selectedIndex)]="selectedTabIndex" [style.margin-top-em]="2" dynamicHeight
    (selectedTabChange)="triggerResize()" #mainTabGroup>
    <mat-tab label="Benutzertabelle" *ngIf="_parsedDatensatz || users().length > 0">
      <mat-card>
        <mat-card-title></mat-card-title>
        <mat-card-content>
          <table mat-table [dataSource]="getDataSource()" matSort (matSortChange)="announceSortChange($event)">
            <!-- Position Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button (click)="selecteElementAndChangeTabindex(element, 1)">
                  ->details
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by identifier">
                Identfier
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.identifier }}
              </td>
            </ng-container>

            <ng-container matColumnDef="vorname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by identifier">
                Vorname
              </th>
              <td mat-cell *matCellDef="let element">{{ element.vorname }}</td>
            </ng-container>
            <ng-container matColumnDef="nachname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by identifier">
                Nachname
              </th>
              <td mat-cell *matCellDef="let element">{{ element.nachname }}</td>
            </ng-container>
            <ng-container matColumnDef="standort">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by identifier">
                Standort
              </th>
              <td mat-cell *matCellDef="let element">{{ element.standort }}</td>
            </ng-container>

            <ng-container matColumnDef="kv">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by identifier">
                kv
              </th>
              <td mat-cell *matCellDef="let element">{{ element.kv }}</td>
            </ng-container>

            <ng-container matColumnDef="austrittstermin_1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by identifier">
                austrittstermin_1
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.austrittstermin_1 }}
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="date_of_birth">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date_of_birth">
                Geburtsdatum
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.date_of_birth | date: "dd.MM.yyyy" }}
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="gdb">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by gdb">
                GDB
              </th>
              <td mat-cell *matCellDef="let element">{{ element.gdb }}</td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="wartezeit_35_fehlend">
              <th mat-header-cell *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by wartezeit_35_fehlend">
                Fehlende WZ35
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.wartezeit_35_fehlend }}
              </td>
            </ng-container>

            <ng-container matColumnDef="wartezeit_45_fehlend">
              <th mat-header-cell *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by wartezeit_45_fehlend">
                Fehlende WZ45
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.wartezeit_45_fehlend | number: "1.0-0" : "de-DE" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="wz_bis">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by wz_bis">
                WZ Stand
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.wz_bis | date: "dd.MM.yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="ep">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ep">
                EP
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.ep | number: "1.4-4" : "de-DE" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="ep_bis">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ep_bis">
                EP bis
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.ep_bis | date: "dd.MM.yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="jahresentgelt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by jahresentgelt">
                Jahresentgelt
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.jahresentgelt | number: "1.2-2" : "de-DE" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="jahresnettoentgelt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by jahresnettoentgelt">
                Jahresnettoentgelt
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.jahresnettoentgelt | number: "1.2-2" : "de-DE" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="jahresnettoentgelt_alg">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by jahresnettoentgelt">
                Jahresnettoentgelt ALG
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.jahresnettoentgelt_alg | number: "1.2-2" : "de-DE" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="jahresnettoentgelt_fuer_phase_x">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by jahresnettoentgelt">
                Jahresnettoentgelt für Phase X
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                element.jahresnettoentgelt_fuer_phase_x
                | number: "1.2-2" : "de-DE"
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="jahresnettoentgelt_fuer_alg_nach_phase_x">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by jahresnettoentgelt">
                Jahresnettoentgelt für ALG1 nach Phase X
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                element.jahresnettoentgelt_fuer_alg_nach_phase_x
                | number: "1.2-2" : "de-DE"
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="jahresbruttoentgelt_fuer_phase_x">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by jahresbruttoentgelt">
                Jahresbruttoentgelt für Phase X
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                element.jahresbruttoentgelt_fuer_phase_x
                | number: "1.2-2" : "de-DE"
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="steuerklasse">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by steuerklasse">
                Steuerklasse
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.steuerklasse }}
              </td>
            </ng-container>
            <ng-container matColumnDef="betriebszugehoerigkeit">
              <th mat-header-cell *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by betriebszugehoerigkeit">
                Betriebszugehörigkeit
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.betriebszugehoerigkeit }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="userFields"></tr>
            <tr mat-row [class.row-selected]="
                detailedView && detailedView?.identifier == row?.identifier
              " (click)="toggleDetails(row)" *matRowDef="let row; columns: userFields"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </mat-tab>

    <mat-tab *ngIf="detailedView">
      <ng-template mat-tab-label>
        Detailansicht Benutzer {{ detailedView.identifier }}
      </ng-template>
      <mat-card *ngIf="detailedView && result">
        <mat-card-title (click)="toggleDetails(detailedView)">Detailansicht für {{ detailedView.identifier
          }}</mat-card-title>
        <mat-card-content>
          <app-vorruhestand-display [markerAge]="markerAge.value ?? 85"
            [betriebsaufloesung]="getBetriebsaufloesungDate()" [visible]="detailedView && result"
            [user]="detailedView"></app-vorruhestand-display>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab *ngIf="this.result" label="Gesamtübersicht" [style.height.vh]="100" (click)="triggerResize()">
      <app-plotly-scatter *ngIf="timelineBatch" [timelineBatch]="timelineBatch" [vergleichsweg]="selectedTimeline"
        [betriebsaufloesung]="getBetriebsaufloesungDate()" [consider_regular_pension_unreduced]="
          consider_regular_pension_unreduced?.value!
        ">
      </app-plotly-scatter>
    </mat-tab>

    <mat-tab *ngIf="this.result && selectedTimeline != 'Keine'" label="Nach Jahrgängen" [style.height.vh]="100"
      (click)="triggerResize()">
      <app-jahrgangs-uebersicht *ngIf="timelineBatch" [timelineBatch]="timelineBatch" [vergleichsweg]="selectedTimeline"
        [betriebsaufloesung]="getBetriebsaufloesungDate()" [consider_regular_pension_unreduced]="
          consider_regular_pension_unreduced?.value!
        ">
      </app-jahrgangs-uebersicht>
    </mat-tab>
    <mat-tab *ngIf="this.result && selectedTimeline != 'Keine'" label="Abfindungsabschätzungen..."
      [style.height.vh]="100" (click)="triggerResize()">
      <app-abfindung *ngIf="timelineBatch" [timelineBatch]="timelineBatch" [vergleichsweg]="selectedTimeline"
        [betriebsaufloesung]="getBetriebsaufloesungDate()" [consider_regular_pension_unreduced]="
          consider_regular_pension_unreduced?.value!
        ">
      </app-abfindung>
    </mat-tab>
    <mat-tab *ngIf="this.result && selectedTimeline != 'Keine'" label="Verluste (Gewinne)" [style.height.vh]="100"
      (click)="triggerResize()">
      <app-verluste *ngIf="timelineBatch" [timelineBatch]="timelineBatch" [vergleichsweg]="selectedTimeline"
        [betriebsaufloesung]="getBetriebsaufloesungDate()" [consider_regular_pension_unreduced]="
          consider_regular_pension_unreduced?.value!
        ">
      </app-verluste>
    </mat-tab>
    -->
    <!--
  
-->
  </mat-tab-group>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin" [fullScreen]="true">
    <p style="color: white">Berechnung läuft....</p>
  </ngx-spinner>

  <div class="berechnen-overlay">
    <button *ngIf="users().length > 0" type="button" mat-raised-button (click)="send()">
      Berechnen
    </button>
  </div>
</ng-container>