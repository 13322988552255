import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TimelineBatch } from '@classes/timeline-batch';
import { Timeline } from '@classes/timeline';
import { Slot } from '@classes/slot';
import { UserContext } from '@classes/user-context';
@Component({
  selector: 'app-plotly-scatter',
  templateUrl: './plotly-scatter.component.html',
  styleUrls: ['./plotly-scatter.component.scss'],
})
export class PlotlyScatterComponent implements OnInit, OnChanges {
  @Input('timelineBatch') timelineBatch: TimelineBatch;
  @Input('betriebsaufloesung') betriebsaufloesung: Date;
  @Input('vergleichsweg') vergleichsweg: string;
  @Input('consider_regular_pension_unreduced')
  consider_regular_pension_unreduced: boolean = false;
  get renten(): Array<string> {
    return [
      'Regelaltersrente',
      'Geminderte Rente',
      'Ungeminderte Rente',
      ...this.timelineBatch.getSpecialPensions(),
    ];
  }
  tableFields: Array<string> = [
    'name',
    'count',
    'percent',
    'before_deadline',
    'before_deadline_percent',
  ];
  vergleichtableFields: Array<string> = ['name', 'count', 'percent'];
  stats: Array<any> = [];

  constructor() {}
  monthCount: any = {};
  get slotStats(): Array<any> {
    let statsArr = new Array<any>();
    for (let key of Object.keys(this.monthCount)) {
      statsArr.push({ name: key, ...this.monthCount[key] });
    }
    return statsArr;
  }
  ngOnChanges(changes: SimpleChanges) {
    //this.graph.data.length = 0;
    //setTimeout( ()=>{
    if (changes['timelineBatch']) {
      this.createSeries();
      this.revision++;
    }

    if (
      changes['vergleichsweg'] ||
      changes['consider_regular_pension_unreduced']
    ) {
      this.createSeries();
      this.revision++;
    }
    //},50);
  }
  revision: number = 0;
  zeilenhoehe: UntypedFormControl = new UntypedFormControl(4);
  vergleichsTimeline: string = '';
  availableTimelines: Array<string> = new Array<string>();
  subscription_onload: any = undefined;
  getGraphHeight() {
    return Math.max(
      300,
      291 + this.timelineBatch.UserContexts().length * this.zeilenhoehe.value,
    );
  }
  ngOnInit(): void {
    this.subscription_onload = this.timelineBatch.onLoad().subscribe(() => {
      this.createSeries();
      this.revision++;
    });
    this.zeilenhoehe.valueChanges.subscribe((value: number) => {
      (this.graph.layout.height = this.getGraphHeight()), this.revision++;
    });
  }
  resetGraph() {
    this.graph = {
      data: [],
      layout: {
        autosize: true,
        title:
          'Rentenbeginne des Referenzweges (Punkte)\nund gewählte Vorruhestandsphasen (Linien)',
        barmode: 'stack',
        automargin: true,
        shapes: [],
        annotations: [],
        xaxis: {
          title: 'Zeitstrahl',
          type: 'date',
          showgrid: true,
          zeroline: false,
        },
        yaxis: {
          showgrid: false,
          showline: false,
          type: 'category',
          //rangemode:'nonnegative',
          nticks: 25,
          title: 'Personen-ID',
        },
      },
    };
  }
  public graph: any = {};
  getDateCoordinate(date: string | Date | undefined) {
    if (date instanceof Date) {
      date =
        date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    }
    if (date === undefined) {
      return 0;
    }

    return date.split('.').reverse().join('-') + ' 00:00:01';
  }
  getDate(date: string | Date | undefined) {
    return new Date(this.getDateCoordinate(date));
  }
  referenzwegStats: any = undefined;
  createSeries() {
    this.resetGraph();
    this.graph.data.length = 0;
    this._createSeries();
  }
  lengthAfterScatter = 4;
  _createSeries() {
    this.graph.data.length = 0;
    let rar: any = {
      name: 'Regelaltersrente',
      type: 'scatter',
      mode: 'markers',
      marker: { color: this.timelineBatch?.colors.regelaltersrente },
      x: [],
      y: [],
    };
    let gemindert: any = {
      name: 'Rente mit Abschlag',
      type: 'scatter',
      mode: 'markers',
      marker: { color: this.timelineBatch?.colors.geminderte },
      x: [],
      y: [],
    };
    let ungemindert: any = {
      name: 'Rente ohne Abschlag',
      type: 'scatter',
      mode: 'markers',
      marker: { color: this.timelineBatch?.colors.ungeminderte },
      x: [],
      y: [],
    };
    let specialPensions: any = {};

    let stats: any = {
      all: {
        count: 0,
        percent: 100,
        name: 'Irgendeine Rente',
        before_deadline: 0,
        before_deadline_percent: 0,
      },
      rar: {
        count: 0,
        percent: 0,
        name: 'Regelaltersrente',
        before_deadline: 0,
        before_deadline_percent: 0,
      },
      reduced: {
        count: 0,
        percent: 0,
        name: 'Rente mit Abschlag',
        before_deadline: 0,
        before_deadline_percent: 0,
      },
      unreduced: {
        count: 0,
        percent: 0,
        name: 'Rente ohne Abschlag',
        before_deadline: 0,
        before_deadline_percent: 0,
      },
    };
    for (let specialRente of this.timelineBatch.getSpecialPensions()) {
      specialPensions[specialRente] = {
        name: specialRente,
        type: 'scatter',
        mode: 'markers',
        marker: { color: this.timelineBatch?.getColor(specialRente) },
        x: [],
        y: [],
      };
      stats[specialRente] = {
        count: 0,
        percent: 0,
        name: specialRente,
        before_deadline: 0,
        before_deadline_percent: 0,
      };
    }
    let deadline = new Date(this.betriebsaufloesung.getTime());
    deadline.setDate(deadline.getDate() + 1);
    deadline.setHours(23);
    deadline.setMinutes(59);
    deadline.setSeconds(59);
    let users: Array<UserContext> = this.timelineBatch.UserContexts();
    if (users === undefined) {
      return;
    }

    stats.all.count = users.length;
    let counter = 0;
    for (let person of users) {
      counter++;
      let referenzWegRenten: Array<Slot> = person.getSlotsByFilterFunction(
        (slot: Slot) => {
          return (
            slot?.timeline?.name.toLowerCase().includes('referenzweg') &&
            slot.name.toLowerCase().includes('rente')
          );
        },
      );

      for (let rente of referenzWegRenten) {
        let yIdx: number | string = users.indexOf(person) + 1;
        yIdx = person.identifier;
        if (rente?.timeline?.name.endsWith('Regelaltersrente')) {
          stats.rar.count++;
          if (deadline > this.getDate(rente.start)) {
            stats.rar.before_deadline++;
          }
          rar.x.push(this.getDateCoordinate(rente.start));
          rar.y.push(yIdx);
        } else if (rente?.timeline?.name.endsWith('Geminderte Rente')) {
          stats.reduced.count++;
          gemindert.x.push(this.getDateCoordinate(rente.start));
          gemindert.y.push(yIdx);
          if (deadline > this.getDate(rente.start)) {
            stats.reduced.before_deadline++;
          }
        } else if (rente?.timeline?.name.endsWith('Ungeminderte Rente')) {
          stats.unreduced.count++;
          ungemindert.x.push(this.getDateCoordinate(rente.start));
          ungemindert.y.push(yIdx);
          if (deadline > this.getDate(rente.start)) {
            stats.unreduced.before_deadline++;
          }
        } else {
          for (let specialRente of this.timelineBatch.getSpecialPensions()) {
            if (rente?.timeline?.name.endsWith(specialRente)) {
              stats[specialRente].count++;
              specialPensions[specialRente].x.push(
                this.getDateCoordinate(rente.start),
              );
              specialPensions[specialRente].y.push(yIdx);
              if (deadline > this.getDate(rente.start)) {
                stats[specialRente].before_deadline++;
              }
            }
          }
        }
      }
    }
    let statsArr = new Array<any>();
    for (let key of Object.keys(stats)) {
      let entry = stats[key];
      entry.percent = Math.round(100 * (entry.count / stats.all.count));
      entry.before_deadline_percent = Math.round(
        100 * (entry.before_deadline / stats.all.count),
      );
      statsArr.push(entry);
    }

    this.referenzwegStats = statsArr;
    this.graph.layout.height = this.getGraphHeight();
    //this.graph.layout.yaxis.range = [0,users.length+1 ];
    this.graph.layout.yaxis.nticks = Math.round(users.length / 4);
    //this.graph.layout.yaxis.rangemode = 'nonnegative';
    this.graph.data.push(rar);
    this.graph.data.push(gemindert);
    this.graph.data.push(ungemindert);
    for (let specialRente of this.timelineBatch.getSpecialPensions()) {
      this.graph.data.push(specialPensions[specialRente]);
    }
    this.graph.layout.shapes.length = 0;
    this.graph.layout.annotations.length = 0;
    this.graph.layout.shapes.push({
      type: 'line',
      x0: this.getDateCoordinate(this.betriebsaufloesung),
      y0: 0,
      x1: this.getDateCoordinate(this.betriebsaufloesung),
      yref: 'paper',
      y1: 1,
      line: {
        color: 'grey',
        width: 1.5,
        dash: 'dot',
      },
    });
    this.graph.layout.annotations.push({
      type: 'line',
      x: this.getDateCoordinate(this.betriebsaufloesung),
      y: 0,
      yref: 'paper',
      yshift: -15,
      textangle: 0,
      showarrow: false,
      text: 'Betriebsauflösung',
      xanchor: 'center',
      yanchor: 'top',
    });
    this.lengthAfterScatter = this.graph.data.length;
    this.addVergleichsWeg();
    this.revision++;
  }

  countMonths(id: string, len: number): void {
    if (!Object.keys(this.monthCount).includes(id)) {
      this.monthCount[id] = {
        total: 0,
        min: 9999,
        max: -1,
        samples: 0,
      };
    }
    this.monthCount[id].samples++;
    this.monthCount[id].total += len;
    if (this.monthCount[id].min > len) {
      this.monthCount[id].min = len;
    }
    if (this.monthCount[id].max < len) {
      this.monthCount[id].max = len;
    }
  }
  vergleichswegDaten: Array<any> = new Array<any>();
  addVergleichsWeg() {
    this.monthCount = {};
    let stats: any = {
      total: this.timelineBatch.UserContexts().length,
      people: 0,
      missingMonths: 0,
      people_without_pension: 0,
      missingMoney: 0,
      people_without_gap: 0,
      people_with_gap: 0,
    };
    if (this.vergleichsweg === undefined) {
      return;
    }

    let vrObj: any = {};
    for (let person of this.timelineBatch.UserContexts()) {
      let timelines: Array<Timeline> = person.timelines.filter(
        (tl: Timeline) => {
          return tl.isTimeline(this.vergleichsweg);
        },
      );

      if (timelines.length == 0) {
        stats.people_without_pension++;
        continue;
      }

      let timeline = timelines.pop();

      if (timelines.length > 1) {
        console.warn(
          'This user (' +
            person.identifier +
            ') seems to have this timeline (' +
            timeline?.identifier +
            ') multiple times...',
        );
      }

      if (timeline === undefined) {
        stats.people_without_pension++;
        continue;
      }

      let vergleichsTimelineSlots: Array<Slot> = timeline.slots.filter(
        (slot: Slot) => !slot.name.toLowerCase().includes('rente'),
      );

      if (vergleichsTimelineSlots.length === 0) {
        stats.people_without_pension++;
      } else {
        stats.people++;
      }
      if (timeline.hasSlots(['Lücke', 'Joker'])) {
        stats.people_with_gap++;
      } else {
        stats.people_without_gap++;
      }
      for (let slot of vergleichsTimelineSlots) {
        if (slot?.name !== undefined) {
          if (vrObj[slot.name] === undefined) {
            vrObj[slot.name] = {
              name: slot.name,
              type: 'scatter',
              mode: 'lines',
              line: {
                width: 1,
              },
              x: [],
              y: [],
            };
          }
          let yIdx: string | number =
            this.timelineBatch.UserContexts().indexOf(person) + 1;
          yIdx = person.identifier;
          if (slot.durationMonths) {
            this.countMonths(slot.name, slot.durationMonths);
          }
          vrObj[slot.name].x.push(this.getDateCoordinate(slot.start));
          vrObj[slot.name].x.push(this.getDateCoordinate(slot.end));
          vrObj[slot.name].x.push(undefined);
          vrObj[slot.name].y.push(yIdx);
          vrObj[slot.name].y.push(yIdx);
          vrObj[slot.name].y.push(undefined);
          if (['Joker', 'Lücke'].includes(slot.name)) {
            if (slot.durationMonths != undefined) {
              stats.missingMonths += slot.durationMonths;
              stats.missingMoney +=
                (slot.durationMonths * person?.jahresentgelt) / 12;
            }
          }
        }
      }
    }
    for (let field of ['Joker', 'Lücke']) {
      if (vrObj[field]) {
        vrObj[field]['visible'] = 'legendonly';
      }
    }
    this.graph.data.length = 4;
    for (let vrs of Object.keys(vrObj)) {
      if (this.timelineBatch.colors[vrs] != undefined) {
        vrObj[vrs]['line'] = {
          color: this.timelineBatch.colors[vrs],
          width: 1,
        };
      }
      this.graph.data.push(vrObj[vrs]);
      //console.log(this.graph.data);
    }
    stats.people_without_pension_percent =
      100 * (stats.people_without_pension / stats.total);
    this.vergleichswegDaten = [];
    /*
        total:this.timelineBatch.UserContexts().length,
        people:0,
        missingMonths:0,
        people_without_pension:0,
        missingMoney:0,
        people_without_gap:0,
        people_with_gap:0
        */
    this.vergleichswegDaten.push({
      name: 'Personen insgesamt',
      count: stats.total,
      percent: 100,
    });
    //this.vergleichswegDaten.push({name:'Personen denen dieser Weg offen steht',count:stats.people,percent:100*(stats.people/stats.total)});
    this.vergleichswegDaten.push({
      name: '... ohne Lücken',
      count: stats.people_without_gap,
      percent: 100 * (stats.people_without_gap / stats.total),
    });
    this.vergleichswegDaten.push({
      name: '... mit Lücken',
      count: stats.people_with_gap,
      percent: 100 * (stats.people_with_gap / stats.total),
    });
    this.vergleichswegDaten.push({
      name: 'Insgesamt fehlende Monate mit Einkommen',
      count: stats.missingMonths,
      percent: '---',
    });
    this.vergleichswegDaten.push({
      name: 'Insgesamt fehlendes Einkommen durch Lücken*',
      count:
        stats.missingMoney.toLocaleString('de-DE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }) + ' €',
      percent: '---',
    });
    this.createPieChart();
  }
  timelineIdentifier = '';
  createPieChart(): any {
    this.pieChart.specialPensions = {};
    let specialValues: any = {};
    for (let specialPension of this.timelineBatch.getSpecialPensions()) {
      this.pieChart.specialPensions[specialPension] = {
        data: {},
        layout: {
          height: 400,
          width: 500,
        },
      };
      specialValues[specialPension] = {
        keine: 0,
        rar: 0,
        gemindert: 0,
        ungemindert: 0,
        timeline: undefined,
      };
    }
    this.piechart_renten_beruecksichtigte_wege.length = 0;
    this.timelineIdentifier = this.getTimelineIdentifier();

    let labels = [...this.renten, 'Keine'];
    let values: Array<number> = new Array<number>();
    let allUsers = this.timelineBatch.UserContexts();
    let allUserCount = allUsers.length;
    let users: any = {
      all: allUserCount,
      rar: 0,
      ungemindert: 0,
      gemindert: 0,
      keine: 0,
    };
    for (let user of allUsers) {
      let best = user.getBestPensionFromTimeline(this.vergleichsweg);

      if (best != undefined) {
        for (let specialPension of this.timelineBatch.getSpecialPensions()) {
          let sp = best.getNeighborTimeline(specialPension);
          if (sp === undefined || sp.hasSlots(['Joker', 'Lücke'])) {
            specialValues[specialPension].keine++;
          } else if (sp.regular_pension) {
            specialValues[specialPension].rar++;
          } else if (sp.reduced === true) {
            specialValues[specialPension].gemindert++;
          } else if (sp.reduced === false) {
            specialValues[specialPension].ungemindert++;
          }
        }

        let bestName = best?.getCondensedName();

        if (!this.piechart_renten_beruecksichtigte_wege.includes(bestName)) {
          this.piechart_renten_beruecksichtigte_wege.push(bestName);
        }
      }
      if (best === undefined) {
        users.keine++;
      } else if (best.regular_pension) {
        users.rar++;
      } else if (best.reduced === true) {
        users.gemindert++;
      } else if (best.reduced === false) {
        users.ungemindert++;
      }
    }
    let colors = [
      this.timelineBatch.colors.regelaltersrente,
      this.timelineBatch.colors.ungeminderte,
      this.timelineBatch.colors.geminderte,
      this.timelineBatch.colors.keine,
    ];

    for (let specialPension of this.timelineBatch.getSpecialPensions()) {
      this.pieChart.specialPensions[specialPension].data = [
        {
          values: [
            specialValues[specialPension].rar,
            specialValues[specialPension].ungemindert,
            specialValues[specialPension].gemindert,
            specialValues[specialPension].keine,
          ],
          labels: [
            'Regelaltersrente',
            'Rente ohne Abschlag',
            'Rente mit Abschlag',
            'Keine Rente',
          ],
          type: 'pie',
          sort: false,
          textinfo: 'value+percent+name',
          marker: {
            colors: colors,
          },
        },
      ];
    }
    this.pieChart.renten.data = [
      {
        values: [users.rar, users.ungemindert, users.gemindert, users.keine],
        labels: [
          'Regelaltersrente',
          'Rente ohne Abschlag',
          'Rente mit Abschlag',
          'Keine Rente',
        ],
        type: 'pie',
        sort: false,
        textinfo: 'value+percent+name',
        marker: {
          colors: colors,
        },
      },
    ];
  }
  pieChart: any = {
    renten: {
      data: {},
      layout: {
        height: 400,
        width: 500,
      },
    },
    specialPensions: {},
  };
  piechart_renten_beruecksichtigte_wege: Array<string> = new Array<string>();
  getTimelineIdentifier(): string {
    return this.timelineBatch.getTimelineIdentifier(this.vergleichsweg);
  }
}
