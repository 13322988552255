import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { TimelineBatch } from '@classes/timeline-batch';
import { UserContext } from '@classes/user-context';
import { Timeline } from '@classes/timeline';
import { FunctionCompiler } from '@classes/function-compiler';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-verluste',
  templateUrl: './verluste.component.html',
  styleUrls: ['./verluste.component.scss'],
})
export class VerlusteComponent implements OnInit {
  @Input('timelineBatch') timelineBatch: TimelineBatch;
  @Input('betriebsaufloesung') betriebsaufloesung: Date;
  @Input('vergleichsweg') vergleichsweg: string;

  @Input('consider_regular_pension_unreduced')
  consider_regular_pension_unreduced: boolean = false;
  abfindungBeruecksichtigen = new UntypedFormControl(false);
  abfindungsFaktor = new UntypedFormControl(0.6);

  getAbfindungsCompiler(): FunctionCompiler | undefined {
    let compiler = this.timelineBatch.getCompiler(this.vergleichsweg);
    if (compiler && compiler.functoids.filter((x) => x.enabled).length > 0) {
      return compiler;
    }
    return undefined;
  }

  rentenEnabled: any = {};

  populateEnablingCheckboxes(): void {
    let incomplete = false;
    for (let rente of this.renten) {
      if (!Object.keys(this.rentenEnabled).includes(rente)) {
        incomplete = true;
      }
    }
    if (!incomplete) {
      return;
    }
    let rentenEnabled: any = {};
    for (let rente of this.renten) {
      rentenEnabled[rente] = false;
    }
    rentenEnabled['Regelaltersrente'] = true;
    this.rentenEnabled = rentenEnabled;
  }
  revision: number = 0;
  get renten(): Array<string> {
    let renten = ['Regelaltersrente', 'Geminderte Rente', 'Ungeminderte Rente'];
    return [...renten, ...this.getSpecialPensions()];
  }
  rentenWithout(str: string): Array<string> {
    return this.renten.filter((x) => x != str);
  }
  tableFields: Array<string> = [];

  public graphs: any = {
    data: {
      userWise: {},
      birthYearWise: {},
      birthYearWiseBoxplot: {},
    },
    layout: {
      userWise: {
        autosize: true,
        automargin: true,
        shapes: [],
        annotations: [],
        xaxis: {
          title: {
            text: 'Person',
            xanchor: 'center',
            yanchor: 'bottom',
          },
          type: 'category',
          showgrid: true,
          zeroline: false,
        },
        yaxis: {
          showgrid: false,
          showline: true,
          title: 'Verluste / Gewinne (€)',
        },
      },
      birthYearWiseBoxplot: {
        autosize: true,
        automargin: true,
        shapes: [],
        annotations: [],
        xaxis: {
          title: {
            text: 'Jahrgang',
            xanchor: 'center',
            yanchor: 'bottom',
          },
          type: 'category',
          showgrid: true,
          zeroline: false,
        },
        yaxis: {
          showgrid: false,
          showline: true,
          title: 'Verluste / Gewinne (€)',
        },
      },
      birthYearWise: {
        autosize: true,
        automargin: true,
        shapes: [],
        annotations: [],
        xaxis: {
          title: {
            text: 'Jahrgang',
            xanchor: 'center',
            yanchor: 'bottom',
          },
          type: 'category',
          showgrid: true,
          zeroline: false,
        },
        yaxis: {
          showgrid: false,
          showline: true,
          title: 'Verluste / Gewinne (€)',
        },
      },
    },
  };
  renderChartsBirthYearWise() {
    let renten: any = {};
    let abfF = this.abfindungsFaktor.value;
    for (let rente of this.renten) {
      renten[rente] = new Array<number | undefined | null>();
    }
    let years = this.timelineBatch.getUserBirthYears().sort();

    for (let year of years) {
      let yearSum: any = {};
      for (let user of this.timelineBatch.getUsersBornIn(year)) {
        let vergleichsweg = user.getTimelineByCondensedName(this.vergleichsweg);

        if (vergleichsweg === undefined) {
          continue;
        }

        let refWayMoney =
          vergleichsweg?.getMarker('AgeMarker')?.nettoeinkuenfte;
        if (refWayMoney === undefined) {
          refWayMoney = 0;
        }
        if (this.mitAbfindung()) {
          let abfindung = vergleichsweg.getAbfindung();
          if (abfindung != undefined) {
            refWayMoney += abfindung * abfF;
          }
        }

        let ways: any = {};
        let money: any = {};

        for (let rente of this.renten) {
          ways[rente] = user.getTimelineByCondensedName(
            'Referenzweg: Arbeit -> ' + rente,
          );
          if (yearSum[rente] === undefined) {
            yearSum[rente] = 0;
          }
          if (ways[rente]) {
            money[rente] = ways[rente].getMarker('AgeMarker')?.nettoeinkuenfte;
            yearSum[rente] += Math.round(money[rente] - refWayMoney);
          }
        }
      }
      for (let rente of this.renten) {
        renten[rente].push(yearSum[rente]);
      }
    }
    for (let rente of this.renten) {
      this.graphs.data.birthYearWise[rente] = [
        {
          x: years,
          type: 'bar',
          y: renten[rente].map((x: number | undefined | null) =>
            x ? x * -1 : null,
          ),
          textposition: 'auto',
          marker: {
            color: this.getColor(rente),
          },
          name:
            'Vergleich mit dem Referenzweg in die ' + rente + ' nach Jahrgang',
        },
      ];
    }
    console.log('birthYearWise:');
    console.log(this.graphs.data.birthYearWise);
  }

  renderChartsBirthYearWiseBoxplot() {
    let abfF = this.abfindungsFaktor.value;
    let userIdx = 0;
    let users = new Array<string>();
    let years = this.timelineBatch.getUserBirthYears().sort();
    let renten: any = {};
    for (let rente of this.renten) {
      renten[rente] = new Array<any>();
      if (this.graphs.data.birthYearWiseBoxplot[rente]) {
        this.graphs.data.birthYearWiseBoxplot[rente].length = 0;
      } else {
        this.graphs.data.birthYearWiseBoxplot[rente] = new Array<any>();
      }
    }

    for (let year of years) {
      let userCount: any = {};
      for (let rente of this.renten) {
        userCount[rente] = new Array<number>();
      }
      for (let user of this.timelineBatch.getUsersBornIn(year)) {
        users.push(user.identifier);
        let vergleichsweg = user.getTimelineByCondensedName(this.vergleichsweg);
        let condensedNames = this.renten.map(
          (x: string) => 'Referenzweg: Arbeit -> ' + x,
        );
        let ways: any = {};
        let money: any = {};
        for (let rente of this.renten) {
          ways[rente] = user.getTimelineByCondensedName(
            'Referenzweg: Arbeit -> ' + rente,
          );
          money[rente] = 0;
        }
        if (vergleichsweg === undefined) {
          continue;
        }

        let refWayMoney =
          vergleichsweg?.getMarker('AgeMarker')?.nettoeinkuenfte;

        if (refWayMoney === undefined) {
          refWayMoney = 0;
          continue;
        }
        if (this.mitAbfindung()) {
          let abfindung = vergleichsweg.getAbfindung();
          if (abfindung != undefined) {
            refWayMoney += abfindung ** abfF;
          }
        }
        for (let rente of this.renten) {
          if (ways[rente]) {
            money[rente] = ways[rente].getMarker('AgeMarker')?.nettoeinkuenfte;
            if (money[rente] === undefined) {
              money[rente] = 0;
            } else {
              userCount[rente].push(money[rente] - refWayMoney);
            }
          }
        }
      }

      for (let rente of this.renten) {
        this.graphs.data.birthYearWiseBoxplot[rente].push({
          y: userCount[rente].map((x: number) => (x ? x * -1 : null)),
          type: 'box',
          whiskerwidth: 0.2,
          boxpoints: 'all',
          jitter: 0.2,
          name: 'Jahrgang ' + year,
        });
      }
    }
    console.log('birthYearWiseBoxplot:');
    console.log(this.graphs.data.birthYearWiseBoxplot);
  }

  getColumnIdentifier(specialRente: string): string {
    return specialRente + 'WayMoney';
  }

  renderChartsUserWise() {
    let renten: any = {};
    let abfF = this.abfindungsFaktor.value;
    for (let rente of this.renten) {
      renten[rente] = new Array<number | undefined | null>();
    }
    let users = new Array<string>();
    for (let user of this.timelineBatch.getUsersSortedByDate()) {
      users.push(user.identifier);
      let vergleichsweg = user.getTimelineByCondensedName(this.vergleichsweg);
      let ways: any = {};
      let data: any = {};
      for (let rente of this.renten) {
        ways[rente] = user.getTimelineByCondensedName(
          'Referenzweg: Arbeit -> ' + rente,
        );
        if (vergleichsweg === undefined) {
          renten[rente].push(null);
        }
      }

      if (vergleichsweg === undefined) {
        continue;
      }

      let refWayMoney = vergleichsweg?.getMarker('AgeMarker')?.nettoeinkuenfte;
      if (refWayMoney === undefined) {
        refWayMoney = 0;
      }
      if (this.mitAbfindung()) {
        let abfindung = vergleichsweg.getAbfindung();
        if (abfindung != undefined) {
          refWayMoney += abfindung * abfF;
        }
      }
      for (let rente of this.renten) {
        if (ways[rente] !== undefined) {
          let money = ways[rente].getMarker('AgeMarker')?.nettoeinkuenfte;
          if (money === undefined) {
            renten[rente].push(null);
          } else {
            renten[rente].push(Math.round(money - refWayMoney));
          }
        } else {
          renten[rente].push(null);
        }
      }
    }
    for (let rente of this.renten) {
      this.graphs.data.userWise[rente] = [
        {
          x: users,
          y: renten[rente].map((x: number | undefined | null) =>
            x ? x * -1 : null,
          ),
          type: 'bar',
          //text: y_rar.map( (x,idx,arr)=>x.toFixed(1).replace('.',',')+'%<br>('+y_rar_abs[idx]+' P.)'),
          textposition: 'auto',
          marker: {
            color: this.getColor(rente),
          },
          name: 'Vergleich mit dem Referenzweg in die ' + rente,
        },
      ];
    }
    console.log('UserWise:');
    console.log(this.graphs.data.userWise);
  }
  getSpecialPensions(): Array<string> {
    return this.timelineBatch.getSpecialPensions();
  }
  getColor(rente: string): string {
    return this.timelineBatch.getColor(rente);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['timelineBatch'] ||
      changes['vergleichsweg'] ||
      changes['consider_regular_pension_unreduced']
    ) {
      this.redraw();
    }
  }

  generateTableFields() {
    this.tableFields = ['identifier', 'currentWayMoney'];
    this.tableFields.push(...this.renten.map((x: string) => x + 'WayMoney'));
    this.tableFields.push('date');
  }
  dataRendered(): boolean {
    return this._renderState == 'done';
  }
  _renderState: string = 'unknown';
  redraw() {
    this._renderState = 'rendering';
    this._tableData = new Array<any>();
    this.generateTableFields();
    this.renderChartsUserWise();
    this.renderChartsBirthYearWise();
    this.renderChartsBirthYearWiseBoxplot();
    this.generateTableData();
    this.populateEnablingCheckboxes();
    this._renderState = 'done';
  }

  _tableData: Array<any> = new Array<any>();
  tableData(): Array<any> {
    return this._tableData;
  }
  generateTableData() {
    let abfF = this.abfindungsFaktor.value;
    this._tableData.length = 0;
    if (this._tableData.length == 0) {
      let sumObj: any = {
        identifier: 'Gesamt',
        currentWayMoney: 0,
        abfindung: 0,
        date: null,
      };

      for (let rente of this.renten) {
        sumObj[rente + 'WayMoney'] = 0;
      }
      this._tableData = this.timelineBatch
        .UserContexts()
        .map((user: UserContext) => {
          let vergleichsWegTimeline = user
            .getTimelineByCondensedName(this.vergleichsweg);
          let marker = vergleichsWegTimeline?.getMarker('AgeMarker');
          if (marker === undefined) {
            console.log(`Age-Marker not found for user ${user.identifier} on ${this.vergleichsweg}`)
          } else {
            console.log({ user: user, marker: marker });
          }
          let referenzWege: Array<Timeline> =
            user.getTimelinesByPartialTemplate({ identifier: 'Referenzweg: Arbeit' });
          let obj: any = {
            identifier: user.identifier,
            date: marker?.date,
            currentWayMoney: marker?.nettoeinkuenfte,
          };
          for (let rente of this.renten) {
            let way = referenzWege.filter((x: Timeline) => {
              return x.getCondensedName() == 'Referenzweg: Arbeit -> ' + rente;
            });
            obj[rente + 'WayMoney'] =
              way[0]?.getMarker('AgeMarker')?.nettoeinkuenfte;
            if (obj[rente + 'WayMoney']) {
              sumObj[rente + 'WayMoney'] += obj[rente + 'WayMoney'];
            }
          }

          let abfindung = vergleichsWegTimeline
            ?.getAbfindung();
          if (user.getTimelineByCondensedName(this.vergleichsweg) === undefined) {
            console.warn(`Could not find way ${this.vergleichsweg} for user ${user.identifier}`);
          }
          if (marker?.nettoeinkuenfte) {
            sumObj.currentWayMoney += marker?.nettoeinkuenfte;
            if (this.mitAbfindung() && abfindung != undefined) {
              sumObj.currentWayMoney += abfindung * abfF;
              obj.currentWayMoney += abfindung * abfF;
            }
          }
          if (abfindung && this.mitAbfindung()) {
            if (abfindung != undefined) {
              sumObj.abfindung += abfindung * abfF;
            }
          }

          if (this.mitAbfindung()) {
            if (abfindung != undefined) {
              obj['abfindung'] = abfindung * abfF;
            }
          }
          return obj;
        });

      this._tableData.push(sumObj);
    }
    console.log("Tabledata: ");
    console.log(this._tableData);
    return this._tableData;
  }

  constructor() { }
  abfindungVerfuegbar() {
    return this.getAbfindungsCompiler() !== undefined;
  }
  mitAbfindung(): boolean {
    return (
      this.abfindungVerfuegbar() && this.abfindungBeruecksichtigen.value == true
    );
  }
  _redrawTimeout: any = undefined;
  redrawTimeout(time: number): void {
    if (this._redrawTimeout) {
      clearTimeout(this._redrawTimeout);
      this._redrawTimeout = undefined;
    }
    this._redrawTimeout = setTimeout(() => this.redraw(), time);
  }
  ngOnInit(): void {
    this.abfindungBeruecksichtigen.valueChanges.subscribe((b: boolean) => {
      this.redraw();
    });
    this.abfindungsFaktor.valueChanges.subscribe((n: number) => {
      if (this.mitAbfindung()) {
        this.redrawTimeout(300);
      }
    });
  }
}
