<ng-container>
  <mat-card>
    <mat-card-title (click)="show = !show"
      >Benutzer Hinzufügen/Editieren (click)</mat-card-title
    >
    <mat-card-content *ngIf="show">
      <div *ngIf="editUser !== undefined">
        Benutzer editieren
        <app-userform [user]="newUser"></app-userform>
        <button matButton (click)="saveChanges()">Änderungen Speichern</button>
      </div>
      <div *ngIf="editUser === undefined">
        Benutzer hinzufügen:
        <app-userform [user]="newUser"></app-userform>
        <button matButton (click)="saveChanges()">Hinzufügen</button>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
