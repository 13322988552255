import { Injectable } from '@angular/core';
import { Timeline } from '@classes/timeline';
import { UserContext } from '@classes/user-context';

@Injectable({
  providedIn: 'root'
})
export class AbschlagsAusgleichService {

  constructor() { }

  beitragssatz: number = 0.186;
  durchschnittsEntgelt: number = 45358;
  rentenwert: number = 39.32;
  getRentenWert(): number {
    return this.rentenwert;
  }
  getBeitragssatz(): number {
    return this.beitragssatz;
  }

  getDurchschnittsentgelt(): number {
    return this.durchschnittsEntgelt;
  }

  earliestPensionTimeline(user: UserContext): Timeline | undefined {
    let referenzWege = user.timelines.filter((x: Timeline) => x.name.startsWith('Referenzweg'));
    let sorted = referenzWege.sort(
      (a: Timeline, b: Timeline) => {
        let bTime = b.getPension()?.start?.getTime() ?? 0;
        let aTime = a.getPension()?.start?.getTime() ?? 0;
        return aTime - bTime;
      }
    );
    if (sorted.length > 0) {
      return sorted[0];
    }
    return undefined;
  }

  costPerEp(): number {
    return (this.getDurchschnittsentgelt() * this.getBeitragssatz());
  }

  maxCost(user: UserContext | undefined): number {
    if (user === undefined) {
      return -1;
    }
    return this.costPerEp() * this.buyableEP(user);
  }

  buyableEP(user: UserContext | undefined): number {

    if (user === undefined) {
      return -1;
    }
    let meta: any = user.metadata.get('buyable_ep');
    if (![undefined, null, ''].includes(meta)) {
      return parseFloat(meta);
    } else {
      console.log({ msg: "Dismissing user-provided buyable_ep", val: parseFloat(meta), original: user.metadata.get('buyable_ep') });
    }
    let timeline = this.earliestPensionTimeline(user);
    let pension = timeline?.getPension();
    let abschlag = pension?.abschläge;
    let ep = pension?.startEp;

    return (ep! * abschlag!) / (1 - abschlag!);
  }

}
