import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { UserContext } from '@classes/user-context';
import { TimelineBatch } from '@classes/timeline-batch';
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit, OnChanges, AfterViewInit {
  @Input('user') editUser?: UserContext = undefined;
  @Input('batch') batch?: TimelineBatch = undefined;
  @Output('onChange') onChange: EventEmitter<UserContext> =
    new EventEmitter<UserContext>();
  show: boolean = false;
  newUser?: UserContext = undefined;
  constructor() {
    this.lastDayOfLastYear.setFullYear(
      this.lastDayOfLastYear.getFullYear() - 1,
    );
    this.lastDayOfLastYear.setDate(31);
    this.lastDayOfLastYear.setMonth(11);
    this.lastDayOfLastYear.setHours(23);
    this.lastDayOfLastYear.setMinutes(59);
    this.lastDayOfLastYear.setSeconds(59);
  }

  getMode(): string {
    if (this.editUser === undefined) {
      return 'create';
    } else {
      return 'edit';
    }
  }

  defaultBirthDay = new Date('01.01.1960');
  lastDayOfLastYear = new Date();

  ngAfterViewInit() {
    this.setUser();
  }
  setUser() {
    if (this.editUser === undefined) {
      this.newUser = this.createNewUser();
    }

    if (this.editUser !== undefined) {
      this.newUser = new UserContext(this.editUser.toJSON());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setUser();
  }
  saveChanges() {
    if (this.newUser !== undefined && this.editUser !== undefined) {
      this.editUser.fromJSON(this.newUser.toJSON());
      this.onChange.emit(this.editUser);
    }
    if (this.newUser !== undefined && this.editUser === undefined) {
      if (this.batch) {
        this.batch.addUserContext(this.newUser, true);
        this.newUser = this.createNewUser();
        this.batch.setAdditionalBzMonths(
          this.batch.additionalBetriebszugehoerigkeitsMonths,
        );
        this.onChange.emit(this.newUser);
      }
    }
  }
  createNewUser(): UserContext {
    return new UserContext({
      identifier: 'Neuer Benutzer',
      date_of_birth: '01.01.1960',
      gdb: 0,
      wartezeit_5_fehlend: 0,
      wartezeit_35_fehlend: 0,
      wartezeit_45_fehlend: 0,
      ep: 0,
      ep_bis: this.serializeDate(this.lastDayOfLastYear),
      wz_bis: this.serializeDate(this.lastDayOfLastYear),
      jahresentgelt: 36000,
      jahresnettoentgelt: 36000 * 0.6,
      jahresnettoentgelt_alg: 36000 * 0.6,
      betriebszugehoerigkeit: 30 * 12,
      steuerklasse: 3,
      sozialversicherung: {
        krankenkasse: 'gesetzlich',
        kinder_unter_23: 1,
        zusatzbeitrag_kv: 1.6,
        zusatzbeitrag_pv: 3.05,
      },
    });
  }
  ngOnInit(): void {}
  serializeDate(date: Date): string {
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  parseDate(date: any): Date {
    if (date instanceof Date) {
      return date;
    }

    return new Date(date.split('.').reverse().join('-') + ' 00:00:01');
  }
}
