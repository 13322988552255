<ng-container *ngIf="dataRendered()">
  <mat-card>
    <mat-card-title> Einstellungen </mat-card-title>
    <mat-card-content>
      <div *ngFor="let rente of renten">
        <mat-label>{{ rente }}</mat-label
        ><br />
        <mat-checkbox
          class="example-margin"
          [checked]="rentenEnabled[rente]"
          (change)="rentenEnabled[rente] = !rentenEnabled[rente]"
        >
          Eingeschaltet
        </mat-checkbox>
      </div>
      <span *ngIf="!getAbfindungsCompiler()"
        >Eine Abfindungsformel für diesen Vergleichsweg wurde noch nicht
        angelegt oder sie ist leer.</span
      >
      <mat-checkbox
        *ngIf="getAbfindungsCompiler()"
        [formControl]="abfindungBeruecksichtigen"
      >
        Arbeitnehmer-Abfindung berücksichtigen, Formel:
        <small>{{ getAbfindungsCompiler()!.getFunctionString() }}</small>
      </mat-checkbox>
      <br />
      <mat-form-field *ngIf="getAbfindungsCompiler()">
        <br />
        <mat-label>Abfindungsfaktor</mat-label>
        <input
          matInput
          value="0.6"
          [formControl]="abfindungsFaktor"
          type="number"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card *ngFor="let rente of renten">
    <mat-card-title *ngIf="rentenEnabled[rente]"
      >Vergleich mit dem Referenzweg in die {{ rente }}</mat-card-title
    >
    <mat-card-content *ngIf="rentenEnabled[rente]">
      <plotly-plot
        [data]="graphs.data.userWise[rente]"
        [layout]="graphs.layout.userWise"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '98vw'
        }"
        [useResizeHandler]="true"
        [divId]="'unreducedVerluste'"
        [revision]="revision"
      >
      </plotly-plot>
    </mat-card-content>
  </mat-card>

  <mat-card *ngFor="let rente of renten">
    <mat-card-title *ngIf="rentenEnabled[rente]">
      Vergleich mit dem Referenzweg in die {{ rente }} nach Jahrgang
    </mat-card-title>
    <mat-card-content *ngIf="rentenEnabled[rente]">
      <plotly-plot
        [data]="graphs.data.birthYearWise[rente]"
        [layout]="graphs.layout.birthYearWise"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '98vw'
        }"
        [useResizeHandler]="true"
        [divId]="'VerlusteJahrgangsweise' + rente"
        [revision]="revision"
      >
      </plotly-plot>
    </mat-card-content>
  </mat-card>

  <mat-card *ngFor="let rente of renten">
    <mat-card-title *ngIf="rentenEnabled[rente]">
      Vergleich mit dem Referenzweg in die {{ rente }} nach Jahrgang
    </mat-card-title>
    <mat-card-content *ngIf="rentenEnabled[rente]">
      <plotly-plot
        [data]="graphs.data.birthYearWiseBoxplot[rente]"
        [layout]="graphs.layout.birthYearWiseBoxplot"
        [updateOnDataChange]="true"
        [style]="{
          position: 'relative',
          display: 'inline-block',
          width: '98vw'
        }"
        [useResizeHandler]="true"
        [divId]="'unreducedVerlusteJahrgangsweiseBoxPlot' + rente"
        [revision]="revision"
      >
      </plotly-plot>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Datentabelle</mat-card-title>
    <mat-card-content>
      <table mat-table [dataSource]="tableData()">
        <!-- Position Column -->
        <ng-container matColumnDef="identifier">
          <th mat-header-cell *matHeaderCellDef>Identfier</th>
          <td class="tac" mat-cell *matCellDef="let element">
            {{ element.identifier }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="currentWayMoney">
          <th mat-header-cell *matHeaderCellDef>Betrachteter Weg</th>
          <td class="tac" mat-cell *matCellDef="let element">
            {{ element.currentWayMoney | number: "1.2-2" : "de-DE" }}€
            <br />
            <small *ngIf="mitAbfindung()">
              (inkl. {{ element.abfindung | number: "1.2-2" : "de-DE" }}€
              Abfindung)
            </small>
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container
          *ngFor="let rente of renten"
          [matColumnDef]="getColumnIdentifier(rente)"
        >
          <th mat-header-cell *matHeaderCellDef>
            <span [class]="{ bold: rentenEnabled[rente] }"
              >Referenzweg in die {{ rente }}
            </span>
          </th>
          <td class="tac" mat-cell *matCellDef="let element">
            {{
              element[getColumnIdentifier(rente)] | number: "1.2-2" : "de-DE"
            }}€<br />
            <small
              [ngClass]="{
                gain:
                  element[getColumnIdentifier(rente)] -
                    element.currentWayMoney <
                  0
              }"
            >
              {{
                -1 *
                  (element[getColumnIdentifier(rente)] -
                    element["currentWayMoney"]) | number: "1.2-2" : "de-DE"
              }}€
            </small>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Datum des Zeitpunktes</th>
          <td class="tac" mat-cell *matCellDef="let element">
            {{ element.date }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableFields"></tr>
        <tr mat-row *matRowDef="let row; columns: tableFields"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</ng-container>
