<div>
  <mat-card>
    <mat-card-title><small>PDF-Erzeugung</small></mat-card-title>

    <div>
      <button mat-raised-button color="primary" (click)="render()">
        PDF erzeugen
      </button>
      <button mat-raised-button color="primary" (click)="renderTeaser()">
        Teaser erzeugen
      </button>
    </div>

    <mat-card-content>
      <mat-tab-group [(selectedIndex)]="selectedTabIndex" [style.margin-top-em]="2" dynamicHeight #pdfTabGroup>
        <mat-tab label="Nicht-erfasste Daten">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="name" />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>Kaufbare EP</mat-label>
            <input matInput type="number" [formControl]="kaufbare_ep" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Kaufbare Text</mat-label>
            <input matInput type="text" [formControl]="kaufbare_ep_text" />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>Bundesland</mat-label>
            <mat-select [formControl]="bundesland" panelClass="timelineSelectPanel">
              <mat-optgroup label="West">
                <mat-option style="width: 30em" *ngFor="let bl of bundeslaender.west" [value]="bl">
                  {{ bl }}
                </mat-option>
              </mat-optgroup>
              <mat-optgroup label="Ost">
                <mat-option style="width: 30em" *ngFor="let bl of bundeslaender.ost" [value]="bl">
                  {{ bl }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <!--<input matInput type="text" [formControl]="Bundesland">-->
          </mat-form-field>
        </mat-tab>

        <mat-tab label="Angezeigte Wege">
          <div>
            <mat-form-field>
              <mat-label>Referenzweg:</mat-label>
              <mat-select [(ngModel)]="refTl">
                <mat-option *ngFor="let tl of availableTimelines()" [value]="tl" [attr.selected]="tl == this.refTl"
                  (onSelectionChange)="setRef(tl, $event)">
                  {{ tl.getCondensedName(true) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngFor="let tl of availableTimelines()">
            <div>
              <mat-checkbox [disabled]="refTl == tl ? true : null" [class.mat-checkbox-disabled]="refTl == tl"
                class="pdf_cb" [checked]="refTl == tl || timelineSelected(tl)" (change)="toggleTimeline(tl)">
                {{ tl.getCondensedName(true) }}
              </mat-checkbox>
            </div>
            <br />
          </div>
        </mat-tab>
        <mat-tab label="Einstellungen">
          <mat-checkbox class="pdf_cb" [checked]="steuerpflichtiges_jahresbrutto"
            (change)="steuerpflichtiges_jahresbrutto = !steuerpflichtiges_jahresbrutto">
            Steuerpflichtiges (statt Rentenversicherungspflichtes) Jahresbrutto unter "Persönliche Daten"
          </mat-checkbox>
          <div style="border-bottom: 1px solid #ccc">
            <mat-checkbox class="pdf_cb" [checked]="include_zero_income_bars"
              (change)="include_zero_income_bars = !include_zero_income_bars; saveSettings()">
              Leeres Einkommen in Balkendiagramm darstellen<br />
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="alternate_markers"
              (change)="alternate_markers = !alternate_markers; saveSettings()">
              Alternative Darstellung von Markierungen<br />
              (zweizeilige Markierung zur Vermeidung von Überlappungen)
            </mat-checkbox>
          </div>
          <div style="border-bottom: 1px solid #ccc">
            <mat-checkbox class="pdf_cb" [checked]="limit_earliest_now" (change)="
                limit_earliest_now = !limit_earliest_now; saveSettings()
              ">
              <mat-label style="line-height: 1em">
                Balken-Grafiken <i>heute</i> Anfangen statt <br />zum frühesten
                von <i>wz_bis</i> und
                <i>ep_bis</i>
              </mat-label>
            </mat-checkbox>
          </div>
          <div style="border-bottom: 1px solid #ccc">
            <mat-checkbox class="pdf_cb" [checked]="always_show_exit_marker" (change)="
                always_show_exit_marker = !always_show_exit_marker;
                saveSettings()
              ">
              Austritts auch markieren<br />
              wenn sie Zeitgleich mit der Rente sind
            </mat-checkbox>
          </div>
          <div style="border-bottom: 1px solid #ccc">
            <mat-checkbox class="pdf_cb" [checked]="custom_colors_on_pensions" (change)="
                custom_colors_on_pensions = !custom_colors_on_pensions;
                saveSettings()
              ">
              Im Detaildiagramm die Renten mit den spezifischen <br />
              Farben versehen statt die der Regelaltersrente
            </mat-checkbox>
          </div>
          <div style="border-bottom: 1px solid #ccc">
            <mat-checkbox class="pdf_cb" [checked]="show_pension_income_in_overview_graph" (change)="
                show_pension_income_in_overview_graph =
                  !show_pension_income_in_overview_graph;
                saveSettings()
              ">
              Rentenhöhe in Übersichtsbalken
            </mat-checkbox>
          </div>
          <div style="border-bottom: 1px solid #ccc">
            <mat-checkbox class="pdf_cb" [checked]="brochure" (change)="brochure = !brochure; saveSettings()">
              Broschüre
            </mat-checkbox>
          </div>
          <div style="border-bottom: 1px solid #ccc">
            <mat-checkbox class="pdf_cb" [checked]="pad_anyway" (change)="pad_anyway = !pad_anyway; saveSettings()">
              Nur falls Broschüre:<br />
              In jedem fall Notizseiten anhängen
            </mat-checkbox>
          </div>
        </mat-tab>

        <mat-tab label="Seiten">
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.title" (change)="pages.title = !pages.title">
              Titelseite
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.personal_data"
              (change)="pages.personal_data = !pages.personal_data">
              Persönliche Daten
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.einzahlungen"
              (change)="pages.einzahlungen = !pages.einzahlungen">
              Kosten für Ausgleich von Abschlägen
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.toc" (change)="pages.toc = !pages.toc">
              Inhalt
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.overview" (change)="pages.overview = !pages.overview">
              Überblick
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.abfindung_vallourec"
              (change)="pages.abfindung_vallourec = !pages.abfindung_vallourec">
              Abfindung VAD
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.abfindung_fls"
              (change)="pages.abfindung_fls = !pages.abfindung_fls">
              Abfindung FLS
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.comparison" (change)="pages.comparison = !pages.comparison">
              Vergleich
            </mat-checkbox>
          </div>


          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.abfindung_igm"
              (change)="pages.abfindung_igm = !pages.abfindung_igm">
              Abfindung IGM
            </mat-checkbox>
          </div>


          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.abschlagsausgleich"
              (change)="pages.abschlagsausgleich = !pages.abschlagsausgleich">
              Ausgleich von Abschlägen
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.hinweise_und_erlaeuterungen_igm"
              (change)="pages.hinweise_und_erlaeuterungen_igm = !pages.hinweise_und_erlaeuterungen_igm">
              Hinweise und Erläuterungen IG-Metall
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.hinweise_und_erlaeuterungen_fls"
              (change)="pages.hinweise_und_erlaeuterungen_fls = !pages.hinweise_und_erlaeuterungen_fls">
              Hinweise und Erläuterungen
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.appendix_fls"
              (change)="pages.appendix_fls = !pages.appendix_fls">
              Anhang FLS
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.appendix_kostal"
              (change)="pages.appendix_kostal = !pages.appendix_kostal">
              Anhang Kostal
            </mat-checkbox>
          </div>

          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.appendix_vallourec"
              (change)="pages.appendix_vallourec = !pages.appendix_vallourec">
              Anhang Vallourec
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.appendix_mypegasus"
              (change)="pages.appendix_mypegasus = !pages.appendix_mypegasus">
              Anhang MyPegasus
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.appendix_voestalpine" (change)="
                pages.appendix_voestalpine = !pages.appendix_voestalpine
              ">
              Anhang Voestalpine
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.legend" (change)="pages.legend = !pages.legend">
              Legende
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox class="pdf_cb" [checked]="pages.backpage" (change)="pages.backpage = !pages.backpage">
              Schlussblatt
            </mat-checkbox>
          </div>
        </mat-tab>

        <mat-tab label="Abkürzungen">
          <div>
            <mat-form-field>
              <mat-label>Arbeit</mat-label>
              <input matInput type="text" [formControl]="kurzform_arbeit" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Kündigungsfrist</mat-label>
              <input readonly matInput type="text" [formControl]="kurzform_kuendigungsfrist" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Freistellung</mat-label>
              <input readonly matInput type="text" [formControl]="kurzform_freistellung" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Krankengeld</mat-label>
              <input readonly matInput type="text" [formControl]="kurzform_krankengeld" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Transfergesellschaft</mat-label>
              <input matInput type="text" [formControl]="kurzform_transfergesellschaft" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Sperre</mat-label>
              <input matInput type="text" [formControl]="kurzform_sperre" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>ALG1</mat-label>
              <input matInput type="text" [formControl]="kurzform_arbeitslosengeld_1" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>ALG1 mit Minijob</mat-label>
              <input matInput type="text" [formControl]="kurzform_arbeitslosengeld_mit_minijob_1" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>ALG1 durch Geschäftsaufgabe oder Insolvenz</mat-label>
              <input matInput type="text" [formControl]="
                  kurzform_arbeitslosengeld_durch_geschaeftsaufgabe_1
                " />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>ALG1 durch Geschäftsaufgabe oder Insolvenz mit
                Minijob</mat-label>
              <input matInput type="text" [formControl]="
                  kurzform_arbeitslosengeld_durch_geschaeftsaufgabe_mit_minijob_1
                " />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>ATZ</mat-label>
              <input matInput type="text" [formControl]="kurzform_atz" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Lücke</mat-label>
              <input matInput type="text" [formControl]="kurzform_luecke" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Brücke</mat-label>
              <input matInput type="text" [formControl]="kurzform_bruecke" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Joker</mat-label>
              <input matInput type="text" [formControl]="kurzform_joker" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Phase X</mat-label>
              <input matInput type="text" [formControl]="kurzform_phase_x" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Phase Y</mat-label>
              <input matInput type="text" [formControl]="kurzform_phase_y" />
            </mat-form-field>
          </div>
        </mat-tab>

        <mat-tab label="Namen">
          <div>
            <mat-form-field>
              <mat-label>Phase X</mat-label>
              <input matInput type="text" [formControl]="slot_translations_phase_x" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Phase Y</mat-label>
              <input matInput type="text" [formControl]="slot_translations_phase_y" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Joker</mat-label>
              <input matInput type="text" [formControl]="slot_translations_joker" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Brücke</mat-label>
              <input matInput type="text" [formControl]="slot_translations_bruecke" />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <mat-label>Lücke</mat-label>
              <input matInput type="text" [formControl]="slot_translations_luecke" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>ALG1</mat-label>
              <input matInput type="text" [formControl]="slot_translations_alg1" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>Sperre vor ALG1</mat-label>
              <input matInput type="text" [formControl]="slot_translations_sperre" />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <mat-label>ALG1 durch Geschäftsaufgabe oder Insolvenz</mat-label>
              <input matInput type="text" [formControl]="slot_translations_alg1_durch_geschaeftsaufgabe" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>ALG1 durch Geschäftsaufgabe oder Insolvenz mit
                Minijob</mat-label>
              <input matInput type="text" [formControl]="
                  slot_translations_alg1_durch_geschaeftsaufgabe_mit_minijob
                " />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>ALG1 mit Minijob</mat-label>
              <input matInput type="text" [formControl]="slot_translations_alg1_mit_minijob" />
            </mat-form-field>
          </div>
        </mat-tab>

        <mat-tab label="Erklärungstexte">
          <div *ngFor="let tl of availableTimelines()" style="margin-top: 1.5em">
            <div>
              <mat-form-field>
                <mat-label for="'explanation.pdf'+tl.getCondensedName()" appearance="outline"
                  [style.width.em]="40">Erklärungstext für {{ tl.getCondensedName(true) }}</mat-label><br />
                <textarea [id]="'explanation.pdf' + tl.getCondensedName()"
                  [attr.disabled]="timelineSelected(tl) ? null : true"
                  style="border: 1px solid #ccc; border-radius: 3px" matInput [(ngModel)]="tl.explanation_pdf"
                  (ngModelChange)="saveExplanations()">
                </textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Platzhalter">
          <div style="border-bottom: 1px solid #ccc" *ngFor="let placeholder of placeholders">
            <div *ngIf="
                placeholder.type == 'function' ||
                placeholder.type == 'conditional_function'
              ">
              <div>
                <label for="'placeholder_'+placeholder.name">Platzhalter: {{ placeholder.name }}</label>
                <textarea [disabled]="true" [id]="'placeholder_' + placeholder.name" matInput>
>> Vom System berechnet <<</textarea>
              </div>
            </div>
            <div *ngIf="
                placeholder.type == 'string' ||
                placeholder.type == 'conditional_string'
              ">
              <mat-form-field>
                <label for="'placeholder_'+placeholder.name">Platzhalter: {{ placeholder.name }}</label><br />
                <textarea [id]="'placeholder_' + placeholder.name" [(ngModel)]="placeholder.content" matInput
                  (ngModelChange)="placeholdersChanged()">
                </textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Alter Rechner">
          <mat-form-field>
            <label>URL</label>
            <input type="text" matInput [formControl]="legacyPensionCalculatorUrl" />
          </mat-form-field>
          <br />
          <mat-form-field>
            <label>Username</label>
            <input type="text" matInput [formControl]="legacyPensionCalculatorUserName" />
          </mat-form-field>
          <br />
          <mat-form-field>
            <label>Password</label>
            <input type="password" matInput [formControl]="legacyPensionCalculatorUserPassword" />
          </mat-form-field>
          <mat-checkbox [formControl]="savePW">Save</mat-checkbox>
          <br />
          <mat-form-field>
            <label>URL-Parameter</label>
            <input type="text" matInput [formControl]="legacyPensioncalculatorArgument" />
          </mat-form-field>
          <button *ngIf="!downloading" (click)="legacyDownload()">
            Download
          </button>
          <div *ngIf="downloading">Download läuft</div>
          <div *ngIf="downloadError">Fehler beim Download</div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>