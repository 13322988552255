import { P } from '@angular/cdk/keycodes';
import { ISlot, Slot } from './slot';
import { UserContext } from './user-context';
export interface ITimelineMarker {
  title: string;
  at: string;
  wz5: number;
  wz35: number;
  ep: number;
  bruttoeinkuenfte: number;
  nettoeinkuenfte: number;
  age: string;
  bruttoeinkuenfte_per_month: number;
  nettoeinkuenfte_per_month: number;
  date: string;
  slot?: string;
}
export interface ITimeline {
  slots: Array<ISlot>;
  name: string;
  sourceIndex?: number;
  regular_pension?: boolean;
  reduced?: boolean;
  identifier?: string;
  user?: UserContext;
  markers: Array<ITimelineMarker>;
}

export class Timeline {
  user?: UserContext;
  slots: Array<Slot> = new Array<Slot>();
  name: string;
  sourceIndex?: number;
  regular_pension?: boolean;
  reduced?: boolean;
  identifier?: string;
  markers?: Array<ITimelineMarker>;
  explanation_pdf: string = '';

  digestString() {
    return this.slots
      .map((slot: Slot) => slot.name + ':' + slot.durationMonths)
      .join('->');
  }
  getMarker(title: string): ITimelineMarker | undefined {
    if (this.markers) {
      for (let marker of this.markers) {
        if (marker.title == title) {
          return marker;
        }
      }
    }
    return undefined;
  }
  hasSlots(names: Array<string>) {
    for (let slot of this.slots) {
      if (names.includes(slot.name)) {
        return true;
      }
    }
    return false;
  }
  getAbfindung(): number | undefined {
    return this.user?.batch
      ?.getCompiler(this.getCondensedName())
      ?.calculateForTimeline(this);
  }
  gapMonths(): number {
    let gaps: number = 0;
    let gapLengths = this.slots
      .filter((slot: Slot) => ['Joker', 'Lücke'].includes(slot.name))
      .map((slot: Slot) => slot.durationMonths);
    for (let len of gapLengths) {
      if (len != undefined) {
        gaps += len;
      }
    }
    return gaps;
  }
  hasSlot(name: string): boolean {
    for (let slot of this.slots) {
      if (slot.name == name) {
        return true;
      }
    }
    return false;
  }
  getPension(): Slot | undefined {
    for (let slot of this.slots) {
      if (slot.name.toLowerCase().includes('rente')) {
        return slot;
      }
    }
    return undefined;
  }
  is(str: string): boolean {
    if (this.name !== undefined && this.name.endsWith(str)) {
      return true;
    }
    switch (str) {
      case 'Regelaltersrente': {
        if (this.regular_pension === true) {
          return true;
        }
        break;
      }
      case 'Ungeminderte Rente': {
        if (this.regular_pension === false && this.reduced === false) {
          return true;
        }
        break;
      }
      case 'Geminderte Rente': {
        if (this.regular_pension === false && this.reduced === true) {
          return true;
        }
        break;
      }
      default: {
      }
    }
    return false;
  }
  /**
   * Get Timeline by Condensed Name
   */
  getCondensedName(includeIndex?: boolean): string {
    let names: Array<string> = new Array<string>();
    if (this.identifier !== undefined) {
      names.push(this.identifier);
    } else {
      for (let slot of this.slots) {
        if (slot.name.toLowerCase().includes('rente')) {
          continue;
        } else {
          names.push(slot.name);
        }
      }
    }

    let srs = this.user?.batch.getSpecialPensions();
    let special = false;

    if (srs !== undefined) {
      for (let sr of srs) {
        if (this.is(sr)) {
          names.push(sr);
          special = true;
        }
      }
    }
    if (special) {
      // stub
    } else if (this.is('Regelaltersrente')) {
      names.push('Regelaltersrente');
    } else if (this.is('Geminderte Rente')) {
      names.push('Geminderte Rente');
    } else if (this.is('Ungeminderte Rente')) {
      names.push('Ungeminderte Rente');
    }
    let index: number | undefined = undefined;
    if (includeIndex === true) {
      let index = this.user?.timelines.indexOf(this);
      if (index !== undefined && index > -1) {
        index += 1;
        return index + ': ' + names.join(' -> ');
      }
    }
    return names.join(' -> ');
  }
  getNeighborTimeline(rente: string): Timeline | undefined {
    if (this.identifier === undefined) {
      return undefined;
    }

    let neighbors = this.user?.timelines.filter((timeline: Timeline) => {
      return timeline.getCondensedName() == [this.identifier, rente].join('->');
    });

    if (neighbors?.length == 1) {
      return neighbors[0];
    }

    return undefined;
  }
  getSlot(slotName: string, filterFn?: (s: Slot) => boolean): Slot | undefined {
    let slots = this.slots.filter((slot: Slot) => slot.name == slotName);
    if (filterFn) {
      slots = slots.filter(filterFn);
    }
    if (slots.length === 1) {
      return slots[0];
    }

    return undefined;
  }

  isTimeline(condensedName: string): boolean {
    return this.getCondensedName() == condensedName;
  }

  fromJSON(tl: Partial<ITimeline>): Timeline {
    this.slots.length = 0;

    if (tl.slots) {
      for (let slot of tl.slots) {
        if (
          slot.durationMonths != 0 ||
          slot.name.toLowerCase().includes('rente')
        ) {
          this.slots.push(new Slot().fromJSON({ ...slot, timeline: this }));
        } else {
          //console.log("not adding "+slot.name);
        }
      }
    }
    if (tl.markers) {
      this.markers = tl.markers;
    }
    if (tl.identifier) {
      this.identifier = tl.identifier;
    }
    if (tl.name) {
      this.name = tl.name;
    }
    if (tl.sourceIndex) {
      this.sourceIndex = tl.sourceIndex;
    }
    if (tl.regular_pension != undefined) {
      this.regular_pension = tl.regular_pension;
    }
    if (tl.reduced != undefined) {
      this.reduced = tl.reduced;
    }
    if (tl.user) {
      this.user = tl.user;
    }

    return this;
  }
  earliest(): Date {
    return new Date(Math.min(...this.slots.map((x) => x.earliest().getTime())));
  }
  latest(): Date {
    return new Date(Math.max(...this.slots.map((x) => x.latest().getTime())));
  }
  originalData: any = undefined;
  setOriginalData(od: any) {
    this.originalData = od;
  }
  getOriginalData(): any {
    return this.originalData;
  }
}
