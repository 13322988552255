<div style="width: 100%" fxLayout="column" [formGroup]="form">

  <div fxLayout="row wrap" fxFlex="100">
    <h1>WORK IN PROGRESS - FUNKTIONIERT NOCH NICHT</h1>
  </div>
  <div fxLayout="row wrap" fxFlex="100">
    <h2><b>Zeitlinie</b> Definieren</h2>
    <br />
    <span style="margin-left: 1em; float: left">
      <mat-checkbox formControlName="showEditSlotDetails">Details anzeigen</mat-checkbox>
    </span>
  </div>
  <div class="timeline_creation" fxLayout="row wrap" fxFlex="100">
    <mat-card>
      <mat-card-title>
        <h4>Zeit-<b>Slot</b> hinzufügen</h4>
      </mat-card-title>
      <mat-card-content>
        <mat-form-field>
          <mat-label>Zeit-<b>Slot</b> hinzufügen ("ggf. Lücke"->"Rente" wird automatisch
            hinzugefügt)</mat-label>
          <mat-select formControlName="newTimeslotType" panelClass="">
            <mat-option style="width: 30em" [value]="'none'">---</mat-option>
            <mat-option style="width: 30em" [value]="'Arbeit'">Arbeit</mat-option>
            <mat-option style="width: 30em" [value]="'ALG1'">ALG1</mat-option>
            <mat-option style="width: 30em" [value]="'ATZ'">ATZ</mat-option>
            <mat-option style="width: 30em" [value]="'Transfergesellschaft'">Transfergesellschaft</mat-option>
            <mat-option style="width: 30em" [value]="'PhaseX'">Phase X</mat-option>
            <mat-option style="width: 30em" [value]="'PhaseY'">Phase Y</mat-option>
            <mat-option style="width: 30em" [value]="'ALG1Sperre'">ALG1Sperre</mat-option>
          </mat-select>
        </mat-form-field>
        <br />
        <button (click)="add()" [disabled]="form.get('newTimeslotType')?.value == 'none'" mat-raised-button
          color="primary">
          Zeit-<b>Slot</b> hinzufügen
        </button>
      </mat-card-content>
    </mat-card>

    <!--
			Lücken / Brücken
		-->

    <mat-card>
      <mat-card-title>
        <h4>Behandlung von Lücken (Betrifft die ganze Zeit<b>linie</b>)</h4>
      </mat-card-title>
      <mat-card-content>
        <mat-form-field>
          <mat-label>Art der Lücken:</mat-label>
          <mat-select formControlName="gapType" panelClass="">
            <mat-option style="width: 30em" [value]="'bridge'">Brücke</mat-option>
            <mat-option style="width: 30em" [value]="'joker'">Joker</mat-option>
            <mat-option style="width: 30em" [value]="'luecke'">Lücke</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Brutto/Netto für Brücke -->
        <br /><br />
        <ng-container *ngIf="form.get('gapType')?.value == 'bridge'">
          <mat-form-field>
            <mat-label>Nettofaktor der Brücke</mat-label>
            <input matInput type="number" placeholder="Nettofaktor" hintLabel="Nettofaktor" min="0" max="1.3"
              step="0.05" formControlName="bridge_netto" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Bruttofaktor der Brücke</mat-label>
            <input matInput type="number" placeholder="Bruttofaktor" hintLabel="Bruttofaktor" min="0" max="1.3"
              step="0.05" formControlName="bridge_brutto" />
          </mat-form-field>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxLayout="row wrap" fxFlex="100" *ngIf="slotToEdit !== undefined">
    <h2>Zeit-<b>Slot</b> konfigurieren</h2>
  </div>
  <div class="timeline_creation" fxLayout="row wrap" fxFlex="100" *ngIf="slots.length > 0">
    <mat-card fxFlex="70">
      <mat-card-title>Aktuelle Zeitlinie</mat-card-title>

      <mat-card-content>
        <mat-form-field class="timeline-chip-list" appearance="fill">
          <mat-chip-list aria-label="Aktuelle Zeitlinie">
            <mat-chip *ngFor="let slot of slots" (removed)="remove(slot)" [selectable]="true"
              [selected]="slotToEdit == slot" (click)="editSlot(slot)">
              {{ slot.type }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-list>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="30" *ngIf="slotToEdit && form.get('showEditSlotDetails')?.value">
      <mat-card-title>Details</mat-card-title>
      <mat-card-content>
        <h3>Slot-Data</h3>
        <pre>
					{{ slotToEdit | json }}
				</pre>
        <h3>Request</h3>
        <pre *ngIf="form.get('showEditSlotDetails')?.value && slotToEdit != undefined">
					{{ requestForSlot(slotToEdit) | json }}
				</pre>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="timeline_creation" fxLayout="row wrap" fxFlex="100" *ngIf="slotToEdit !== undefined">
    <!--
			Anfangsdatum
		-->
    <mat-card *ngIf="controls.start">
      <mat-card-title>
        <h2>Anfang von {{ slotToEdit.type }}</h2>
      </mat-card-title>
      <mat-card-content>
        <ng-container *ngIf="controls.start">
          <div>
            <mat-checkbox formControlName="start_enabled"></mat-checkbox>
            <span>&nbsp;Start angeben</span>
            <br />
          </div>

          <mat-form-field *ngIf="controls.start.available && form.get('start_enabled')?.value">
            <mat-label>Start-Typ</mat-label>
            <mat-select formControlName="startValueFrom">
              <mat-option *ngFor="let option of controls.start.options" style="width: 30em" [value]="option.value">{{
                option.caption }}</mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container *ngIf="form.get('startValueFrom')?.value == 'custom_date' && form.get('start_enabled')?.value">
            <mat-form-field>
              <mat-label>Start-Datum</mat-label>
              <input hintLabel="Start-Datum" matInput [matDatepicker]="startPicker" formControlName="startDatum"
                placeholder="Start-Datum" />
              <mat-datepicker-toggle matSuffix [for]="startPicker">
              </mat-datepicker-toggle>
              <mat-datepicker startView="year" [startAt]="now" #startPicker>
              </mat-datepicker>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </mat-card-content>
    </mat-card>

    <!--
			End-Datum
		-->
    <mat-card *ngIf="controls.end.available">
      <mat-card-title>
        <h2>Ende von {{ slotToEdit.type }}</h2>
      </mat-card-title>
      <mat-card-content>
        <ng-container *ngIf="controls.end">
          <div>
            <mat-checkbox formControlName="ende_enabled"></mat-checkbox>
            <span>&nbsp;Ende angeben</span>
            <br />
          </div>

          <mat-form-field *ngIf="controls.end.available && form.get('ende_enabled')?.value">
            <mat-label>End-Typ</mat-label>
            <mat-select formControlName="endValueFrom">
              <mat-option *ngFor="let option of controls.end.options" style="width: 30em" [value]="option.value">{{
                option.caption }}</mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container *ngIf="form.get('endValueFrom')?.value == 'custom_date' && form.get('endValueFrom')?.value">
            <mat-form-field>
              <mat-label>End-Datum</mat-label>
              <input hintLabel="End-Datum" matInput [matDatepicker]="endPicker" formControlName="endDatum"
                placeholder="End-Datum" />
              <mat-datepicker-toggle matSuffix [for]="endPicker">
              </mat-datepicker-toggle>
              <mat-datepicker startView="year" [startAt]="now" #endPicker>
              </mat-datepicker>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </mat-card-content>
    </mat-card>
    <!--
			Maximale Dauer
		-->
    <mat-card *ngIf="controls.dauer">
      <mat-card-title>
        <h2>Dauer</h2>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-checkbox formControlName="dauer_enabled"></mat-checkbox>
          <span>&nbsp;Dauer angeben</span>
          <br />
        </div>
        <mat-form-field *ngIf="form.get('dauer_enabled')?.value">
          <mat-label>Maximale Dauer (in Monaten) für {{ slotToEdit.type }}</mat-label>
          <input matInput type="number" placeholder="Max. Dauer" hintLabel="Max. Dauer" min="0" max="999" step="1"
            formControlName="dauer" />
        </mat-form-field>
        <br />
        <mat-form-field *ngIf="form.get('dauer_phase_x_csv_enabled')?.value">

          <mat-checkbox matInput formControlName="dauer_phase_x_csv">Dauer nach "dauer_phase_x" in CSV</mat-checkbox>
          <input type="text" hidden matInput formControlname="dauer_phase_x_csv">
        </mat-form-field>
        <mat-form-field *ngIf="form.get('dauer_phase_y_csv_enabled')?.value">

          <mat-checkbox matInput formControlName="dauer_phase_y_csv">Dauer nach "dauer_phase_y" in CSV</mat-checkbox>
          <input type="text" hidden matInput formControlname="dauer_phase_y_csv">
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="controls.max_duration">
      <mat-card-title>
        <h2>Maximale Dauer (Nur ATZ, PhX,PhY,ALG1)</h2>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-checkbox formControlName="max_dauer_enabled"></mat-checkbox>
          <span>&nbsp;Maximale Dauer angeben</span>
          <br />
        </div>
        <mat-form-field *ngIf="form.get('max_dauer_enabled')?.value">
          <mat-label>Maximale Dauer (in Monaten) für {{ slotToEdit.type }}</mat-label>
          <input matInput type="number" placeholder="Max. Dauer" hintLabel="Max. Dauer" min="0" max="999" step="1"
            formControlName="max_duration" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="controls.max_age && controls.max_age.available">
      <mat-card-title>
        <h2>Maximales Alter (Nur ATZ, PhX,PhY)</h2>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-checkbox formControlName="max_age_enabled"></mat-checkbox>
          <span>&nbsp;Maximales Alter angeben</span>
          <br />
        </div>
        <mat-form-field *ngIf="form.get('max_age_enabled')?.value">
          <mat-label>Maximales Alter für {{ slotToEdit.type }}</mat-label>
          <input matInput type="number" placeholder="Max. Alter" hintLabel="Max. Alter" min="0" max="999" step="1"
            formControlName="max_age" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <!-- Brutto/Netto-Faktoren -->
    <mat-card *ngIf="controls.nettoFaktor.available">
      <mat-card-title>
        <h2>Einkommens-Faktoren für {{ slotToEdit.type }}</h2>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-checkbox formControlName="netto_faktor_enabled"></mat-checkbox>
          <span>&nbsp;Nettofaktor angeben</span>
          <br />
        </div>
        <mat-form-field *ngIf="form.get('netto_faktor_enabled')?.value">
          <mat-label>Nettofaktor</mat-label>
          <input matInput type="number" placeholder="Nettofaktor" hintLabel="nettoFaktor" min="0" max="1.3" step="0.05"
            formControlName="nettoFaktor" />
        </mat-form-field>
        <div>
          <mat-checkbox formControlName="brutto_faktor_enabled"></mat-checkbox>
          <span>&nbsp;Bruttofaktor angeben</span>
          <br />
        </div>
        <mat-form-field *ngIf="form.get('brutto_faktor_enabled')?.value">
          <mat-label>Bruttofaktor</mat-label>
          <input matInput type="number" placeholder="Bruttofaktor" hintLabel="Bruttofaktor" min="0" max="1.3"
            step="0.05" formControlName="bruttoFaktor" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>
        <h2>Minijob (nur ALG1)</h2>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-checkbox formControlName="minijob"></mat-checkbox>
          <span>&nbsp;Minijob</span>
          <br />
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>
        <h2>Geschäftsaufgabe (nur ALG1)</h2>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-checkbox formControlName="geschaeftsaufgabe"></mat-checkbox>
          <span>&nbsp;Geschaeftsaufgabe</span>
          <br />
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>
        <h2>Padding-Priorität für {{ slotToEdit.type }}</h2>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-checkbox formControlName="padding_enabled"></mat-checkbox>
          <span>&nbsp;Padding angeben</span>
          <br />
        </div>
        <mat-form-field *ngIf="form.get('padding_enabled')?.value">
          <mat-label>Priorität</mat-label>
          <input matInput type="number" placeholder="Priorität" hintLabel="Priorität" min="0" max="9999" step="1"
            formControlName="paddingPriority" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>
        <h2>Alternativ-Name für {{ slotToEdit.type}}</h2>
        <mat-form-field>
          <mat-label>Alternativname</mat-label>
          <input matInput type="text" formControlName="alt_name">
        </mat-form-field>
      </mat-card-title>
    </mat-card>
  </div>

  <div class="timeline_creation" fxLayout="row wrap" fxFlex="100" *ngIf="slots.length > 0">
    <button *ngIf="currentTimeline != undefined" (click)="saveToExistingTimeline()" mat-raised-button color="primary">
      Über bestehene Zeitlinie speichern
    </button>
    <span>&nbsp;</span>
    <button (click)="addTimeline()" mat-raised-button color="primary">
      Zeitlinie <span *ngIf="currentTimeline != undefined">als Kopie </span>hinzufügen
    </button><span>&nbsp;</span>
    <button *ngIf="currentTimeline != undefined" (click)="abort()" mat-raised-button color="secondary">
      Abbrechen
    </button>
  </div>
  <div>
    <div>Aktuelle bearbeitete Timeline:</div>
    <div>
      <span>{{ identifier() }}</span>
      <pre *ngIf="form.get('showEditSlotDetails')?.value">
					{{ slots | json }}
				</pre>
    </div>
  </div>
  <div class="debug">
    <div>Bisherige dynamische Timelines:</div>
    <div class="timeline-list" *ngFor="let identifier of existingIdentifiers()">
      <div class="timeline-entry">
        <div class="identifier">{{ identifier??"meh" }}</div>
        <div class="controls">
          <button mat-raised-button color="primary" (click)="deleteTimlineByIdentifier(identifier)">
            Entfernen
          </button>
          <button mat-raised-button color="primary" (click)="editTimelineByIdentifer(identifier)">
            Bearbeiten
          </button>
        </div>
      </div>

      <button mat-raised-button color="primary" (click)="export()">Zeitlinien in Datei expotieren</button>
    </div>
  </div>
  <div>
    <input hidden #fileInput type="file" accept=".json" class="file-input" (change)="datensatzChanged($event)" />
    <button mat-raised-button color="primary" (click)="fileInput.click()">Zeitlinien aus Datei importieren</button>
  </div>
</div>